import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";


i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
      de: {
        translations: {
          neu: "Der einfache Weg das Verständnis deiner SchülerInnen individuell zu kennen. ",
          neu1: "Mary ist eine Webapp, die dir zeigt, wie gut deine Schüler etwas bearbeitet haben. Du siehst welche Themen deine Schüler individuell behandelt haben und was sie noch lernen sollten. Deine Schüler geben einfach ihre textbasierten Antworten ein und du bekommst sofort das Ergebnis.",
          gibein: "Sag Mary, was du gerne lernen willst ...",
          losgehts: "Los gehts ...",
          marysagt: "Mary sagt: ",
          zeichen: "Zeichen ",
          inbrowser: "  Starte und teile textbasierte Aufgaben mit deinen SchülerInnen, direkt von deinem Browser aus und ohne Installation." ,
          learntogether: "Aufgaben für die Schüler",
          learntogethertext: "Deine SchülerInnen erhalten die Aufgabe und können sofort loslegen. Auch ohne Laptop, nur mit dem Smartphone oder Tablet.",
          features: "Verbringe 95% weniger Zeit mit der Überprüfung deiner SchülerInnen",
          teachers: "Du erhältst eine automatische Auswertung der Antwort der SchülerInnen, kannst diese anpassen und verwenden.",
          agb: "AGB",
          uberuns: "Team",
          blog: "Blog",
          datenschutz: "Datenschutz",
          jobs: "Jobs",
          impressum: "Impressum",
          blogdate1: "28. April 2020",
          blogdate2: "20. April 2020",
          blogtitle: "Willkommen Mary!",
          blogtitle2: "Lerne was du willst",
          lesedauer: "Lesedauer: ",
          blogpost: "Mit Mary kannst du lernen was du magst.",
          blogdate3: "17. Juni 2020",
          blogtitle3: "Mary gibt noch besseres Feedback ",
          blogpost3: "Mary zeigt dir, wie weit du eine Thema behandelt hast.",
          blogpost2: "\"Wer Dinge aus der Seele heraus tut spürt einen Fluss, der sich in einem bewegt, Freude\" Rumi",
          aboutus_willkommen: "Wer wir sind",
          aboutus_wirsind: "Wir sind ein Team von Freunden, die sich vorgenommen haben, das Lernen und Forschen an die Bedürfnisse des Menschen auszurichten.",
          aboutus_diemehrheit: "Die Mehrheit akzeptiert Angebot und Nachfrage, um unsere wirtschaftlichen Angelegenheiten zu regeln. Aber wenn es um Bildung geht, insbesondere um das, was man lernt, sind die meisten Menschen mit einem festen Lehrplan zufrieden. Das ist für die ersten 8 Schuljahre in Ordnung. Heute lernen die meisten nur, um Prüfungen zu bestehen. Stelle dir eine Welt vor, in der Menschen nur um des Lernens willen lernen. Dies geschieht, so glauben wir, wenn die Menschen das lernen können, was sie wollen.",
          aboutus_nerdig: "Ein wenig nerdig: Wir sehen uns als rekursive Funktion die dafür sorgt, dass jede/r so gut sein kann, wie möglich. Deswegen haben wir Mary entwickelt.",
          aboutus_produkte: "Wir bauen Produkte mit möglichst geringen Nebenwirkungen. Aus Deiner Sicht sind Nebenwirkungen Funktionen, die dir als Benutzer keinen Wert zurückgeben. Technisch gesehen konzentrieren wir uns auf funktionale Programmierung und sauberes Design. Deshalb mag unsere App einfach aussehen, aber die Schlüsselfunktionen, die wir anbieten, sind leistungsstark.",
          aboutus_ideen: "Wenn du Ideen hast, die wir ausprobieren sollten, lasse es uns bitte wissen.",
          aboutus_wirsindnamen: "Wir sind ",
          maryishere: "Mary ist da.",
          impressum_lerne: "Lerne, was Dich interessiert",
          impressum_impressum: "Impressum",
          impressum_dieseseite: "Diese Seite wurde erstellt von ",
          impressum_verantwortlich: "Verantwortlich für den Inhalt der Seite: Karan Dehghani ",
          impressum_email: "E-Mail: ",
          impressum_adresse: "Adresse: ",
          jobs_date: "2. April 2020",
          jobs_title: "Stelle: Senior Developer",
          jobs_desc: "Wenn du damit baust (gewichtet): Python, JavaScript, Common Lisp ist es uns eine Freude, wenn du uns kontaktierst.",
          jobs_mail: "Schreibe uns bitte an ",
          follow_bestimme: "Lege hier fest, welche Schüler du überprüfen möchtest: ",
          follow_neue: "Neuen Schüler anlegen: ",
          follow_hinzu: "Hinzufügen",
          follow_habeemail: "Habe die E-Mail auf deine Beobachtungsliste gesetzt.",
          follow_konntenicht: "Diese E-Mail konnte ich nicht finden.",
          follow_du: "Deine Schüler: ",
          follow_nichtweiter: "Nicht weiter beobachten",
          follow_alter: "Alter: ",
          follow_email: "Bitte E-Mail Adresse des Schülers eingeben",
          source_lege: "Lege hier die Inhalte für dein Thema an: ",
          source_bitte: "Bitte Informationen zum Thema angeben: ",
          source_oder: "oder direkt eingeben, was die Schüler können müssen. Du kannst einfaches Deutsch verwenden: ",
          source_hinzufuegen: "Hinzufügen",
          source_ichhab: "Ich habe die Quelle für dich aufbereitet.",
          source_woerter: " Jetzt",
          source_speichern: "Speichern",
          source_ups: "Ups, das ging schief.",
          source_quelle: "Ich habe die neue Quelle für Dich auf Deinem",
          source_schreibtisch: "Schreibtisch",
          source_gespeichert: "gespeichert",
          source_und: "Jetzt bitte die URL oder den Text hinterlegen, den Mary später bei den Schülern überprüfen soll.",
          source_url: "URL Adresse eingeben",
          source_kommentar: "Kommentar/ Aufgabe eingeben",
          source_titel: "Titel eingeben",
          confirmDeletion_wirsind: "Es tut uns leid, dass du gehst. Dein Account wurde gelöscht.",
          confirmDeletion_hierist: "Hier ist ein Video als ein letztes Goodie.",
          confirmRegister_yes: "Yes. Dein Konto ist jetzt aktiviert.",
          confirmRegister_anmelden: "Anmelden",
          confirmRegister_etwas: "Etwas ist schief gelaufen. Entschuldigung, bitte kontaktiere help@tensorparc.com",
          home_hallo: "Hallo ",
          home_dasist: ", das ist dein Schreibtisch: ",
          home_deine: "Deine Themen:",
          home_projektloeschen: "Thema löschen",
          home_age: "Themenalter: ",
          home_projektprivat: "Thema ist privat",
          home_eigenequelle: "Thema anlegen",
          home_neuequelle: " Neues Thema anlegen",
          home_dubeobachtest: "Deine Schüler: ",
          home_nichtmehrfolgen: "Nicht mehr folgen",
          home_nochkeinprojektonline: "Noch kein Thema online",
          home_mehrfolgen: "Mehr Schülern folgen (drücke bitte auf Plus und gib dann bitte ihre Email Adresse ein)",
          home_deinenaccount: "Deinen Account löschen: ",
          home_meinenaccount: "Meinen Account jetzt löschen",
          home_endgueltig: "Wenn du diesen Knopf drückst, wird dein Konto endgültig gelöscht. Das bedeutet, dass dein Konto, deine Kontoeinstellungen und Themen gelöscht werden. Wenn andere Personen deine Themen benutzt haben, können sie diese weiterhin benutzen. Bist du sicher, dass du das tun willst? Immer, wenn ein Benutzer geht, weint Mary nachts.",
          bsStart_schaumal: "Schau mal! Das habe ich gefunden",
          bsStart_waehle: "Wähle bitte ein Thema: ",
          bsStart_gibeinthema: "Gib hier dein Thema ein ",
          nav_anmelden: "Anmelden",
          nav_konto: "Konto erstellen",
          nav_dashboard: "Dashboard",
          nav_abmelden: "Abmelden",
          signin_anmelden: "Anmelden",
          signin_wenndu: "Wenn du Dich anmeldest, erklärst du Dich mit den Nutzungsbedingungen und der Datenschutzrichtlinie von TensorParc sowie mit dem Erhalt von E-Mails mit Aktualisierungen einverstanden. Die kommen aber nur ab und zu.",
          signin_abmelden: "Abmelden",
          signin_email: "E-Mail",
          signin_passwort: "Passwort (Bitte nutze 8 Zeichen)",
          signin_duhast: "Du hast noch keinen Account? Jetzt einfach registrieren.",
          signup_registrieren: "Registrieren",
          signup_wenndu: "Wenn du Dich registrierst, erklärst du Dich mit den Nutzungsbedingungen und der Datenschutzrichtlinie von TensorParc sowie mit dem Erhalt von E-Mails mit Aktualisierungen einverstanden. Die kommen aber nur ab und zu.",
          signup_bereits: "Du hast Dich bereits registriert? Bitte anmelden",
          signup_erfolgreich: "Deine Registrierung war erfolgreich. Bitte überprüfe deine E-Mail, um dein Konto zu aktivieren.",
          speech_texteinsprechen: "Text einfach sprechen",
          speech_dukannst: "Du kannst deinenText auch einfach einsprechen. Drücke dafür auf die ",
          speech_aufnehmen: "Text aufnehmen",
          speech_taste: "Taste.",
          speech_fertig: "Wenn du fertig bist, kann du den Text mit copy/paste in den Editor eingeben.",
          speech_textaufnehmen: "Text aufnehmen",
          speech_textstoppen: "Textaufnahme stoppen",
          speech_textlöschen: "Text wieder löschen",
          speech_textkopieren: "Klicke hier um den Text zu kopieren.",
          toggle_video: "Video Call",
          toggle_audio: "Voice Recording",
          toggle_dukannst: "Du kannst mich auf dem Bildschirm hin und her bewegen.",
          whatsapp: "Sprich mit deinen Freunden.",
          bsEditor_leider: "Ich habe leider nichts gefunden.",
          bsEditor_versuch: " Versuch mal",
          bsEditor_duforschst: "Das Thema: ",
          bsEditor_losgehts: "Los geht's:",
          bsEditor_schreibeauf: "Fange einfach an. Schreibe in den Editor was du bereits über",
          bsEditor_weisst: "weisst: ",
          bsEditor_umwissen: "Um deinen Wissens-Stand zu speichern bitte erst anmelden oder registrieren.",
          bsEditor_speichern: "Speichern",
          bsEditor_deinwissen: "dein Wissens-Stand wurde im",
          bsEditor_gespeichert: "gespeichert.",
          bsEditor_bearbeiten: "bearbeiten: ",
          bsEditor_damit: "Damit kannst du starten: ",
          bsEditor_keinplan: "Kein Plan? Suche hier: ",
          bsEditor_deinlevel: "dein Bearbeitungs-Level: ",
          bsEditor_schluessel: "Schlüsselwörter:",
          bsEditor_linkszu: "Links zu ähnlichen Autoren",
          bsEditor_askmary: "Überprüfe mit Mary (Beta - English only):",
          bsEditor_problem: " Berechne mathematische Formeln oder frage, ob etwas wahr ist. Mary wird antworten, wenn sie das Problem verstanden hat.",
          bsEditor_keywords: "Versuche diese Wörter in deinem Text einzubauen.",
          pricing: "Unsere Preise",
          bsEditor_links: "Wenn du etwas geschrieben hast, kannst du nachschauen, ob im Internet jemand über das selbe Thema etwas geschrieben hat.",
          bsEditor_type: "Wenn du ?1 und die Leertaste drückst, zeigt dir Mary das nächste wichtige Wort."
        }
      },
      en: {
        translations: {
          neu: "Der einfache Weg das Verständnis deiner SchülerInnen individuell zu kennen. ",
          neu1: "Mary ist eine Webapp, die dir zeigt, wie gut deine Schüler etwas bearbeitet haben. Du siehst welche Themen deine Schüler individuell behandelt haben und was sie noch lernen sollten. Deine Schüler geben einfach ihre textbasierten Antworten ein und du bekommst sofort das Ergebnis.",
          gibein: "Sag Mary, was du gerne lernen willst ...",
          losgehts: "Los gehts ...",
          marysagt: "Mary sagt: ",
          zeichen: "Zeichen ",
          inbrowser: "  Starte und teile textbasierte Aufgaben mit deinen SchülerInnen, direkt von deinem Browser aus und ohne Installation." ,
          learntogether: "Aufgaben für die Schüler",
          learntogethertext: "Deine SchülerInnen erhalten die Aufgabe und können sofort loslegen. Auch ohne Laptop, nur mit dem Smartphone oder Tablet.",
          features: "Verbringe 95% weniger Zeit mit der Überprüfung deiner SchülerInnen",
          teachers: "Du erhältst eine automatische Auswertung der Antwort der SchülerInnen, kannst diese anpassen und verwenden.",
          agb: "AGB",
          uberuns: "Team",
          blog: "Blog",
          datenschutz: "Datenschutz",
          jobs: "Jobs",
          impressum: "Impressum",
          blogdate1: "28. April 2020",
          blogdate2: "20. April 2020",
          blogtitle: "Willkommen Mary!",
          blogtitle2: "Lerne was du willst",
          lesedauer: "Lesedauer: ",
          blogpost: "Mit Mary kannst du lernen was du magst.",
          blogdate3: "17. Juni 2020",
          blogtitle3: "Mary gibt noch besseres Feedback ",
          blogpost3: "Mary zeigt dir, wie weit du eine Thema behandelt hast.",
          blogpost2: "\"Wer Dinge aus der Seele heraus tut spürt einen Fluss, der sich in einem bewegt, Freude\" Rumi",
          aboutus_willkommen: "Wer wir sind",
          aboutus_wirsind: "Wir sind ein Team von Freunden, die sich vorgenommen haben, das Lernen und Forschen an die Bedürfnisse des Menschen auszurichten.",
          aboutus_diemehrheit: "Die Mehrheit akzeptiert Angebot und Nachfrage, um unsere wirtschaftlichen Angelegenheiten zu regeln. Aber wenn es um Bildung geht, insbesondere um das, was man lernt, sind die meisten Menschen mit einem festen Lehrplan zufrieden. Das ist für die ersten 8 Schuljahre in Ordnung. Heute lernen die meisten nur, um Prüfungen zu bestehen. Stelle dir eine Welt vor, in der Menschen nur um des Lernens willen lernen. Dies geschieht, so glauben wir, wenn die Menschen das lernen können, was sie wollen.",
          aboutus_nerdig: "Ein wenig nerdig: Wir sehen uns als rekursive Funktion die dafür sorgt, dass dass jede/r so gut sein kann, wie möglich. Deswegen haben wir Mary entwickelt.",
          aboutus_produkte: "Wir bauen Produkte mit möglichst geringen Nebenwirkungen. Wir wolle nicht, dass du noch mehr zu tun hast. Aus deiner Sicht sind Nebenwirkungen Funktionen, die dir als Benutzer keinen Wert zurückgeben. Technisch gesehen konzentrieren wir uns auf funktionale Programmierung und sauberes Design. Deshalb mag unsere App einfach aussehen, aber die Schlüsselfunktionen, die wir anbieten, sind leistungsstark.",
          aboutus_ideen: "Wenn du Ideen hast, die wir ausprobieren sollten, lasse es uns bitte wissen.",
          aboutus_wirsindnamen: "Wir sind ",
          maryishere: "Mary ist da.",
          impressum_lerne: "Lerne, was Dich interessiert",
          impressum_impressum: "Impressum",
          impressum_dieseseite: "Diese Seite wurde erstellt von ",
          impressum_verantwortlich: "Verantwortlich für den Inhalt der Seite: Karan Dehghani ",
          impressum_email: "E-Mail: ",
          impressum_adresse: "Adresse: ",
          jobs_date: "2. April 2020",
          jobs_title: "Stelle: Senior Developer",
          jobs_desc: "Wenn du damit baust (gewichtet): Python, JavaScript, Common Lisp ist es uns eine Freude, wenn du uns kontaktierst.",
          jobs_mail: "Schreibe uns bitte an ",
          follow_bestimme: "Lege hier fest, welche Schüler du überprüfen möchtest: ",
          follow_neue: "Neuen Schüler anlegen: ",
          follow_hinzu: "Hinzufügen",
          follow_habeemail: "Habe die E-Mail auf deine Beobachtungsliste gesetzt.",
          follow_konntenicht: "Diese E-Mail konnte ich nicht finden.",
          follow_du: "Deine Schüler: ",
          follow_nichtweiter: "Nicht weiter beobachten",
          follow_alter: "Alter: ",
          follow_email: "Bitte E-Mail Adresse des Schülers eingeben",
          source_lege: "Lege hier die Inhalte für dein Thema an: ",
          source_bitte: "Bitte Informationen zum Thema angeben: ",
          source_oder: "oder direkt eingeben, was die Schüler können müssen. Du kannst einfaches Deutsch verwenden: ",
          source_hinzufuegen: "Hinzufügen",
          source_ichhab: "Ich habe die Quelle für dich angeschaut und",
          source_woerter: "bin bereit für die Prüfung der Texte deiner SchülerInnen. Ist die Aufgabe fertig gestellt? Falls ja, bitte jetzt",
          source_speichern: "speichern",
          source_ups: "Ups, das ging schief.",
          source_quelle: "Ich habe die neue Quelle für Dich auf deinem",
          source_schreibtisch: "Schreibtisch",
          source_gespeichert: "gespeichert",
          source_und: "Jetzt bitte die URL oder den Text hinterlegen, den Mary später bei den Schülern überprüfen soll.",
          source_url: "URL Adresse eingeben",
          source_kommentar: "Hier bitte die Aufgabenstellung eingeben",
          source_name: "Name der Klasse/Gruppe bitte hier eingeben",
          source_titel: "Titel der Aufgabe bitte hier eingeben",
          confirmDeletion_wirsind: "Es tut uns leid, dass du gehst. Dein Account wurde gelöscht.",
          confirmDeletion_hierist: "Hier ist ein Video als ein letztes Goodie.",
          confirmRegister_yes: "Yes. Dein Konto ist jetzt aktiviert.",
          confirmRegister_anmelden: "Anmelden",
          confirmRegister_etwas: "Etwas ist schief gelaufen. Entschuldigung, bitte kontaktiere help@tensorparc.com",
          home_hallo: "Hallo ",
          home_dasist: ", das ist dein Schreibtisch: ",
          home_deine: "Deine Themen:",
          home_projektloeschen: "Themen löschen",
          home_age: "Themenalter: ",
          home_projektprivat: "Thema ist privat",
          home_eigenequelle: "Thema anlegen",
          home_neuequelle: " Neues Thema anlegen",
          home_dubeobachtest: "Deine Schüler: ",
          home_nichtmehrfolgen: "Nicht mehr folgen",
          home_nochkeinprojektonline: "Noch kein Thema online",
          home_mehrfolgen: "Mehr Schülern folgen (drücke bitte auf Plus und gib dann bitte ihre Email Adresse ein)",
          home_deinenaccount: "Deinen Account löschen: ",
          home_meinenaccount: "Meinen Account jetzt löschen",
          home_endgueltig: "Wenn du diesen Knopf drückst, wird dein Konto endgültig gelöscht. Das bedeutet, dass dein Konto, deine Kontoeinstellungen und Themen gelöscht werden. Wenn andere Personen deine Themen benutzt haben, können sie diese weiterhin benutzen. Bist du sicher, dass du das tun willst? Immer, wenn ein Benutzer geht, weint Mary nachts.",
          bsStart_schaumal: "Schau mal! Das habe ich gefunden",
          bsStart_waehle: "Wähle bitte ein Thema: ",
          bsStart_gibeinthema: "Gib hier dein Thema ein ",
          nav_anmelden: "Anmelden",
          nav_konto: "Konto erstellen",
          nav_dashboard: "Dashboard",
          nav_abmelden: "Abmelden",
          signin_anmelden: "Anmelden",
          signin_wenndu: "Wenn du Dich anmeldest, erklärst du Dich mit den Nutzungsbedingungen und der Datenschutzrichtlinie von TensorParc sowie mit dem Erhalt von E-Mails mit Aktualisierungen einverstanden. Die kommen aber nur ab und zu.",
          signin_abmelden: "Abmelden",
          signin_email: "E-Mail",
          signin_passwort: "Passwort (Bitte nutze 8 Zeichen)",
          signin_duhast: "Du hast noch keinen Account? Jetzt einfach registrieren.",
          signup_registrieren: "Registrieren",
          signup_wenndu: "Wenn du Dich registrierst, erklärst du Dich mit den Nutzungsbedingungen und der Datenschutzrichtlinie von TensorParc sowie mit dem Erhalt von E-Mails mit Aktualisierungen einverstanden. Die kommen aber nur ab und zu.",
          signup_bereits: "Du hast Dich bereits registriert? Bitte anmelden",
          signup_erfolgreich: "Deine Registrierung war erfolgreich. Bitte überprüfe deine E-Mail, um dein Konto zu aktivieren.",
          speech_texteinsprechen: "Text einfach sprechen",
          speech_dukannst: "Du kannst deinen Text auch einfach einsprechen. Drücke dafür auf die ",
          speech_aufnehmen: "Text aufnehmen",
          speech_taste: "Taste.",
          speech_fertig: "Wenn du fertig bist, kann du den Text mit copy/paste in den Editor eingeben.",
          speech_textaufnehmen: "Text aufnehmen",
          speech_textstoppen: "Textaufnahme stoppen",
          speech_textlöschen: "Text wieder löschen",
          speech_textkopieren: "Klicke hier um den Text zu kopieren.",
          toggle_video: "Video Call",
          toggle_audio: "Voice Recording",
          toggle_dukannst: "Du kannst mich auf dem Bildschirm hin und her bewegen.",
          whatsapp: "Sprich mit deinen Freunden.",
          bsEditor_leider: "Ich habe leider nichts gefunden.",
          bsEditor_versuch: " Versuch mal",
          bsEditor_duforschst: "Das Thema: ",
          bsEditor_losgehts: "Los geht's:",
          bsEditor_schreibeauf: "Fange einfach an. Schreibe in den Editor was du bereits über",
          bsEditor_weisst: "weisst: ",
          bsEditor_umwissen: "Um deinen Wissens-Stand zu speichern bitte erst anmelden oder registrieren.",
          bsEditor_speichern: "Speichern",
          bsEditor_deinwissen: "dein Wissens-Stand wurde im",
          bsEditor_gespeichert: "gespeichert.",
          bsEditor_bearbeiten: "bearbeiten: ",
          bsEditor_damit: "Damit kannst du starten: ",
          bsEditor_keinplan: "Kein Plan? Suche hier: ",
          bsEditor_deinlevel: "dein Bearbeitungs-Level: ",
          bsEditor_schluessel: "Schlüsselwörter:",
          bsEditor_linkszu: "Links zu ähnlichen Autoren",
          bsEditor_askmary: "Überprüfe mit Mary (Beta - English only):",
          bsEditor_problem: " Berechne mathematische Formeln oder frage, ob etwas wahr ist. Mary wird antworten, wenn sie das Problem verstanden hat.",
          bsEditor_keywords: "Versuche diese Wörter in deinem Text einzubauen.",
          pricing: "Unsere Preise",
          bsEditor_links: "Wenn du etwas geschrieben hast, kannst du nachschauen, ob im Internet jemand über das selbe Thema etwas geschrieben hat.",
          bsEditor_type: "Wenn du ?1 und die Leertaste drückst, zeigt dir Mary das nächste wichtige Wort."        }
      }
    },
    fallbackLng: "de",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
  });


export default i18n;
