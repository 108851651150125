import React from 'react';
import './style.css';
import { Card, Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

function Impressum() {

    return (
        <div>
                <div/>
                <Container>
                    <Row>
                        <Col>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                            <h3><Trans i18nKey="aboutus_willkommen" /> </h3>
                            <p><Trans i18nKey="aboutus_wirsind" /> <br/>
                            <br/>
                            <Trans i18nKey="aboutus_diemehrheit" />
                            <br/> <br/>
                            <Trans i18nKey="aboutus_nerdig" /> <br/> <br/>
                            <Trans i18nKey="aboutus_produkte" /> <br/><br/>
                            <Trans i18nKey="aboutus_ideen" />  <Trans i18nKey="aboutus_wirsindnamen" /> Tony Meyer, Farid Bidardel, Tobias Lang, Karan Dehghani.</p>
                        </Card>
                        </Col>
                    </Row>
                </Container>

        </div>
    );
}


const NewComponent30 = withTranslation()(Impressum)


export default function App() {
  return (

      <NewComponent30 />

  );
}
