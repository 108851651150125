import React, { PureComponent, Fragment } from "react";
import { Mixpanel } from '../../components/Mixpanel';
import Loader from "../../components/Loader";
import { DragDropContext } from 'react-beautiful-dnd';
import { api } from "../../lib/apisauce";
import SimpleIcons from 'simple-icons-react-component';
import 'react-circular-progressbar/dist/styles.css';
import { UserConsumer } from "../../userContext";
import UserContext from "../../userContext";
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import Toggle from '../../components/Toggle/index.js'
import ToggleAudio from '../../components/ToggleAudio/index.js'
import 'jquery-ui-dist/jquery-ui'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col, Form, Modal } from 'react-bootstrap';
import "../../index.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSave, faMagic, faMedal, faQuestionCircle, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { Trans } from "react-i18next";
import  "../../i18n.js"
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";

var writeGood = require('write-good');
var schreibGut = require('schreib-gut');

const Msg = ({ closeToast, lastWord, nextWord, index, props, keywordItem }) => (
    <div style={{ color: "#000" }}>
        <div style={{ width: "30%", float: "left", height: "100px" }}><img src="https://i.imgur.com/VudgSQK.png" style={{ width:"88px", height:"auto", verticalAlign: "middle", float: "left" }} alt=""/></div>
        {nextWord != null && <div> Super! Du hast "{lastWord}" geschrieben.<br/> Hast du auch schon etwas zu: "{nextWord[0]}" geschrieben?<br/> </div>}
        {nextWord == null && <div> Super! Du hast "{lastWord}" geschrieben. - nice!</div>}
        <hr/>
        {keywordItem[1] && <div>Mary sagt zu "{keywordItem[0]}": <br/><br/>{keywordItem[1]}</div>}
    </div>
);

const MsgMary = ({ answer }) => (
    <div style={{ color: "#000" }}>
        <div style={{ height: "75px" }}><img src="https://i.imgur.com/VudgSQK.png" style={{ width:"88px", height:"auto", verticalAlign: "middle", float: "left" }} alt=""/>
            Mary sagt:
        </div>
        <hr/>
        <div>{answer}</div>
    </div>
);

const Medal = ({ keyid, placement, badge, txt }) => (
    <OverlayTrigger
        key={keyid}
        placement={placement}
        overlay={
            <Tooltip id={keyid}>
                <div dangerouslySetInnerHTML={{__html: txt}} />
            </Tooltip>}
    >
        <FontAwesomeIcon icon={faMedal} style={{ fontSize: "36px",
            color: badge ? "#5cb85c" : "lightgrey",
        }} />
    </OverlayTrigger>
);

class Team extends PureComponent {
    static contextType = UserContext;

    constructor(props) {
        super(props);

    }


    state = {

        loadIde: this.props.match.params.ide,
        teamMembers: [],
        userIdes: [],
        userTeams: [],
        onlyContexts: [],
    };

    componentDidMount() {

        const { loadNouns } = this.props.location.state;
        const { lan } = this.props.location.state;

        this.setState({ loadNouns: loadNouns });
        this.setState({ lanradio: lan });

        if (this.context.isLoggedIn) { this.loadTeam(this.props.match.params.ide); }


    }


    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

        console.log(this.state)

        return (
            <UserConsumer>
                {({ username, usertype }) => (<Fragment>
                    {this.state.loading && <div>
                        <Loader />
                    </div>}
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h3 className="subtitleide"><Trans i18nKey="home_hallo" /> {username}<Trans i18nKey="home_dasist" /> </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                              <h3>Hier siehst du die Auflistung der SchülerInnen. Bitte weise unten die Themen zu: </h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row>
                                        {this.state.teamMembers.map((item, index) => {
                                            return (
                                                <Col xs={12} md={6} lg={4} key={index}>
                                                    <div style={{
                                                        backgroundColor: "#5cb85c",
                                                        color: "rgb(255, 255, 255)",
                                                        margin: "0.5rem",
                                                        padding: "1rem",
                                                        borderColor: "rgb(217, 217, 217)",
                                                        borderRadius: "10px",
                                                    }}>

                                                        <Row>
                                                            <Col>
                                                                <Link to={{
                                                                    pathname: "/Student/"+ item.id,
                                                                    state: {
                                                                        studentid: item.id,
                                                                        studentname: item.username,
                                                                    }
                                                                }}
                                                                      style={{ color: "white", textDecoration: "none" }}>
                                                                    {item.username}
                                                                </Link>
                                                            </Col>
                                                        </Row>


                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}>Themen zuweisen (bitte einfach nur das Häckchen setzen):</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row>
                                        {this.state.userIdes.map((item, index) => {
                                            return (
                                                <Col xs={12} md={6} lg={4} key={index}>
                                                    <div style={{
                                                        backgroundColor: "#5cb85c",
                                                        color: "rgb(255, 255, 255)",
                                                        margin: "0.5rem",
                                                        padding: "1rem",
                                                        borderColor: "rgb(217, 217, 217)",
                                                        borderRadius: "10px",
                                                    }}>

                                                        <Row>
                                                            <Col>

                                                                [{item.id}] <strong>{item.context}</strong>
                                                                <Form.Check
                                                                    custom
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    label=""
                                                                    value={item.connected}
                                                                    checked={item.connected != null ? true : false}
                                                                    onChange={() => this.connectProject(item.id, item.connected)}
                                                                />

                                                            </Col>
                                                        </Row>


                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }


    connectProject = async (id, hideit) => {
        const teamid = this.props.match.params.ide;
        const hideityn = hideit === '0' || hideit === null ? "1" : "0";
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/connectProject.php",
            { id, teamid, hideityn }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserSavedItems(this.context.username, this.props.match.params.ide);
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadTeam = async (teamid) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getTeam.php",
            { teamid }
        );
        const { ok, data } = response || {};
        if (ok) {

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({teamMembers: data.result});

                this.setState({loading: false});

                this.loadUserSavedItems(this.context.username, teamid);
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user, teamid) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getItems.php",
            { user, teamid }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userIdes: data.result});


                const onlyContexts = data.result.reduce((obj, {context, id}) => {
                    const dateArr = obj[context] || [];
                    dateArr.push(context);
                    return {...obj, [context]: dateArr};
                }, {});

                const onlyContextsA = Object.values(onlyContexts);

                this.setState({onlyContexts: onlyContextsA});
                console.log(onlyContextsA)

                this.setState({loading: false});

            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

}

export default Team;
