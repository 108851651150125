import React, { PureComponent, Fragment } from "react";
import Loader from "../../components/Loader";
import { Link } from 'react-router-dom';
import { api } from "../../lib/apisauce";
import 'react-circular-progressbar/dist/styles.css';
import UserContext, {UserConsumer} from "../../userContext";
import { Button, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { Trans } from "react-i18next";
import  "../../i18n.js";

class ConfirmRegister extends PureComponent {
    static contextType = UserContext;

    componentDidMount() {
        this.confirmRegister();
    }

    state = {
        code: this.props.match.params.code,
        confirmed: false,
        trycatch: false,
        loading: false
    };

    render() {
        console.log(this.state)
        return (
            <div>
                <UserConsumer>
                    {({ isLoggedIn }) => (<Fragment>
                        {this.state.loading && <div className="loader">
                            <Loader />
                        </div>}
                        <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem", paddingTop: "4rem" }}>
                            <Container style={{ paddingTop: "3rem" }}>
                                {this.state.confirmed && <Col xs={12} sm={12} md={12}>
                                    <h3 style={{ paddingBottom: "3rem" }}><Trans i18nKey="confirmRegister_yes" /> </h3>
                                    <Link to="/Signin"><Button variant="primary"><Trans i18nKey="confirmRegister_anmelden" /></Button></Link>
                                </Col>}
                                {this.state.trycatch && <Col xs={12} sm={12} md={12}>
                                    <h3 style={{ paddingBottom: "3rem" }}><Trans i18nKey="confirmRegister_etwas" /></h3>

                                </Col>}

                            </Container>
                        </div>


                    </Fragment>)}
                </UserConsumer>
            </div>
        );
    }

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    confirmRegister = async () => {
        this.setState({ loading: true});
        const code = this.state.code;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putConfirmRegister.php",
            { code }
        );
        const { ok, data } = response || {};
        if (ok) {
            if(data.result.status === '200') {
                this.setState({ confirmed: true});
            } else {
                if (data.result.status === '500') {
                    this.setState({ confirmed: false});
                    this.setState({ trycatch: true});
                }
            }
            this.setState({ loading: false});

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }

            this.setState({ confirmed: false});
            this.setState({ trycatch: true});
            this.setState({ loading: false});
        }
    };

}

export default ConfirmRegister