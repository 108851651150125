import React from "react";
import { Button } from 'react-bootstrap';
import VideoCallFrame from '../../VideoCallFrame';
import { Trans } from "react-i18next";
import  "../../i18n.js";

const Toggle = () => {
  const [show, toggleShow] = React.useState(false);

  return (
    <div>
      <Button onClick={() => toggleShow(!show)}><Trans i18nKey="toggle_video" /> {show ? 'Beenden' : 'Starten'}</Button>
      {show && <div><p style={{marginTop: "1em"}}><Trans i18nKey="toggle_dukannst" /> </p><VideoCallFrame
          url={ process.env.REACT_APP_DAILY_ROOM_URL }
        ></VideoCallFrame>
        <div id="link">
        </div>
        <div id="clickable">
        </div>
        <div id="whatsapp">
        </div></div>}
    </div>
  )
}

export default Toggle;
