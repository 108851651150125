import React from 'react';
import './style.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

function Impressum() {
    return (
        <div>
                <Container>
                    <Row>

                        <Col>
                        <Card class="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"><Trans i18nKey="jobs_date" /> </p>
                            <h3 class="title"><Trans i18nKey="jobs_title" /> </h3><h4> <Trans i18nKey="jobs_desc" /> </h4>
                            <span><Trans i18nKey="jobs_mail" /> jobs at tensorparc dot com </span>
                            </Card>
                        </Col>
                    </Row>
                </Container>

        </div>
    );
}

const NewComponent100 = withTranslation()(Impressum)


export default function App() {
  return (

      <NewComponent100 />

  );
}
