
import { Card, Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import React, { PureComponent, Fragment } from "react";

class Audio extends PureComponent {

  playAudio() {
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
  }


  render() {
    return (
      <div>
             <button className="btn-primary center" onClick={this.playAudio}>
               <span>Mary says...</span>
             </button>
             <audio className="audio-element">
               <source src="https://raw.githubusercontent.com/justkaran/a/master/hello.mp3"></source>
             </audio>
           </div>
    )
  }

}
export default Audio;
