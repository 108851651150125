import React from 'react';
import './style.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function Impressum() {
    return (
        <div>
            <Container>
                <Row>

                    <Col>
                            <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray"}}>
                    <p class="datefont"> Fassung vom 2. April 2020</p>
                    <h1>TensorParc's Datenschutzerklärung</h1>
<h3>Welche Informationen TensorParc sammelt</h3>
<p><b>"Persönliche Benutzerdaten"</b> sind alle Informationen über einen unserer Benutzer, die ihn allein oder zusammen mit anderen Informationen persönlich identifizieren oder anderweitig in angemessener Weise mit ihm verknüpft oder in Verbindung gebracht werden könnten. Informationen wie ein Benutzername und ein Passwort, eine E-Mail-Adresse, ein echter Name, eine Internetprotokoll (IP)-Adresse und ein Foto sind Beispiele für persönliche Benutzerdaten".</p>
<p>
Persönliche Daten von Benutzern umfassen keine aggregierten, nicht persönlich identifizierenden Informationen, die einen Benutzer nicht identifizieren oder auf andere Weise nicht vernünftig mit ihm verknüpft oder verbunden werden können. Wir können solche aggregierten, nicht persönlich identifizierenden Informationen zu Forschungszwecken und zum Betrieb, zur Analyse, zur Verbesserung und zur Optimierung unserer Website und unseres Service verwenden.
</p>

<h3>Informationen, die die Benutzer TensorParc zur Verfügung stellen</h3>
<b>Informationen zur Registrierung</b>
<p>
Wir benötigen einige grundlegende Informationen zum Zeitpunkt der Kontoerstellung. Wenn Sie Ihren eigenen Benutzernamen und Ihr Passwort erstellen, bitten wir Sie um eine gültige E-Mail-Adresse.
</p>

<b>Informationen zum Profil</b>
<p> Sie können uns weitere Informationen für Ihr Kontoprofil geben, wie Ihren vollständigen Namen, einen Avatar, der ein Foto enthalten kann, Ihre Biografie, Ihren Standort, Ihr Unternehmen und eine URL zu einer Website eines Drittanbieters. Diese Informationen können persönliche Informationen des Benutzers enthalten.
</p>
<b>Bitte beachten Sie, dass Ihre Profilinformationen für andere sichtbar sein können. Dies können Nutzer unseres Dienstes sein.</b>

<b>Informationen zur Verwendung</b>
<p>
Wenn Sie auf unseren Dienst oder unsere Website zugreifen, erfassen wir automatisch die gleichen grundlegenden Informationen, die die meisten Dienste erfassen, gegebenenfalls mit Ihrer Zustimmung. Dazu gehören Informationen darüber, wie Sie den Dienst nutzen, wie z.B. die von Ihnen angezeigten Seiten, die verweisende Website, Ihre IP-Adresse und Sitzungsinformationen sowie Datum und Uhrzeit jeder Anfrage. Dies sind Informationen, die wir von jedem Besucher der Website sammeln, unabhängig davon, ob er ein Konto hat oder nicht. Diese Informationen können persönliche Informationen des Benutzers enthalten.
</p>

<b>Informationen über Cookies und ähnliche Technologien</b>
<p>Wie weiter unten beschrieben und gegebenenfalls vorbehaltlich Ihrer Zustimmung, sammeln wir automatisch Informationen aus Cookies und ähnlichen Technologien (wie z.B. Cookie-ID und Einstellungen), um Sie eingeloggt zu halten, Ihre Präferenzen zu speichern und Sie und Ihr Gerät zu identifizieren.
</p>
<b>Informationen zum Gerät</b>
<p>Wir können bestimmte Informationen über Ihr Gerät erfassen, wie z. B. seine IP-Adresse, Informationen über den Browser oder die Client-Anwendung, die bevorzugte Sprache, das Betriebssystem und die Anwendungsversion, den Gerätetyp und die ID sowie das Gerätemodell und den Hersteller. Diese Informationen können persönliche Informationen des Benutzers enthalten.
</p>


<b>Informationen, die wir von Dritten sammeln</b>
<p>
TensorParc kann persönliche Informationen der Nutzer von Dritten sammeln. Dies kann zum Beispiel passieren, wenn Sie sich für eine Schulung anmelden oder um Informationen über TensorParc von einem unserer Anbieter, Partner oder Partnerunternehmen zu erhalten. TensorParc kauft keine persönlichen Informationen der Benutzer von externen Datenmaklern.
</p>
<h3>Welche Informationen TensorParc nicht sammelt</h3>
<p>
Wir sammeln nicht absichtlich "Sensible persönliche Informationen", wie z.B. persönliche Daten, aus denen die rassische oder ethnische Herkunft, politische Meinungen, religiöse oder philosophische Überzeugungen oder die Gewerkschaftszugehörigkeit hervorgehen, sowie die Verarbeitung von genetischen Daten, biometrischen Daten zum Zwecke der eindeutigen Identifizierung einer natürlichen Person, Daten über die Gesundheit oder Daten über das Sexualleben oder die sexuelle Ausrichtung einer natürlichen Person. Wenn Sie sich dafür entscheiden, sensible persönliche Daten auf unseren Servern zu speichern, sind Sie für die Einhaltung jeglicher gesetzlicher Kontrollen bezüglich dieser Daten verantwortlich.
</p>
<p>
<b>Wenn Sie ein Kind unter 13 Jahren sind</b>, dürfen Sie kein Benutzerkonto bei TensorParc haben. TensorParc sammelt wissentlich keine Informationen von unseren Inhalten und richtet diese auch nicht speziell an Kinder unter 13 Jahren. Wenn wir erfahren oder den begründeten Verdacht haben, dass Sie ein Benutzer unter oder von 13 Jahren sind, müssen wir Ihr Konto schließen. Wir wollen Sie nicht vom Lernen oder Forschen abhalten, aber das sind die Regeln, die wir wollen und einhalten müssen. Bitte beachten Sie unsere Nutzungsbedingungen für Informationen zur Kündigung des Accounts. Verschiedene Länder können unterschiedliche Mindestalter haben, und wenn Sie unter dem Mindestalter für die Erteilung der Zustimmung zur Datenerfassung in Ihrem Land sind, haben Sie möglicherweise kein Konto bei TensorParc.</p>
<p>
Wir sammeln nicht absichtlich persönliche Informationen der Benutzer, die in Ihren Projekten oder anderen frei zugänglichen Inhaltseingaben gespeichert werden. Alle persönlichen Informationen innerhalb des Projekts eines Benutzers liegen in der Verantwortung des Projektinhabers.
</p>

<h3>Wie TensorParc Ihre Informationen verwendet</h3>
<p>Wir können Ihre Informationen für die folgenden Zwecke verwenden:</p>
<ul>
<li>Wir verwenden Ihre Registrierungsinformationen, um Ihr Konto zu erstellen und Ihnen den Service zur Verfügung zu stellen.</li>
<li>Wir verwenden Ihre persönlichen Benutzerinformationen, insbesondere Ihren Benutzernamen, um Sie auf TensorParc zu identifizieren.</li>
<li>Wir verwenden Ihre Profilinformationen, um Ihr Kontoprofil auszufüllen und dieses Profil mit anderen Benutzern zu teilen, wenn Sie uns darum bitten.</li>
<li>Wir verwenden Ihre E-Mail-Adresse, um mit Ihnen zu kommunizieren, wenn Sie gesagt haben, dass das in Ordnung ist. </li>
<li>Wir verwenden die persönlichen Daten der Benutzer, um auf Support-Anfragen zu reagieren.</li>
<li>Wir verwenden persönliche Informationen der Benutzer und andere Daten, um Ihnen Empfehlungen zu geben, z.B. um Projekte vorzuschlagen, die Sie verfolgen oder zu denen Sie beitragen möchten. Wir lernen aus Ihrem öffentlichen Verhalten auf TensorParc - wie z.B. den Projekten, die Sie gespeichert haben - um Ihre Themeninteressen zu bestimmen, und wir empfehlen ähnliche Projekte. Diese Empfehlungen sind automatisierte Entscheidungen, haben aber keine rechtlichen Auswirkungen auf Ihre Rechte.</li>
<li>Wir können die persönlichen Daten der Benutzer verwenden, um Sie zur Teilnahme an Umfragen, Betaprogrammen oder anderen Forschungsprojekten einzuladen, gegebenenfalls mit Ihrer Zustimmung.</li>
<li>Wir verwenden Nutzungsinformationen und Geräteinformationen, um besser zu verstehen, wie unsere Nutzer TensorParc nutzen und um unsere Website und unseren Service zu verbessern.</li>
<li>Wir können Ihre persönlichen Benutzerinformationen verwenden, wenn es für Sicherheitszwecke oder zur Untersuchung eines möglichen Betrugs oder Versuchen, TensorParc oder unseren Benutzern zu schaden, notwendig ist.</li>
<li>Wir können Ihre persönlichen Benutzerdaten verwenden, um unsere gesetzlichen Verpflichtungen zu erfüllen, unser geistiges Eigentum zu schützen und unsere Nutzungsbedingungen durchzusetzen.</li>
<li>Wir beschränken die Verwendung Ihrer persönlichen Benutzerdaten auf die in dieser Datenschutzerklärung aufgeführten Zwecke. Wenn wir Ihre persönlichen Benutzerdaten für andere Zwecke verwenden müssen, werden wir Sie zuerst um Ihre Zustimmung bitten. In Ihrem Benutzerprofil können Sie jederzeit sehen, welche Informationen wir haben, wie wir sie verwenden und welche Berechtigungen Sie uns erteilt haben.</li>
</ul>

<h3>Unsere rechtlichen Grundlagen für die Informationsverarbeitung</h3>
<p>n dem Maße, in dem unsere Verarbeitung Ihrer persönlichen Benutzerdaten bestimmten internationalen Gesetzen unterliegt (einschließlich, aber nicht beschränkt auf die allgemeine Datenschutzverordnung der Europäischen Union (GDPR)), ist TensorParc verpflichtet, Sie über die rechtliche Grundlage zu informieren, auf der wir persönliche Benutzerdaten verarbeiten. TensorParc verarbeitet persönliche Informationen der Benutzer auf den folgenden rechtlichen Grundlagen:
</p>

<b>Vertragserfüllung:</b>
<p>
Wenn Sie ein TensorParc-Konto erstellen, geben Sie Ihre Registrierungsinformationen an. Wir benötigen diese Informationen, damit Sie den Vertrag über die Nutzungsbedingungen mit uns abschließen können, und wir verarbeiten diese Informationen auf der Grundlage der Erfüllung dieses Vertrags. Wir verarbeiten Ihren Benutzernamen und Ihre E-Mail-Adresse auch auf anderen rechtlichen Grundlagen, wie unten beschrieben.
</p>

<b>Einverstanden:</b>
<p>

Wir verlassen uns auf Ihr Einverständnis, Ihre persönlichen Benutzerdaten unter den folgenden Umständen zu verwenden: wenn Sie die Informationen in Ihrem Benutzerprofil ausfüllen; wenn Sie sich entscheiden, an einer TensorParc-Schulung, einem Forschungsprojekt, einem Betaprogramm oder einer Umfrage teilzunehmen; und für Marketingzwecke, falls zutreffend. Alle diese persönlichen Daten der Benutzer sind völlig freiwillig und Sie haben die Möglichkeit, sie jederzeit einzusehen und zu ändern. Sie können Ihre Einwilligung jederzeit widerrufen. Bitte benachrichtigen Sie uns sofort, wenn Sie dies tun, indem Sie an privacy@tensorparc.com schreiben und die E-Mail-Adresse angeben, die Sie auf unserer Website verwenden.
</p>
<b>
Berechtigte Interessen:
</b>

<p>
Im Allgemeinen ist die übrige Verarbeitung der persönlichen Benutzerinformationen, die wir durchführen, für die Zwecke unseres legitimen Interesses notwendig, zum Beispiel für die Einhaltung von Gesetzen, für Sicherheitszwecke oder zur Aufrechterhaltung der laufenden Vertraulichkeit, Integrität, Verfügbarkeit und Belastbarkeit der Systeme, der Website und des Service von TensorParc. Wenn Sie die Löschung von Daten, die wir aufgrund einer Einwilligung verarbeiten, beantragen möchten oder wenn Sie gegen unsere Verarbeitung personenbezogener Daten Einspruch erheben, schreiben Sie bitte an privacy@tensorparc.com.
</p>
<h3>Wie wir die von uns gesammelten Informationen teilen</h3>
<p>Wir können Ihre persönlichen Benutzerdaten unter einem der folgenden Umstände an Dritte weitergeben::</p>

<b>Mit Ihrer Zustimmung</b>
<p>Wenn Sie ein Mitglied einer Organisation sind, kann TensorParc Ihren Benutzernamen, Ihre Nutzungsinformationen und Geräteinformationen, die mit dieser Organisation verbunden sind, mit einem Eigentümer und/oder Verwalter der Organisation teilen, der den Unternehmensbedingungen oder den anwendbaren Kundenvereinbarungen zugestimmt hat, in dem Maße, dass diese Informationen nur zur Untersuchung oder Reaktion auf einen Sicherheitsvorfall, der die Sicherheit dieser bestimmten Organisation beeinträchtigt oder kompromittiert, zur Verfügung gestellt werden.</p>

<b>Für die rechtliche Offenlegung</b>
<p>TensorParc bemüht sich um Transparenz bei der Einhaltung von rechtlichen Verfahren und rechtlichen Verpflichtungen. Sofern wir nicht durch Gesetz oder Gerichtsbeschluss oder unter seltenen, dringenden Umständen daran gehindert werden, bemühen wir uns in angemessener Weise, die Benutzer über eine gesetzlich erzwungene oder erforderliche Offenlegung ihrer Informationen zu informieren. TensorParc kann persönliche Benutzerdaten oder andere Informationen, die wir über Sie sammeln, an die Strafverfolgungsbehörden weitergeben, wenn dies als Reaktion auf eine gültige Vorladung, einen Gerichtsbeschluss, einen Durchsuchungsbefehl oder eine ähnliche Regierungsanordnung erforderlich ist, oder wenn wir in gutem Glauben glauben, dass die Weitergabe notwendig ist, um unsere gesetzlichen Verpflichtungen zu erfüllen, unser Eigentum oder unsere Rechte oder die von Dritten oder der Öffentlichkeit im Allgemeinen zu schützen.
</p>

<b>For security purposes</b>
<p>If you are a member of an Organization, TensorParc may share your username, Usage information, and Device information associated with that Organization with an owner and/or administrator of the Organization who has agreed to the Corporate Terms of Service or applicable customer agreements, to the extent that such information is provided only to investigate or respond to a security incident that affects or compromises the security of that particular Organization.
</p>

<b>For legal disclosure</b>
<p>TensorParc strives for transparency in complying with legal process and legal obligations. Unless prevented from doing so by law or court order, or in rare, exigent circumstances, we make a reasonable effort to notify users of any legally compelled or required disclosure of their information. TensorParc may disclose user personal information or other information we collect about you to law enforcement if required in response to a valid subpoena, court order, search warrant, a similar government order, or when we believe in good faith that disclosure is necessary to comply with our legal obligations, to protect our property or rights, or those of third parties or the public at large.</p>


<h3>Änderung der Kontrolle oder Veräußerung</h3>
<p> Wir können persönliche Daten der Benutzer weitergeben, wenn wir an einer Fusion, einem Verkauf oder einer Übernahme von Unternehmenseinheiten oder Geschäftsbereichen beteiligt sind. Wenn ein solcher Eigentümerwechsel stattfindet, stellen wir sicher, dass er unter Bedingungen erfolgt, die die Vertraulichkeit der persönlichen Daten der Benutzer wahren, und wir werden Sie auf unserer Website oder per E-Mail benachrichtigen, bevor wir Ihre persönlichen Benutzerdaten übertragen. Die Organisation, die persönliche Daten von Benutzern erhält, muss alle Versprechen einhalten, die wir in unserer Datenschutzerklärung oder unseren Nutzungsbedingungen gemacht haben.</p>

<b>Aggregierte, nicht persönlich identifizierende Informationen</b>
<p> Wir teilen bestimmte aggregierte, nicht persönlich identifizierende Informationen mit anderen darüber, wie unsere Nutzer TensorParc gemeinsam nutzen oder wie unsere Nutzer auf unsere anderen Angebote, wie z.B. unsere Konferenzen oder Veranstaltungen, reagieren. Zum Beispiel können wir Statistiken über die Open-Source-Aktivität in TensorParc erstellen.</p>

<p> Wir verkaufen Ihre persönlichen Benutzerdaten nicht gegen eine finanzielle oder andere Gegenleistung.</p>

<b>Bitte beachten Sie::</b> <p> Der California Consumer Privacy Act of 2018 ("CCPA") verpflichtet Unternehmen, in ihren Datenschutzrichtlinien anzugeben, ob sie persönliche Daten gegen eine finanzielle oder andere wertvolle Gegenleistung offenlegen oder nicht. Obwohl das CCPA nur für Einwohner Kaliforniens gilt, werden wir, wenn es in Kraft tritt, freiwillig seine Kernrechte für Personen zur Kontrolle ihrer Daten auf alle unsere Nutzer in den Vereinigten Staaten ausdehnen, nicht nur auf diejenigen, die in Kalifornien leben. Hier erfahren Sie mehr über den CCPA und wie wir ihn einhalten.</p>

<h3>Andere wichtige Informationen</h3>
<b>Inhalt des Projekts</b>
<p>
Die Mitarbeiter von TensorParc haben keinen Zugang zu privaten Projekten, es sei denn, dies ist aus Sicherheitsgründen, zur Unterstützung des Projekteigentümers in einer Unterstützungsangelegenheit, zur Aufrechterhaltung der Integrität des Dienstes oder zur Erfüllung unserer gesetzlichen Verpflichtungen erforderlich. Obwohl wir im Allgemeinen nicht nach Inhalten in Ihren Projekten suchen, können wir jedoch unsere Server und Inhalte scannen, um bestimmte Tokens oder Sicherheitssignaturen, bekannte aktive Malware oder Bilder von Kinderausbeutung zu erkennen. Unsere Nutzungsbedingungen enthalten weitere Einzelheiten.
</p>
<p>
Wenn Ihr Projekt öffentlich ist, kann jeder seinen Inhalt einsehen. Wenn Sie private, vertrauliche oder sensible persönliche Informationen wie E-Mail-Adressen oder Passwörter in Ihr öffentliches Projekt aufnehmen, können diese Informationen von Suchmaschinen indexiert oder von Dritten verwendet werden.
</p>


<h3>Öffentliche Information über TensorParc</h3>
<p>
Viele der Dienste und Funktionen von TensorParc sind öffentlich zugänglich. Wenn Ihr Inhalt öffentlich zugänglich ist, können Dritte auf ihn zugreifen und ihn in Übereinstimmung mit unseren Nutzungsbedingungen verwenden, z. B. durch Anzeigen Ihres Profils oder Ihrer Projekte oder durch Abrufen von Daten über unsere API. Wir verkaufen diese Inhalte nicht; sie gehören Ihnen. Wir erlauben jedoch Dritten, wie Forschungsorganisationen oder Archiven, die Zusammenstellung von öffentlich zugänglichen TensorParc-Informationen. Andere Dritte, wie z.B. Datenmakler, sind dafür bekannt, TensorParc zu verschrotten und ebenfalls Daten zu sammeln.
</p>
<p>
Ihre persönlichen Benutzerinformationen, die mit Ihrem Inhalt verbunden sind, könnten von Dritten in diesen Zusammenstellungen von TensorParc-Daten gesammelt werden. Wenn Sie nicht möchten, dass Ihre persönlichen Benutzerinformationen in den Zusammenstellungen von TensorParc-Daten durch Dritte erscheinen, machen Sie Ihre persönlichen Benutzerinformationen bitte nicht öffentlich zugänglich. Wir setzen die E-Mail-Adresse der Benutzer derzeit standardmäßig auf privat, aber ältere TensorParc-Benutzer müssen ihre Einstellungen möglicherweise aktualisieren.
</p>
<p>
Wenn Sie TensorParc-Daten zusammenstellen möchten, müssen Sie unsere Nutzungsbedingungen bezüglich Scraping und Datenschutz einhalten, und Sie dürfen die persönlichen Daten der Benutzer, die Sie öffentlich zugänglich machen, nur für den Zweck verwenden, für den unser Benutzer sie autorisiert hat. Wenn ein TensorParc-Benutzer zum Beispiel eine E-Mail-Adresse zum Zweck der Identifizierung und Zuordnung öffentlich gemacht hat, verwenden Sie diese E-Mail-Adresse nicht für kommerzielle Werbung. Wir erwarten von Ihnen, dass Sie alle persönlichen Benutzerinformationen, die Sie von TensorParc gesammelt haben, in angemessener Weise sichern und unverzüglich auf Beschwerden, Entfernungsanfragen und Anfragen von TensorParc oder TensorParc-Benutzern "nicht zu kontaktieren" reagieren.
</p>
<p>
In ähnlicher Weise können Projekte auf TensorParc öffentlich zugängliche persönliche Informationen der Benutzer enthalten, die als Teil des kollaborativen Prozesses gesammelt wurden. Wenn Sie eine Beschwerde über persönliche Informationen der Benutzer von TensorParc haben, senden Sie bitte eine E-Mail an privacy@tensorparc.com.
</p>


<h3>Zusätzliche Dienstleistungen</h3>
<b>Verwendung von Mixpanel</b>
<p>
Diese Website benutzt Mixpanel, einen Webanalysedienst, der von Mixpanel Inc. ("Mixpanel"). Mixpanel verwendet sog. 'Events', Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch die Veranstaltung generierten Informationen über Ihre Nutzung dieser Website werden in der Regel an einen Mixpanel-Server in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf diesen Webseiten wird Ihre IP-Adresse jedoch zuvor von Mixpanel gekürzt. Wenn Sie sich in der Europäischen Union befinden, geschieht dies innerhalb der Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum. Nur in Ausnahmefällen wird die vollständige IP-Adresse an einen Mixpanel-Server in den USA gesendet und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Mixpanel diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Die von Mixpanel Analytics im Rahmen von Mixpanel Analytics zur Verfügung gestellte IP-Adresse wird nicht mit anderen Mixpanel-Daten zusammengeführt. Sie können die Speicherung von Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können.
</p>

<b>Wie Sie auf die von uns gesammelten Informationen zugreifen und sie kontrollieren können</b>
<p>
Wenn Sie bereits ein TensorParc-Benutzer sind, können Sie auf Ihre grundlegenden Benutzerprofilinformationen zugreifen, sie aktualisieren, ändern oder löschen, indem Sie Ihr Benutzerprofil bearbeiten oder den TensorParc-Support kontaktieren. Sie können die Informationen, die wir über Sie sammeln, kontrollieren, indem Sie einschränken, welche Informationen in Ihrem Profil enthalten sind, indem Sie Ihre Informationen aktuell halten oder indem Sie den TensorParc-Support unter support@TensorParc.com kontaktieren.
</p>
<p>
Wenn TensorParc Informationen über Sie verarbeitet, wie z.B. Informationen, die TensorParc von Dritten erhält, und Sie kein Konto haben, dann können Sie, vorbehaltlich des geltenden Rechts, auf Ihre persönlichen Informationen zugreifen, sie aktualisieren, ändern, löschen oder der Verarbeitung Ihrer persönlichen Informationen widersprechen, indem Sie sich an den TensorParc-Support wenden.
</p>

<b>Daten-Portabilität</b>
<p>
Als Benutzer von TensorParc können Sie Ihre Daten jederzeit mitnehmen. Sie können Ihre Projekte z.B. auf Ihrem Desktop speichern.
</p>

<b>Aufbewahrung und Löschung von Daten</b>
<p>
Im Allgemeinen bewahrt TensorParc die persönlichen Daten der Nutzer so lange auf, wie Ihr Konto aktiv ist oder wie es für die Bereitstellung von Dienstleistungen erforderlich ist.
</p>
<p>
Wenn Sie Ihr Konto löschen oder Ihre persönlichen Daten löschen möchten, können Sie dies in Ihrem Benutzerprofil oder durch Schreiben an support@tensorparc.com tun. Wir behalten und verwenden Ihre Daten, soweit dies zur Erfüllung unserer rechtlichen Verpflichtungen, zur Beilegung von Streitigkeiten und zur Durchsetzung unserer Vereinbarungen erforderlich ist, aber wenn keine rechtlichen Anforderungen bestehen, werden wir Ihr vollständiges Profil (innerhalb eines angemessenen Zeitraums) innerhalb von 90 Tagen nach Ihrer Anfrage löschen. Sie können sich an den TensorParc-Support wenden, um die Löschung der von uns verarbeiteten Daten auf der Grundlage der Zustimmung innerhalb von 30 Tagen zu beantragen.
</p>
<p>
Nach der Löschung eines Kontos bleiben bestimmte Daten, wie Beiträge zu Projekten anderer Benutzer und Kommentare in den Ausgaben anderer Benutzer, erhalten. Wir werden jedoch Ihre persönlichen Benutzerdaten, einschließlich Ihres Benutzernamens und Ihrer E-Mail-Adresse, löschen oder entidentifizieren.
</p>


<h3>Unser Einsatz von Cookies und Tracking</h3>
<b>Cookies</b>
<p>
TensorParc verwendet Cookies, um Interaktionen mit unserem Service einfach und sinnvoll zu gestalten. Cookies sind kleine Textdateien, die von Websites häufig auf den Festplatten oder mobilen Geräten der Besucher gespeichert werden. Wir verwenden Cookies (und ähnliche Technologien, wie HTML5 localStorage), um Sie eingeloggt zu halten, Ihre Präferenzen zu speichern und Informationen für die zukünftige Entwicklung von TensorParc bereitzustellen. Aus Sicherheitsgründen verwenden wir Cookies, um ein Gerät zu identifizieren. Durch die Nutzung unserer Website erklären Sie sich damit einverstanden, dass wir diese Art von Cookies auf Ihrem Computer oder Gerät platzieren können. Wenn Sie die Fähigkeit Ihres Browsers oder Geräts, diese Cookies zu akzeptieren, deaktivieren, können Sie sich nicht einloggen oder die Dienstleistungen von TensorParc nutzen.
</p>


<h3>Wie TensorParc Ihre Informationen sichert</h3>
<p>TensorParc ergreift alle Maßnahmen, die vernünftigerweise notwendig sind, um die persönlichen Informationen der Benutzer vor unbefugtem Zugriff, Änderung oder Zerstörung zu schützen, die Datengenauigkeit zu erhalten und die angemessene Nutzung der persönlichen Informationen der Benutzer sicherzustellen.</p>
<p>
Die Übertragung von Daten auf TensorParc wird mit SSH, HTTPS (TLS) verschlüsselt, und der Projektinhalt wird im Ruhezustand verschlüsselt. Wir verwalten unsere eigenen Cages und Racks in erstklassigen Rechenzentren mit hoher physischer und Netzwerksicherheit, und wenn Daten bei einem Drittanbieter gespeichert werden, werden sie verschlüsselt.
</p>
<p>
Keine Übertragungsmethode oder Methode der elektronischen Speicherung ist 100% sicher. Daher können wir seine absolute Sicherheit nicht garantieren.
</p>

<h3>TensorParc's globale Datenschutzpraktiken</h3>
<p>Wir speichern und verarbeiten die Informationen, die wir in den Vereinigten Staaten sammeln, in Übereinstimmung mit dieser Datenschutzerklärung, obwohl unsere Dienstleister Daten außerhalb der Vereinigten Staaten speichern und verarbeiten können. Wir verstehen jedoch, dass wir Nutzer aus verschiedenen Ländern und Regionen mit unterschiedlichen Erwartungen an den Datenschutz haben, und wir versuchen, diesen Bedürfnissen gerecht zu werden, auch wenn die Vereinigten Staaten nicht den gleichen Datenschutzrahmen wie andere Länder haben.</p>
<p>
Wir bieten allen unseren Nutzern auf der ganzen Welt, unabhängig von ihrem Herkunftsland oder Standort, einen hohen Standard beim Schutz der Privatsphäre - wie in dieser Datenschutzerklärung beschrieben - und wir sind stolz auf das Niveau der Benachrichtigung, der Wahlmöglichkeiten, der Verantwortlichkeit, der Sicherheit, der Datenintegrität, des Zugriffs und der Rückgriffsmöglichkeiten, die wir bieten. Wir arbeiten hart daran, die geltenden Datenschutzgesetze überall dort einzuhalten, wo wir geschäftlich tätig sind, und arbeiten dabei mit unserem Datenschutzbeauftragten als Teil eines funktionsübergreifenden Teams zusammen, das unsere Bemühungen um die Einhaltung des Datenschutzes überwacht. Wenn unsere Anbieter oder Partner Zugang zu persönlichen Daten der Benutzer haben, müssen sie zusätzlich Vereinbarungen unterzeichnen, die sie zur Einhaltung unserer Datenschutzrichtlinien und der geltenden Datenschutzgesetze verpflichten.
</p>

<h3>Grenzüberschreitende Datenübertragungen</h3>
<p>
TensorParc erfüllt den EU-US-Datenschutzschild und den schweizerisch-amerikanischen Rahmen, wie er vom US-Handelsministerium hinsichtlich der Sammlung, Verwendung und Aufbewahrung von persönlichen Benutzerinformationen, die von der Europäischen Union in die Vereinigten Staaten übertragen werden, festgelegt wurde.
</p>
<p>
Wenn es einen Konflikt zwischen den Bedingungen in dieser Datenschutzerklärung und den Datenschutzschutzprinzipien gibt, gelten die Datenschutzschutzprinzipien.
</p>

<b>Wie wir mit Ihnen kommunizieren</b>
<p>
Wir benutzen Ihre E-Mail-Adresse, um mit Ihnen zu kommunizieren, wenn Sie gesagt haben, dass das in Ordnung ist, und nur aus den Gründen, die Sie gesagt haben. Wenn Sie sich zum Beispiel mit einer Anfrage an unser Support-Team wenden, antworten wir Ihnen per E-Mail. Sie haben eine Menge Kontrolle darüber, wie Ihre E-Mail-Adresse auf und durch TensorParc verwendet und weitergegeben wird. Sie können Ihre Kommunikationspräferenzen in Ihrem Benutzerprofil verwalten.
</p>

<p>
Abhängig von Ihren E-Mail-Einstellungen kann TensorParc gelegentlich Benachrichtigungs-E-Mails über Änderungen in einem von Ihnen beobachteten Projekt, neue Funktionen, Anfragen für Feedback, wichtige Richtlinienänderungen oder um Kundensupport anzubieten, versenden. Wir versenden auch Marketing-E-Mails, basierend auf Ihrer Wahl und in Übereinstimmung mit den geltenden Gesetzen und Vorschriften. Es gibt einen "Unsubscribe"-Link, der sich am Ende jeder Marketing-E-Mail befindet, die wir Ihnen senden. Bitte beachten Sie, dass Sie den Erhalt wichtiger Mitteilungen von uns, wie z.B. E-Mails von unserem Support-Team oder System-E-Mails, nicht ablehnen können, aber Sie können Ihre Benachrichtigungseinstellungen in Ihrem Profil so konfigurieren, dass Sie andere Mitteilungen ablehnen.
</p>


<b>Lösung von Beschwerden</b>
<p>
Wenn Sie Bedenken über die Art und Weise haben, wie TensorParc mit Ihren persönlichen Benutzerdaten umgeht, lassen Sie es uns bitte sofort wissen. Wir wollen helfen. Sie können mit uns Kontakt aufnehmen, indem Sie uns unter privacy@tensorparc.com schreiben. Wir werden Ihnen umgehend - spätestens innerhalb von 30 Tagen - antworten.
</p>
<p>
Wenn Sie in einem EU-Mitgliedsstaat ansässig sind, haben Sie außerdem das Recht, eine Beschwerde bei Ihrer örtlichen Aufsichtsbehörde einzureichen. Wir unterstehen der Rechtsprechung der U.S. Federal Trade Commission (FTC).
</p>

<b>Änderungen an unserer Datenschutzerklärung</b>
<p>
Obwohl die meisten Änderungen wahrscheinlich nur geringfügig sein werden, können wir unsere Datenschutzerklärung von Zeit zu Zeit ändern. Wir benachrichtigen die Nutzer über wesentliche Änderungen dieser Datenschutzerklärung über unsere Website mindestens 30 Tage vor Inkrafttreten der Änderung, indem wir eine Mitteilung auf dieser Seite veröffentlichen oder eine E-Mail an die in Ihrem TensorParc-Konto angegebene primäre E-Mail-Adresse senden. Wir empfehlen Ihnen, diese Website regelmäßig zu besuchen.
</p>                      </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default Impressum;
