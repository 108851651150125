import React from 'react';
import './style.css';
import { Card, Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js"
import $ from "jquery"

function Impressum() {

    $(document).ready(function(){
    $(".blogchars21").html($("#post21").text().length);
    $(".readingtime21").html(($("#post21").text().length)*3/100);
    $(".blogchars20").html($("#post20").text().length);
    $(".readingtime20").html(($("#post20").text().length)*3/100);
    $(".blogchars10").html($("#post10").text().length);
    $(".readingtime10").html(($("#post10").text().length)*3/100);
    $(".blogchars1").html($("#post1").text().length);
    $(".readingtime1").html(($("#post1").text().length)*3/100);
    $(".blogchars22").html($("#post22").text().length);
    $(".readingtime22").html(($("#post22").text().length)*3/100);
    });
    return (
        <div>
                <Container>
                    <Row>
                        <Col>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"> 8. November 2020 </p>
                            <h3 classes="title"> Mary hat neue Funktionen</h3>
                            <h4 id="post22"> SchülerInnen können sich jetzt ihre Antworten anhören, bevor sie diese einreichen. Das verbessert die Kommunikationsfähigkeit.</h4>
                            <span>Antworte mit einem <a class="block" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Ich%20lerne%20gerade%20mit%20Mary%20auf%20https://mary.tensorparc.com%20" data-size="large">Tweet
                            </a></span><div class="datefont">Zeichen: <span class="blogchars22"> </span>,  <Trans i18nKey="lesedauer"/><span class="readingtime22"> </span> sec</div>
                        </Card>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"> 26. August 2020 </p>
                            <h3 classes="title"> Mary ist für Lehrkräfte </h3>
                            <h4 id="post21"> Lehrkräfte können viel Zeit sparen und SchülerInnen individuell betreuen.</h4>
                            <span>Antworte mit einem <a class="block" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Ich%20lerne%20gerade%20mit%20Mary%20auf%20https://mary.tensorparc.com%20" data-size="large">Tweet
                            </a></span><div class="datefont">Zeichen: <span class="blogchars21"> </span>,  <Trans i18nKey="lesedauer"/><span class="readingtime21"> </span> sec</div>
                        </Card>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"> <Trans i18nKey="blogdate3" /> </p>
                            <h3 classes="title"> <Trans i18nKey="blogtitle3"/> </h3>
                            <h4 id="post20"> <Trans i18nKey="blogpost3"/> </h4>
                            <span>Antworte mit einem <a class="block" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Ich%20lerne%20gerade%20mit%20Mary%20auf%20https://mary.tensorparc.com%20" data-size="large">Tweet
                            </a></span><div class="datefont">Zeichen: <span class="blogchars20"> </span>,  <Trans i18nKey="lesedauer"/><span class="readingtime20"> </span> sec</div>
                        </Card>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"> <Trans i18nKey="blogdate1" /> </p>
                            <h3 classes="title"> <Trans i18nKey="blogtitle2"/> </h3>
                            <h4 id="post1"> <Trans i18nKey="blogpost2"/> </h4>
                            <span>Antworte mit einem <a class="block" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Ich%20lerne%20gerade%20mit%20Mary%20auf%20https://mary.tensorparc.com%20" data-size="large">Tweet
                            </a></span><div class="datefont">Zeichen: <span class="blogchars1"> </span>,  <Trans i18nKey="lesedauer"/> <span class="readingtime1"> </span> sec</div>
                        </Card>
                        <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                        <p class="datefont"> <Trans i18nKey="blogdate2" /> </p>
                            <h3 classes="title"> <Trans i18nKey="blogtitle"/> </h3>
                            <h4 id="post10"> <Trans i18nKey="blogpost"/> </h4>
                            <span>Antworte mit einem  <a class="block" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Ich%20lerne%20gerade%20mit%20Mary%20auf%20https://mary.tensorparc.com%20" data-size="large">Tweet
                            </a></span><div class="datefont">Zeichen: <span class="blogchars10"> </span>,  <Trans i18nKey="lesedauer"/><span class="readingtime10"> </span> sec</div>
                        </Card>
                        </Col>
                    </Row>
                </Container>

        </div>
    );
}

const NewComponent40 = withTranslation()(Impressum)


export default function App() {
  return (

      <NewComponent40 />

  );
}
