import React, { Component } from "react";
import SpeechRecognition from "react-speech-recognition";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Trans } from "react-i18next";
import  "../../i18n.js";

const options = {
  autoStart: false
}

class Dictaphone extends Component {



  componentWillMount() {
    const { recognition } = this.props
    const { browserSupportsSpeechRecognition} = this.props
    if (!browserSupportsSpeechRecognition) {
      return null;
    }
    recognition.lang = 'de-DE'
  }


  render() {
    const { transcript } = this.props
    const { resetTranscript } = this.props
    const { startListening } = this.props
    const { stopListening } = this.props
    const { browserSupportsSpeechRecognition} = this.props

    if (!browserSupportsSpeechRecognition) {
      return null;
    }

    return (
      <div  style={{borderTop: "7px dotted lightgrey"}}>
        <h5 id="paste"> <Trans i18nKey="speech_texteinsprechen" /></h5>
        <p><Trans i18nKey="speech_dukannst" /><b><button className="btn-primary" onClick={startListening }><Trans i18nKey="speech_aufnehmen" /></button></b> <Trans i18nKey="speech_taste" /> <br/>
        <Trans i18nKey="speech_fertig" /></p>
              <button className="btn-primary" onClick={startListening }><Trans i18nKey="speech_textaufnehmen" /></button>
              <button className="btn-primary" onClick={stopListening }><Trans i18nKey="speech_textstoppen" /></button>
              <button className="btn-primary" onClick={resetTranscript}><Trans i18nKey="speech_textlöschen" /></button>
                      <CopyToClipboard text={transcript}
                        onCopy={() => this.setState({copied: true}, alert('Dein Text liegt jetzt in der Zwischenablage.'))}>
                        <button><Trans i18nKey="speech_textkopieren" /></button>
                      </CopyToClipboard>

        <span> {transcript}</span>
      </div>
    )
  }
}


export default SpeechRecognition(options)(Dictaphone)
