import React from 'react';
import DailyIframe from '@daily-co/daily-js';
import {api} from "./lib/apisauce";
import Loader from "./components/Loader";

class VideoCallFrame extends React.Component {

  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  state = {
    url: '',
    loading: false,
  }

  componentDidMount() {
    this.getDailyUrl();
  }

  render() {
    return (
        <iframe className="Video-Frame"
             title="video call iframe"
             ref={this.iframeRef}
             allow="camera; microphone; fullscreen"
           >
          {this.state.loading && <div>
            <Loader/>
          </div>}
        </iframe>)
  }



  getDailyUrl = async () => {
    this.setState({ loading: true });
    const response = await api.post(
        "https://apitp.tensorparc.com/mary/getDaily.php",
        { }
    );
    const { ok, data } = response || {};
    if (ok) {
      this.setState({ url: data.url });
      console.log(data.url)
      document.getElementById("link").append("Um den Link für den Video-Chat an Deine Freunde zu senden, klicke hier: ")
      const anchor = document.createElement('a');
      anchor.href = 'mailto:?subject= Hey, hier kommt der Video-Chat Link für Mary: ' + data.url;
      anchor.innerText = '  Teile den Link per E-Mail  ';
      const anchor2 = document.createElement('a');
      anchor2.href  = 'whatsapp://send?text=Hey, hier kommt der Video-Chat Link für Mary: ' + data.url;
      anchor2.innerText = '  Teile den Link per Whatsapp  ';
      const image = document.createElement ('img')
      image.src = ('https://i.imgur.com/LOp5VVp.png');
      const image2 = document.createElement ('img')
      image2.src = ('https://i.imgur.com/87FxWga.png');
      document.getElementById("clickable").append(image);
      document.getElementById("clickable").append(anchor);
      document.getElementById("whatsapp").append(image2);
      document.getElementById("whatsapp").append(anchor2);
      this.daily = DailyIframe.wrap(this.iframeRef.current);
      this.daily.join({ url: this.state.url });
      this.setState({ loading: false });
    } else {
      // handle api call error
      if (process.env.REACT_APP_DEBUG === true) {
        console.error("getDaily Url Error", { data });
      }
    }
  };

}

export default VideoCallFrame;
