import React from 'react';
import { UserConsumer } from './userContext';

export default function UserRegisterFail() {
    return (
        <UserConsumer>
            {props => {
                return props.loginFailure ? <div style={{ color: "red", padding: "2rem" }}>Bei der Erstellung des Kontos is ein Fehler aufgetreten, bitte alle Felder ausfüllen und AGB akzeptieren.</div> : <div></div>;
            }}
        </UserConsumer>
    );
}
