import React, { PureComponent, Fragment } from "react";
import { Mixpanel } from '../../components/Mixpanel';
import Loader from "../../components/Loader";
import { api } from "../../lib/apisauce";
import 'react-circular-progressbar/dist/styles.css';
import { UserConsumer } from "../../userContext";
import UserContext from "../../userContext";
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js"
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col, Form, Card, CardColumns, CardGroup, CardDeck } from 'react-bootstrap';
import "../../index.css";
import Audio from "../../components/Audio/index.js";

class BsStart extends PureComponent {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        value: "de",
        research: '',
        question: '',
        searchTerm: '',
        prelist: [],
        context: '',
        editor: 1,
        saved: false,
        back: false,
        open: false,
        txt: '',
        resp: '',
        loadDbId: null,
        loading: false,
        open2: false,
        userIdes: [],
        openAddElement: false,
        snackBarOpen: false,
        checker: [],
        lanradio: 'de',
    };

    componentDidMount() {
        Mixpanel.identify(this.context.username);
        Mixpanel.track('User Visit at Mary');
    }

    handleChange = (e) =>{
        let { value} = e.target;
        value = value.replace(/'/g, `'\\''`)
        this.setState({txt: value});
        console.log(this.state.txt)
    }

    handleRChange = changeEvent => {
        this.setState({
            lanradio: changeEvent.target.value
        });
        console.log(changeEvent.target.value)
    };

    render() {
    const { t } = this.props;
        return (

            <Fragment>
                <UserConsumer>{({ username, password, isLoggedIn, loginFailure }) => (<Fragment>

                {this.state.loading && <div>
                    <Loader />
                </div>}

                    <Container>

                        {this.state.prelist.length <= 0 &&
                        (

                            <Row style={{marginTop: "30px" }}>

                            <Container>
                            <Row>
                                    <Col lg={true} xs={12} sm={12} md={true} xl={true}>   <h1> <Trans i18nKey="neu" /> </h1> <br/><h3 class="subtext" > <Trans i18nKey="neu1" /> </h3>

                                    <a href="/Signup"><Button size="lg" variant="success"  class="startbutton" style={{fontSize: "1em" }}>Jetzt kostenlos testen</Button></a>{' '}

                                    </Col>
                                    <Col lg={true} xs={12} sm={12} md={true} xl={true}> <img src="https://i.imgur.com/LUdocW7.jpg?1" width="100%" alt="Mary" style={{paddingTop:"1em"}}/>
                                    </Col>
                            </Row>

                            </Container>


<Container style={{ marginTop: '10rem', alignItems: 'center' }}>
<Row className="justify-content-md-center" style={{ marginTop: '3rem', alignItems: 'center' }}>

<Col lg="12" style={{textAlign: "center"}}>
  <h1><Trans i18nKey="features" /></h1>
</Col>


<CardDeck>

<Col lg="4">
  <Card style={{ borderRadius: '15px', marginTop: '2em', height: '100%' }}>
    <Card.Body style={{ borderRadius: '15px' }}>
      <Card.Img variant="top" src="https://i.imgur.com/bMyoRFy.png" />

        <h2 style={{ fontWeight:'bold' }}>In-Browser Smart Editor</h2>
      <Card.Text class="subtext">
        <Trans i18nKey="inbrowser" />
      </Card.Text>
    </Card.Body>
  </Card>
</Col>

<Col lg="4" class="align-items-stretch">
  <Card style={{ borderRadius: '15px',  marginTop: '2em', height: '100%'  }}>
    <Card.Body style={{ borderRadius: '15px' }}>
      <Card.Img variant="top" src="https://i.imgur.com/j6om8se.png" />
      <h2 style={{ fontWeight:'bold' }}><Trans i18nKey="learntogether" /></h2>
        <Card.Text class="subtext">
            <Trans i18nKey="learntogethertext" />
        </Card.Text>
    </Card.Body>
  </Card>
</Col>

<Col lg="4" class="align-items-stretch">
  <Card style={{ borderRadius: '15px', marginTop: '2em', height: '100%'  }}>
    <Card.Body style={{ borderRadius: '15px' }}>
      <Card.Img variant="top" src="https://i.imgur.com/ouCfnpD.png" />
      <h2 style={{ fontWeight:'bold' }}>Auswertungen</h2>
        <Card.Text class="subtext">
            <Trans i18nKey="teachers" />
        </Card.Text>
    </Card.Body>
  </Card>
</Col>

</CardDeck>
</Row>



<Container style={{ marginTop: '10rem', alignItems: 'center' }}>
<Row className="justify-content-md-center" style={{ marginTop: '3rem', alignItems: 'center' }}>

  <Col lg="12" style={{textAlign: "center"}}>
      <h1><Trans i18nKey="pricing" /></h1>
  </Col>

  <CardDeck>

  <Col lg="4">
    <Card style={{ borderRadius: '15px', marginTop: '2em', height: '100%' }}>
    <Card.Body style={{ borderRadius: '15px' }}>
      <Card.Img variant="top" src="" />
      <h2 style={{ fontWeight:'bold', color: '#3e8f3e' }}>Für SchülerInnen</h2>
        <Card.Text class="subtext">
        <hr/>
        Kostenlos
        </Card.Text>
    </Card.Body>
    </Card>
  </Col>

  <Col lg="4">
    <Card style={{ borderRadius: '15px', marginTop: '2em', height: '100%' }}>
    <Card.Body style={{ borderRadius: '15px' }}>
    <Card.Img variant="top" src="" />

    <h2 style={{ fontWeight:'bold', color: '#3e8f3e' }}> Für Lehrkräfte</h2>
    <Card.Text class="subtext" style={{ marginBottom: "2em" }}>

     <hr/>
     7 Tage kostenlos testen <br/>
    <hr/>
    <b>Nur 3,99 EUR pro Monat </b>
    <hr/>
    Monatlich kündbar
    </Card.Text>
    <a href="/Signup"><Button size="lg" variant="success"  class="startbutton" style={{fontSize: "1em" }}>Jetzt starten</Button></a>{' '}

  </Card.Body>
    </Card>
  </Col>

  <Col lg="4" class="align-items-stretch">
    <Card style={{ borderRadius: '15px', marginTop: '2em', height: '100%'  }}>
      <Card.Body style={{ borderRadius: '15px' }}>
        <Card.Img variant="top" src="" />
          <h2 style={{ fontWeight:'bold', color: '#3e8f3e' }}>Für Schulen</h2>
          <Card.Text class="subtext">
          <hr/>
              <Trans i18nKey="teachers" />
          </Card.Text>
      </Card.Body>
    </Card>
  </Col>

  </CardDeck>


</Row>
      </Container>
      </Container>
                     </Row>


                        )}
                        {this.state.prelist.length >= 1 &&
                        (
                            <Fragment>
                            <Row>
                                <Col>
                                    <h2 className="titlefont"> <Trans i18nKey="bsStart_schaumal" />   </h2>
                                </Col>
                            </Row>
                                <Row>
                                    <Col>
                                        <h2 className="titlefont"> <span className="titlebluepart"><Trans i18nKey="bsStart_waehle" />  </span></h2>
                                    </Col>
                                </Row>
                            <Row>
                                <Col>
                                    {this.state.prelist.map((item, ind) => {
                                        return (
                                            <Link to={{
                                                pathname: '/BsEditor',
                                                state: {
                                                    loadNouns: item.title,
                                                    lan: this.state.lanradio,
                                                }
                                            }} key={ind}>
                                                <Button className="mr-2 mb-2"
                                                >{item.title}
                                                </Button>
                                            </Link>)
                                    })}
                                </Col>
                            </Row>
                            </Fragment>
                        )
                        }
                    </Container>
                </Fragment> )}
                </UserConsumer>
            </Fragment>

        );
    }

    /**
     *
     * @method getPreList
     * @memberof LandingPage
     * WIP
     */
    getPreList = async () => {
        this.setState({ loading: true });
        const topic = this.state.txt;
        const lan = this.state.lanradio;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWPpages.php",
            { topic, lan }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ prelist: data });
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };
}

const NewComponent10 = withTranslation()(BsStart)

export default function App() {
  return (

      <NewComponent10 />

  );
}
