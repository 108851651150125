import React, { PureComponent, Fragment } from "react";
import { Mixpanel } from '../../components/Mixpanel';
import Loader from "../../components/Loader";
import { api } from "../../lib/apisauce";
import 'react-circular-progressbar/dist/styles.css';
import { UserConsumer } from "../../userContext";
import UserContext from "../../userContext";
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js"
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import "../../index.css";

import "./index.css";

class FlowStart extends PureComponent {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        value: "de",
        research: '',
        question: '',
        searchTerm: '',
        prelist: [],
        context: '',
        editor: 1,
        saved: false,
        back: false,
        open: false,
        txt: '',
        resp: '',
        loadDbId: null,
        loading: false,
        open2: false,
        userIdes: [],
        openAddElement: false,
        snackBarOpen: false,
        checker: [],
        lanradio: 'de',
        aktpath: [],
        extract: '',
        extractOrig: ''
    };

    componentDidMount() {
        Mixpanel.identify(this.context.username);
        Mixpanel.track('User Visit at Mary');
    }

    handleChange = (e) =>{
        let { value} = e.target;
        value = value.replace(/'/g, `'\\''`)
        this.setState({txt: value});
        console.log(this.state.txt)
    }

    handleRChange = changeEvent => {
        this.setState({
            lanradio: changeEvent.target.value
        });
        console.log(changeEvent.target.value)
    };

    applyHighlights(text) {
        text = text
            .replace(/\n$/g, '\n\n')
            .replace(/[A-Z].*?\b/g, '<mark>$&</mark>');
        this.setState({ extract: text })
    }

    applyHighlightsWord(word) {
        console.log("drin")

        const tempRegex = new RegExp(word, "gi");

        console.log(tempRegex)

        let text = this.state.extractOrig
            .replace(/\n$/g, '\n\n')
            .replace(tempRegex, '<mark>$&</mark>');

        console.log(text)

        this.setState({ extract: text })
    }

    render() {
        const { t } = this.props;

        console.log(this.state)

        return (

            <Fragment>
                <UserConsumer>{({ username, password, isLoggedIn, loginFailure }) => (<Fragment>

                    {this.state.loading && <div>
                        <Loader />
                    </div>}

                    <Container>

                        {this.state.prelist.length <= 0 &&
                        (

                            <Row style={{marginTop: "30px" }}>

                                <Container>
                                    <Row>
                                        <Col className="text-center"><img src="https://i.imgur.com/6dN5sBu.png?1" height="120px" alt="Mary"/></Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        <Col className="text-center">
                                            <p style={{ marginLeft: "15px" }}> <Trans i18nKey="neu" /> </p>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container>
                                    <Row>
                                        <Col>
                                            <h2 style={{ marginLeft: "15px" }}><Trans i18nKey="gibein" /> </h2>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container>
                                    <Row className="justify-content-center">
                                        <Col xs="12">
                                            <Form>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control style={{borderRadius: "20px", height: "5rem" }} type="text" size="lg" placeholder={t('bsStart_gibeinthema')} onChange={this.handleChange}  name="txt" />
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col>
                                                        <Form.Check
                                                            type="radio"
                                                            label="deutsch"
                                                            name="lanradio"
                                                            id="formHorizontalRadios1"
                                                            value="de"
                                                            checked={this.state.lanradio === "de"}
                                                            onChange={this.handleRChange}

                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            label="english"
                                                            name="lanradio"
                                                            id="formHorizontalRadios2"
                                                            value="en"
                                                            checked={this.state.lanradio === "en"}
                                                            onChange={this.handleRChange}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Col>
                                                    <Button className="btn-primary center" type="button" onClick={() => this.getPreList(this.state.txt)} >
                                                        <Trans i18nKey="losgehts" />
                                                    </Button>
                                                </Col>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Container>


                            </Row>


                        )}
                        {this.state.prelist.length >= 1 &&
                        (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <h2 className="titlefont"> <Trans i18nKey="bsStart_schaumal" />   </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2 className="titlefont"> <span className="titlebluepart"><Trans i18nKey="bsStart_waehle" />  </span></h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.prelist.map((item, ind) => {
                                            return (

                                                    <Button className="mr-2 mb-2"  key={ind}
                                                            onClick={() => this.getKeywordList(item.title)}
                                                    >{item.title}
                                                    </Button>
                                                )
                                        })}
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                        }
                        {this.state.aktpath.length >= 1 &&
                        (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <h2 className="titlefont"> <span className="titlebluepart">Die wichtigsten Begriffe zu Deinem Thema {this.state.txt} sind:</span></h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.aktpath.map((item, ind) => {
                                            return (

                                                <Button className="mr-2 mb-2"  key={ind}
                                                        onClick={() => this.applyHighlightsWord(item[0])}
                                                >{item[0]} {item[1]}
                                                </Button>
                                            )
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.extract && (

                                            <div dangerouslySetInnerHTML={{ __html: this.state.extract }} />
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                        }
                    </Container>
                </Fragment> )}
                </UserConsumer>
            </Fragment>

        );
    }

    /**
     *
     * @method getPreList
     * @memberof LandingPage
     * WIP
     */
    getPreList = async () => {
        this.setState({ loading: true });
        const topic = this.state.txt;
        const lan = this.state.lanradio;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWPpages.php",
            { topic, lan }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ prelist: data });
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getKeywordList = async (link) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getKeywordRanking.php",
            { link }
        );
        const { ok, data } = response || {};
        if (ok) {
            console.log(data);
            this.setState({ aktpath: data.result });
            this.setState({ loading: false });
            this.getExtract(link)
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getExtract = async (link) => {
        this.setState({ loading: true });
        const topic = this.state.txt;
        const lan = this.state.lanradio;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getExtract.php",
            { topic, lan }
        );
        const { ok, data } = response || {};
        if (ok) {
            console.log(data);
            this.setState({ extract: data.result.extract });
            this.setState({ extractOrig: data.result.extract });
            //this.applyHighlights(data.result.extract)
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };
}

const NewComponent10 = withTranslation()(FlowStart)

export default function App() {
    return (

        <NewComponent10 />

    );
}