import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { Button, Card, Container, Row, Col} from 'react-bootstrap';

const ElementDemos = ({ demos }) => {
  const location = useLocation();
  const history = useHistory();

  return (
<Container>
  <Row>
    <Col lg="3">
    </Col>
  <Col lg="6">
      <div className="DemoPickerWrapper">
        <select
          className="DemoPicker"
          value={location.pathname}
          onChange={event => {
            history.push(event.target.value);
          }}
        >
          {demos.map(({ path, label }) => (
            <option key={path} value={path}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <Switch>
        <Redirect to={demos[0].path} from="/" exact />
        {demos.map(({ path, component: Component }) => (
          <Route key={path} path={path}>
            <div className="Demo">
              <Component />
            </div>
          </Route>
        ))}
      </Switch>
        </Col>
      </Row>
      <Col lg="3">
      </Col>
</Container>
  );
};

export default ElementDemos;
