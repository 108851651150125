import React , { Component, Fragment }from "react";
import ReactDOM from "react-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter } from "react-router-dom";
import { Button, Card, Container, Row, Col} from 'react-bootstrap';
import ElementDemos from "../../components/ElementDemos";

import CardForm from "../../components/demos/CardForm";
import IbanForm from "../../components/demos/IbanForm";


import SplitForm from "../../components/demos/SplitForm";
import './style.css'
import {api} from "../../lib/apisauce";

const stripePromise = loadStripe("pk_live_gJQvED8s3LDjVfXSwL7cp5tu00LritP48C");

const demos = [
  {
    path: "/card-element",
    label: "Bitte wähle die Bezahlmethode",
    component: CardForm
  },
  {
    path: "/split-card-elements",
    label: "Kredikarte",
    component: SplitForm
  },
  {
    path: "/iban-element",
    label: "Bankeinzug",
    component: IbanForm
  }
];


class SubscribeForm extends Component {

  render() {
      return (
        <BrowserRouter>
        <Container>
            <Row>
                <Col>
                <h3 className="subtext2"> Spare Dir viel Zeit beim bewerten Deiner Schüler für monatlich 3,99 EUR. <br/> Es gelten unsere <a href="https://mary.tensorparc.com/TOU">AGB</a>. Kurz gesagt: die Daten gehören Dir und es ist monatlich kündbar. <br/><br/> Bitte wähle Deine Bezahlmethode. Wir arbeiten mit Stripe, die sind sicher. </h3>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col>
     <Elements stripe={stripePromise}>
       <ElementDemos demos={demos} />
     </Elements>
     </Col>
 </Row>
</Container>
   </BrowserRouter>
      );
  }






}

export default SubscribeForm;
