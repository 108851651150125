import React, { PureComponent, Fragment } from "react";
import { Mixpanel } from '../../components/Mixpanel';
import Loader from "../../components/Loader";
import { DragDropContext } from 'react-beautiful-dnd';
import { api } from "../../lib/apisauce";
import SimpleIcons from 'simple-icons-react-component';
import 'react-circular-progressbar/dist/styles.css';
import { UserConsumer } from "../../userContext";
import UserContext from "../../userContext";
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import Toggle from '../../components/Toggle/index.js'
import ToggleAudio from '../../components/ToggleAudio/index.js'
import { maybeJoinCall } from "./javascript.js";
import 'jquery-ui-dist/jquery-ui'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col, Form, Modal } from 'react-bootstrap';
import "../../index.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSave, faMagic, faMedal, faQuestionCircle, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { Trans } from "react-i18next";
import  "../../i18n.js"
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";

var writeGood = require('write-good');
var schreibGut = require('schreib-gut');

const Msg = ({ closeToast, lastWord, nextWord, index, props, keywordItem }) => (
    <div style={{ color: "#000" }}>
        <div style={{ width: "30%", float: "left", height: "100px" }}><img src="https://i.imgur.com/VudgSQK.png" style={{ width:"88px", height:"auto", verticalAlign: "middle", float: "left" }} alt=""/></div>
        {nextWord != null && <div> Super! Du hast "{lastWord}" geschrieben.<br/> Hast du auch schon etwas zu: "{nextWord[0]}" geschrieben?<br/> </div>}
        {nextWord == null && <div> Super! Du hast "{lastWord}" geschrieben. - nice!</div>}
        <hr/>
        {keywordItem[1] && <div>Mary sagt zu "{keywordItem[0]}": <br/><br/>{keywordItem[1]}</div>}
    </div>
);

const MsgMary = ({ answer }) => (
    <div style={{ color: "#000" }}>
        <div style={{ height: "75px" }}><img src="https://i.imgur.com/VudgSQK.png" style={{ width:"88px", height:"auto", verticalAlign: "middle", float: "left" }} alt=""/>
        Mary sagt:
        </div>
        <hr/>
        <div>{answer}</div>
    </div>
);

const Medal = ({ keyid, placement, badge, txt }) => (
    <OverlayTrigger
        key={keyid}
        placement={placement}
        overlay={
            <Tooltip id={keyid}>
                <div dangerouslySetInnerHTML={{__html: txt}} />
            </Tooltip>}
    >
        <FontAwesomeIcon icon={faMedal} style={{ fontSize: "36px",
            color: badge ? "#5cb85c" : "lightgrey",
        }} />
    </OverlayTrigger>
);

class BsEditor extends PureComponent {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.getNouns = this.getNouns.bind(this);
        this.contentEditable = React.createRef();
    }

    sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "p", "h1", "h2", "h3", "h4", "mark", "br"],
        allowedAttributes: { a: ["href"] }
    };

    state = {
        show: false,
        show2: false,
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        research: '',
        question: '',
        questionOrig: '',
        searchTerm: '',
        prelist: [],
        result: {
            percent: 0,
            differ: []
        },
        context: '',
        editor: 1,
        saved: false,
        back: false,
        open: false,
        txt: '',
        resp: '',
        loadDbId: null,
        columnOrder: ['column-1', 'column-3', 'column-2'],
        loading: false,
        loadIde: this.props.match.params.ide,
        loadNouns: '',
        open2: false,
        userIdes: [],
        openAddElement: false,
        snackBarOpen: false,
        checker: [],
        itemcounter: 0,
        snippet: '',
        dailyURL: '',
        sourcenote: '',
        editnote: false,
        textarea1: '',
        lanradio: '',
        html: '',
        keywords: ['Keine'],
        othersources: [],
        badges: { "one": true, "two": false, "three": false, "four": false, "five": false, "six": false, "seven": false, "eight": false, "nine": false, "ten": false, "eleven": false, "twelve": false },
        differenceChecker: [],
        suggestions: [],
        fragmary: '',
        maryantwort: [],
    };




    toggle = () => this.setState((currentState) => ({show: !currentState.show}));

    componentDidMount() {

        const { loadNouns } = this.props.location.state;
        const { lan } = this.props.location.state;

        maybeJoinCall();

        this.setState({ loadNouns: loadNouns });
        this.setState({ lanradio: lan });

        if (this.state.loadIde >= 1) { this.loadIdeDb(this.state.loadIde); }
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.context.username); }

        Mixpanel.identify(this.context.username);
        Mixpanel.track('User Visit at Mary');

        if (!this.state.loadIde) this.getNouns(loadNouns, lan);

    }



    handleChange=(e)=>{
        let { value} = e.target;
        value = value.replace(/'/g, `'\\''`)
        this.setState({txt: value});
    }

    handleChangeFM=(e)=>{
        let {value} = e.target;
        this.setState({ fragmary: value });
    }

    toggleLoad=(e)=> {
        this.setState({open2: !this.state.open2});
    }

    toggleElement=(e)=> {
        this.setState({openAddElement: !this.state.openAddElement});
    }

    handleChangeResp=(e)=>{
        let { value } = e.target;
        value = value.replace(/'/g, `'\\''`)
        this.setState({ question: value })

    }

    handleChangeMod=(e)=>{
        let { name, value} = e.target;
        this.setState({ [name]: value });
    }

    checkPercent() {
        let countChecker = this.state.checker.length
        let rest = this.state.checker;
        let source = this.state.question.split(" ");

        let intersection = rest.filter(x => source.includes(x));
        let countIntersec = intersection.length

        let num = countIntersec / countChecker

        let perc = Math.round(num*100);

        if(perc === 0) perc = 1;
        if(perc > 100) perc = 100;
        var result = {...this.state.result}
        result.percent = perc;
        this.setState({result})

    }

    checkPercentKeywords(cleanhtml) {
        let countChecker = this.state.keywords.length
        let rest = this.state.keywords;
        let txt = cleanhtml;

        txt = txt.replace(/&nbsp;/gi, ' ')
        let source = txt.trim().split(/[\s,]+/);

        source = source.map((sour) => sour.replace(/&nbsp;/gi, ' '));
        source = source.map((sour) => sour.trim());

        let intersection = rest.filter(x => source.includes(x[0]));
        let countIntersec = intersection.length
        let num = countIntersec / countChecker
        let perc = Math.round(num*100);

        if(perc === 0) perc = 1;
        if(perc > 100) perc = 100;

        var badges = {...this.state.badges};
        perc >= 10 ? badges.two = true : badges.two = false;
        perc >= 20 ? badges.three = true : badges.three = false;
        perc >= 30 ? badges.four = true : badges.four = false;
        perc >= 40 ? badges.five = true : badges.five = false;
        perc >= 50 ? badges.six = true : badges.six = false;
        perc >= 60 ? badges.seven = true : badges.seven = false;
        perc >= 70 ? badges.eight = true : badges.eight = false;
        perc >= 80 ? badges.nine = true : badges.nine = false;
        perc >= 90 ? badges.ten = true : badges.ten = false;
        perc >= 100 ? badges.eleven = true : badges.eleven = false;
        this.setState({ badges })
    }

    setSearchTerm = debounce((searchTerm, toast) => {
        searchTerm = searchTerm.replace(/&nbsp;/gi, ' ')
        this.setState({ question: searchTerm })
        if(toast) this.isaHelp();
    }, 1000)

    isaHelp() {
        if(this.handleCheck(this.returnLastWord())) this.notify();
    }

    handleCheck(val) {
        return this.state.checker.some(item => val.toLowerCase() === item.toLowerCase());
    }

    notify = () => {
        let keywordItem = '';
        let wort = this.returnLastWord();
        let index = this.state.keywords.findIndex((word) => word[0].toString().toLowerCase() == wort.toLowerCase());
        console.log(wort.toLowerCase())
        if (index >= 0) keywordItem = this.state.keywords[index];

        toast(<Msg
                lastWord={this.returnLastWord()}
                nextWord={this.returnLastWordPlusOne()}
                index={this.state.checker.indexOf(this.returnLastWord())}
                keywordItem={keywordItem}
                />, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar',
            autoClose: 30000,
        });
        console.log("Mary sagt:", this.returnLastWord())

    };

    returnLastWordPlusOne() {
        let index = this.state.checker.indexOf(this.returnLastWord());
        if ((this.state.checker.length > index) && (index >= 1)) {
            let retarr = [this.state.checker[index-1], this.state.checker[index+1]]
            return retarr
        } else {
            return null;
        }
    }

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose(){
        this.setState({ open: false });
    };

    switchIde(val) {
        this.setState({ editor: val });
    }

    /*
    eventLogger = (e: MouseEvent, data: Object) => {
      console.log('Event: ', e);
      console.log('Data: ', data);
    };
    */

    onStart = () => {
        let plus = this.state.activeDrags
        let plusplus = ++plus;
      this.setState({activeDrags: plusplus});
    };

    onStop = () => {
        let minus = this.state.activeDrags
        let minusminus = --minus
      this.setState({activeDrags: minusminus});
    };

    // For controlled component
    adjustXPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const {x, y} = this.state.controlledPosition;
      this.setState({controlledPosition: {x: x - 10, y}});
    };

    adjustYPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const {controlledPosition} = this.state;
      const {x, y} = controlledPosition;
      this.setState({controlledPosition: {x, y: y - 10}});
    };

    onControlledDrag = (e, position) => {
      const {x, y} = position;
      this.setState({controlledPosition: {x, y}});
    };

    onControlledDragStop = (e, position) => {
      this.onControlledDrag(e, position);
      this.onStop();
    };


    countItems(val) {
        const max = this.state.items.length;

        if(val === "up") {
            let num = this.state.itemcounter+1;
            if (num >= max) num = max;
            this.setState({ itemcounter: num });
        } else {
            let num = this.state.itemcounter-1;
            if (num <= 0) num = 0;
            this.setState({ itemcounter: num });
        }
    }

    editComment() {
        this.setState({editnote: true})
    }

    saveComment() {
        this.putNote()
        this.setState({editnote: false})
    }

    handleSNChange = (e) => {
        let { value } = e.target;
        this.setState({sourcenote: value});
    }

    applyHighlightsWord() {
          let text = this.state.checker.join('|')
          const tempRegex = new RegExp(text, "gi");
          const cleanRegex = new RegExp('(<mark([^>]+)>)|(<([^>]+)mark>)|<mark>', "gmi");

          this.sanitize()

          let cleanhtml = this.state.html.replace(cleanRegex, '');
          let html = cleanhtml
              .replace(tempRegex, (aktword) => {
                  let tootltip = '<mark>' + aktword + '</mark>'
                  return tootltip;
              });

        let checkerItems = this.state.checker;
        let res = Array.from(cleanhtml.matchAll(tempRegex));
        let flatRes = res.flat(1);
        let difference = checkerItems.filter((x) => !flatRes.includes(x));
        console.log(difference)
            this.setState({ differenceChecker: difference })

        html = html.replace("&nbsp;", " ")

        if(this.returnLastWord() === '?1') {
            html = html.replace(/(\?1)/gi, difference[0])
            this.onTxtInput(html, 0);
            console.log("drin")
            //this.setState({ html: html })
        }

        if(this.returnLastWord() === '?2') {
            let twoWord = difference[0] + " " + difference[1]
            html = html.replace(/(\?2)/gi, twoWord)
            this.onTxtInput(html, 0);
            console.log("drin")
            //this.setState({ html: html })
        }


         this.setState({ html: html })
      }


    //////

    sanitize = () => {
        this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
    };

    handleECChange = evt => {
        let txtval = evt.target.value;
        this.setState({ html: txtval });
        this.onTxtInput(txtval, true)
    };

    onTxtInput = debounce((searchTerm, toast) => {
        searchTerm = searchTerm.replace(/&nbsp;/gi, ' ')
        this.setState({ question: searchTerm })

        const cleanRegex = new RegExp('(<mark([^>]+)>)|(<([^>]+)mark>)|<mark>', "gmi");
        let cleanhtml = this.state.html.replace(cleanRegex, '');
        cleanhtml = cleanhtml.replace(/&nbsp;/gi, ' ')

        this.setState({ questionOrig: cleanhtml })
        this.checkPercent();
        this.checkPercentKeywords(cleanhtml);
        this.isaHelp();
        this.applyHighlightsWord()

        var suggestions = writeGood(cleanhtml)
        if (suggestions.length <= 0) {
            console.log("schreib-gut")
            suggestions = writeGood(cleanhtml, { checks: schreibGut})
        }
        console.log(suggestions)
        this.setState({ suggestions: suggestions })

    }, 1000)

    returnLastWord() {
        var txt = sanitizeHtml(this.state.question, this.sanitizeConf);
        txt = txt.trim()
        var n = txt.trim().split(/[\s,]+/);
        return n[n.length - 1];
    }


    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

        console.log(this.state)

        return (
            <div>
                <Modal size="lg" show={this.state.show} onHide={() => this.setState({ show: false })} animation={false} style={{ zIndex: '99999' }}>
                    <Modal.Header closeButton>
                        <Modal.Title><Trans i18nKey="bsEditor_duforschst"/>{this.state.loadNouns}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.snippet }} />
                        {this.state.snippet === null && <div><Trans i18nKey="bsEditor_leider" />  <Trans i18nKey="bsEditor_versuch" />: <div style={{ width: "3rem", border: "1px solid #ccc", padding: "0.4rem" }}>
                            <a
                                href={"http://www.google.com/search?q=" + this.state.loadNouns + " " + this.state.items[this.state.itemcounter]}
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SimpleIcons name="Google"/>
                            </a>
                            </div>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ show: false })}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={this.state.show2} onHide={() => this.setState({ show2: false })} animation={false} style={{ zIndex: '99999' }}>
                    <Modal.Header closeButton>
                        <Modal.Title><Trans i18nKey="marysagt" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.snippet }} />
                        {this.state.suggestions.length >= 0 && <div style={{ padding: "0.4rem" }}>

                            {this.state.suggestions.map((item, ind) => {
                                return (
                                <div key={ind}><Trans i18nKey="zeichen" /> {item.index}: {item.reason}
                                </div>
                                )
                            })}

                        </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ show2: false })}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>




                    <UserConsumer>
                        {({username, password, isLoggedIn, loginFailure}) => (<Fragment>
                            {this.state.loading && <div>
                                <Loader/>
                            </div>}

                            <Container>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <h3 className="subtitleide"><Trans i18nKey="bsEditor_duforschst" /> <span className="titlebluepart">{this.state.loadNouns}</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <h1 className="subtitleide"><span className="titlebluepart"><Trans i18nKey="bsEditor_losgehts" /></span> <Trans i18nKey="bsEditor_schreibeauf" /> <span className="titlebluepart">{this.state.loadNouns}</span> <Trans i18nKey="bsEditor_weisst" /></h1>
                                    </Col>


                                    <Col xs={12} md={2} lg={12}>
                                        {this.state.saved && (
                                            <span className="editorfont titlebluepart" style={{ width: "20%", fontSize: "1.3rem" }}><Trans i18nKey="bsEditor_deinwissen" />  <Link to={"/Home"}>Dashboard</Link> <Trans i18nKey="bsEditor_gespeichert" /> </span>
                                        )}
                                    </Col>
                                </Row>

                                {this.state.sourcenote && !this.state.editnote && (<Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div style={{
                                            width: "100%",
                                            borderTop: "7px dotted lightgrey",
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem"
                                        }}>
                                            <Row>
                                                <Col xs={11} >{this.state.sourcenote}</Col>
                                                <Col xs={1} className="text-right align-bottom"><FontAwesomeIcon icon={faPen} onClick={() => this.editComment()}/></Col>
                                            </Row>

                                        </div>

                                    </Col>
                                </Row>)}

                                {this.state.editnote && (<Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div style={{
                                            width: "100%",
                                            borderTop: "7px dotted lightgrey",
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem"
                                        }}>

                                            <Row>

                                                <Col xs={11}>
                                                    <Form.Group>
                                                        <Form.Label><Trans i18nKey="bsEditor_bearbeiten" /> </Form.Label>
                                                        <Form.Control as="textarea" rows="5" id="sourcenote" name="sourcenote"
                                                                      defaultValue={this.state.sourcenote}
                                                                      onBlur={this.handleSNChange}
                                                                      onChange={this.handleSNChange}/>
                                                    </Form.Group>

                                                </Col>
                                                <Col xs={1} className="align-bottom text-right"><FontAwesomeIcon icon={faSave} onClick={() => this.saveComment()}/></Col>
                                            </Row>

                                        </div>
                                    </Col>
                                </Row>)}

      <p><Trans i18nKey="bsEditor_type" />  </p>
                                            <Row>
                                            <Col xs={12} md={9} lg={9}>


                                                    <ContentEditable
                                                        innerRef={this.contentEditable}
                                                        html={this.state.html} // innerHTML of the editable div
                                                        disabled={false}       // use true to disable editing
                                                        onChange={this.handleECChange} // handle innerHTML change
                                                        onBlur={this.sanitize}
                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                        style={{
                                                            marginLeft: "0",
                                                            fontFamily: "inherit",
                                                            height: "40vh",
                                                            border: "7px dotted lightgrey",
                                                            outline: "none",
                                                            margin: 0,
                                                            padding: "2px",
                                                            overflow: "scroll" }}
                                                    />

                                                    <textarea
                                                        className="editable"
                                                        value={this.state.html}
                                                        onChange={this.handleChange}
                                                        onBlur={this.sanitize}
                                                        style={{ display: "none" }}
                                                        overflow="hidden"
                                                    />

                                            </Col>

                                            <Col xs={12} md={3} lg={3}>
                                                <div style={{marginBottom: "1em", textAlign: "right", borderTop: "7px dotted lightgrey" }}>
                                                    <p className="subtitleide" style={{ fontSize: "1.3rem", marginTop: "0px" }}><Trans i18nKey="bsEditor_schluessel" /></p>
                                                    {this.state.keywords.map((item, ind) => {
                                                        return (
                                                            <Button className="btn-sm mr-2 mb-2" key={ind} onClick={() => this.getWPSnippet2(item[0])}
                                                            >{item[0]}
                                                            </Button>
                                                        )
                                                    })}
                                                     <p><Trans i18nKey="bsEditor_keywords" /></p>
                                                </div>
                                            </Col>

                                            </Row>

                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row className="mt-2" style={{ margin: "0px", padding: "1rem" }}>

                                            <Col xs={12} md={6} lg={6} style={{  }}>
                                            <p className="subtitleide" style={{ fontSize: "1.3rem", marginTop: "0px" }}><Trans i18nKey="bsEditor_askmary" /></p>
                                            <textarea
                                                name="fragmary"
                                                value={this.state.fragmary}
                                                onChange={this.handleChangeFM}
                                                overflow="hidden"
                                                style={{ border: "1px solid lightgray", borderRadius: "5px", width: "80%" }}
                                            />
                                            <Button className="btn-sm ml-2 mb-4" onClick={() => this.getWikiWolfram(this.state.fragmary)}
                                            ><FontAwesomeIcon icon={faAngleDoubleRight} style={{ cursor: "pointer", fontSize: "16" }} />
                                            </Button>
                                            <p> <Trans i18nKey="bsEditor_problem" /></p>
                                            </Col>

                                            <Col xs={12} md={6} lg={6} style={{ borderLeft: "7px dotted lightgrey" }} >
                                                {this.state.othersources.length <= 0 && (
                                                    <p className="subtitleide" style={{ fontSize: "1.3rem", marginTop: "0px" }}><Trans i18nKey="bsEditor_linkszu" /></p>
                                                )}
                                                <Button className="btn-sm mr-2 mb-2" onClick={() => this.getPlagiat()}
                                                ><FontAwesomeIcon icon={faMagic} style={{ cursor: "pointer", fontSize: "16" }} />
                                                </Button>
                                                <p> <Trans i18nKey="bsEditor_links"/></p>
                                                {this.state.othersources.length >= 1 &&
                                                (
                                                    <Fragment>
                                                        <Row>
                                                            <Col>
                                                                {this.state.othersources.map((item, ind) => {
                                                                    return (
                                                                        <Row className="mb-3"><Col>
                                                                            <a
                                                                                href={item}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{ border: "1px solid rgb(204, 204, 204)", padding: "0.4rem", margin: "0.4rem", width: "100%" }}
                                                                            >
                                                                                {item}
                                                                            </a></Col></Row>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                )
                                                }
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row className="mt-2" style={{marginBottom: "1em", textAlign: "center", borderTop: "7px dotted lightgrey", borderBottom: "7px dotted lightgrey", padding: "1rem 15px 1rem 15px", margin: "0px" }}>

                                            <Col xs={1} md={1} lg={1} style={{  }}>
                                                <Medal keyid={"one"} placement={"top"} badge={this.state.badges.one} txt={"<strong>Starter</strong><br/>du hast angefangen, super - mach weiter!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"two"} placement={"top"} badge={this.state.badges.two} txt={"<strong>10%</strong><br/>du hast 10% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"three"} placement={"top"} badge={this.state.badges.three} txt={"<strong>20%</strong><br/>du hast 20% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"four"} placement={"top"} badge={this.state.badges.four} txt={"<strong>30%</strong><br/>du hast 30% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"five"} placement={"top"} badge={this.state.badges.five} txt={"<strong>40%</strong><br/>du hast 40% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"six"} placement={"top"} badge={this.state.badges.six} txt={"<strong>50%</strong><br/>du hast 50% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"seven"} placement={"top"} badge={this.state.badges.seven} txt={"<strong>60%</strong><br/>du hast 60% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"eight"} placement={"top"} badge={this.state.badges.eight} txt={"<strong>70%</strong><br/>du hast 70% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"nine"} placement={"top"} badge={this.state.badges.nine} txt={"<strong>80%</strong><br/>du hast 80% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"ten"} placement={"top"} badge={this.state.badges.ten} txt={"<strong>90%</strong><br/>du hast 90% der wichtigen Keywords geschrieben, super - weiter so!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Medal keyid={"eleven"} placement={"top"} badge={this.state.badges.eleven} txt={"<strong>100%</strong><br/>du hast 100% der wichtigen Keywords geschrieben, super Klasse!"}/>
                                            </Col>
                                            <Col xs={1} md={1} lg={1} style={{ borderLeft: "7px dotted lightgrey" }}>
                                                {this.state.suggestions.length <= 0 && (<Medal keyid={"twelve"} placement={"top"} badge={true} txt={"<strong>Guter Satzaufbau!</strong><br/>du hast einen guten Satzaufbau, super Klasse!"}/>)}
                                                {this.state.suggestions.length >= 1 && (

                                                    <FontAwesomeIcon icon={faQuestionCircle} style={{cursor: "pointer"}} onClick={() => this.setState({show2: true})} style={{ fontSize: "36px",
                                                        color: "lightgrey",
                                                    }} />)}


                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div style={{marginBottom: "1em", marginTop: "0.5em", textAlign: "right", paddingBottom: "0.5rem"}}>
                                            {!isLoggedIn && (
                                                <Fragment>
                                                    <h1 className="subtitleide" style={{ fontSize: "1.3rem", borderTop: "7px dotted lightgrey", paddingTop: "10px", marginTop: "20px" }}><Trans i18nKey="bsEditor_umwissen" /></h1>
                                                    <span className="editorfont titlebluepart" style={{ width: "20%", fontSize: "1.3rem" }}> <Trans i18nKey="bsEditor_speichern" /></span>
                                                    <FontAwesomeIcon icon={faSave} style={{cursor: "pointer"}} onClick={() => alert('Um deinen Wissens-Stand zu speichern bitte erst anmelden oder registrieren.')}/>
                                                </Fragment>)}
                                            {isLoggedIn && (
                                                <Fragment>
                                                    <span className="editorfont titlebluepart" style={{ width: "20%", fontSize: "1.3rem" }}> <Trans i18nKey="bsEditor_speichern" /> </span>
                                                    <FontAwesomeIcon icon={faSave} style={{cursor: "pointer"}} onClick={() => this.saveIde(username)}/>
                                                </Fragment>)}
                                            {isLoggedIn && (
                                            <Fragment>
                                            <Draggable
                                             {...dragHandlers}
                                              defaultPosition={{x: 0, y: 0}}
                                              position={null}
                                              grid={[50, 50]}
                                              scale={1}
                                              onStart={this.handleStart}
                                              onDrag={this.handleDrag}
                                              onStop={this.handleStop}>
                                              <div>
                                                  <p className="subtitleide" style={{ fontSize: "1.3rem", paddingTop: "10px", marginTop: "0px" }}></p>

                                                <div id="appendhere">
                                                <Toggle/>
                                                </div>

                                              </div>
                                            </Draggable>


                                            <div id="link">
                                            </div>
                                            <div id="clickable">
                                            </div>
                                            <div id="whatsapp">
                                            </div>
                                            </Fragment>)}
                                            {isLoggedIn && (
                                                <Fragment>
                                                <Draggable
                                                 {...dragHandlers}
                                                  defaultPosition={{x: 0, y: 0}}
                                                  position={null}
                                                  grid={[50, 50]}
                                                  scale={1}
                                                  onStart={this.handleStart}
                                                  onDrag={this.handleDrag}
                                                  onStop={this.handleStop}>
                                                  <div>
                                                      <p className="subtitleide" style={{ fontSize: "1.3rem", paddingTop: "10px", marginTop: "0px" }}></p>
                                                    <ToggleAudio/>
                                                  </div>
                                                </Draggable>
                                            </Fragment>)}

                                        </div>
                                    </Col>
                                </Row>

                            </Container>


                        </Fragment>)}
                    </UserConsumer>

                <ToastContainer/>

            </div>
        );
    }

    /**
     *
     * @method getNouns
     * @memberof LandingPage
     * WIP
     */
    getNouns = async (item, lan) => {

        console.log(item)
        console.log("lan:" + lan)

        this.setState({ loading: true });
        this.setState({ context: item });

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWPspacy.php",
            { item, lan }
        );
        const { ok, data } = response || {};
        if (ok) {
            console.log(data)

            this.setState({ items: data.result.items, back: true });

            this.setState({ checker: data.result.checker });

            this.setState({ keywords: data.result.keywords });

            this.state.items.map((item, index) => {
                const i = index + 1;
                const newState = {
                    ...this.state,
                    tasks: {
                        ...this.state.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                this.setState(newState);
                return true;
            });

            const newItems = [];
            this.state.items.map((item, index) => {
                const i = index + 1
                newItems[index] = 'task-'+i
                return true;
            });

            const newColumns = {
                'column-1': {
                    id: 'column-1',
                    title: 'Mary hat etwas gefunden. Schau mal bitte hier.',
                    taskIds: newItems,
                },
                'column-3': {
                    id: 'column-3',
                    title: 'Wenn Du etwas nicht verstehst ist das gar nicht schlimm. Suche einfach nach den Wörtern. Zieh Dir das Wort, was Dich interessiert hierher.',
                    taskIds: [],
                },
                'column-2': {
                    id: 'column-2',
                    title: 'Schreibe einfach los:',
                    taskIds: [],
                },
            };

            this.setState({ columns: newColumns});
            this.setState({ loading: false });

            //this.myRef.current.scrollIntoView({block: 'start', behavior: 'smooth'})

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user) => {
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getItems.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)
            this.setState({ userIdes: data.result});

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    saveIde = async username => {
        const dashboard = JSON.stringify(this.state.result.differ);
        const research = JSON.stringify(this.state.keywords);
        const items = JSON.stringify(this.state.items);
        const check = JSON.stringify(this.state.checker);
        const context = JSON.stringify(this.state.context);
        const score = this.state.result.percent;
        const cleanRegex = new RegExp('(<mark([^>]+)>)|(<([^>]+)mark>)|<mark>', "gmi");
        const cleanhtml = this.state.html.replace(cleanRegex, '');
        const ide = cleanhtml;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putDashboard.php",
            { dashboard, ide, research, items, check, context, username, score }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ saved: true});
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method loadIde
     * @memberof LandingPage
     * WIP
     */
    loadIdeDb = async loadIde => {
        this.setState({ loading: true });

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getDashboard.php",
            { loadIde }
        );
        const { ok, data } = response || {};
        if (ok) {

            this.setState({ items: data.result[0].items, back: true });

            this.setState({ checker: data.result[0].checkit })

            data.result[0].items.map((item, index) => {
                const i = index + 1;
                const newState = {
                    ...this.state,
                    tasks: {
                        ...this.state.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                this.setState(newState);
                return true;
            });

            const tmpresult = {
                percent: data.result[0].score,
                differ: data.result[0].dashboard
            }

            this.setState({ result: tmpresult });
            this.setState({ question: data.result[0].ide });
            this.setState({ questionOrig: data.result[0].ide });
            this.setSearchTerm(data.result[0].ide, false);
            this.setState({ context: data.result[0].context });
            this.setState({ loading: false });
            this.setState({ open: true});
            this.setState({ loadNouns: data.result[0].context });

            this.setState({ sourcenote: data.result[0].note });

            this.setState({ html: data.result[0].ide });

            if(data.result[0].research.length <= 1) data.result[0].research[0] = ['Keine', '5'];

            this.setState({ keywords: data.result[0].research });

            this.applyHighlightsWord()


        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("loadDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method getCheck
     * @memberof LandingPage
     * WIP
     */
    getCheck = async () => {

        this.setState({ loading: true });

        const question = this.state.question;
        const topic = this.state.txt;
        const research = this.state.research;
        const fallbackelements = this.state.items;

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getAnswer.php",
            { question, topic, research, fallbackelements }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ result: data });
            this.setState({ loading: false });
            this.setState({ open: true });

            let differ = Object.values(data.differ);
            differ.map((item, index) => {
                const i = index + 1;
                const newState = {
                    ...this.state,
                    tasks: {
                        ...this.state.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                this.setState(newState);
                return true;
            });

            const newItems = [];
            differ.map((item, index) => {
                const i = index + 1
                newItems[index] = 'task-'+i
                return true;
            });


            const newState1 = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    'column-2': {
                        id: 'column-2',
                        title: 'Try researching the following keywords:',
                        taskIds: newItems,
                    }
                }
            };
            this.setState(newState1);


        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getWPSnippet = async () => {
        this.setState({ loading: true });
        const topic = this.state.items[this.state.itemcounter];
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWPextract.php",
            { topic }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ snippet: data });
            this.setState({ loading: false });
            this.setState({ show: true });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getWPSnippet2 = async (txt) => {
        this.setState({ loading: true });
        const topic = txt;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWPextract.php",
            { topic }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ snippet: data });
            this.setState({ loading: false });
            this.setState({ show: true });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getPlagiat = async () => {
        this.setState({ loading: true });
        const cleanRegex = new RegExp('(<mark([^>]+)>)|(<([^>]+)mark>)|<mark>', "gmi");
        const ide = this.state.html.replace(cleanRegex, '');
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getPlagiat.php",
            { ide }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ othersources: data.result });
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    getWikiWolfram = async (frage) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getWikiWolfram.php",
            { frage }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ maryantwort: data.result });
            this.setState({ loading: false });
            toast(<MsgMary
                answer={data.result}
            />, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                autoClose: 30000,
            });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    putNote = async () => {
        this.setState({ loading: true });
        const note = this.state.sourcenote;
        const id = this.state.loadIde;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putNote.php",
            { note, id }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ sourcenote: note });
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

}



function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export default BsEditor;
