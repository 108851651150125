/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import ToggleButton from 'react-bootstrap/ToggleButton'
// material-ui core components


import { useTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';


export default function Footer(props) {

  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
   i18n.changeLanguage(lng);
   };

  const { whiteFont } = props;

  return (
    <footer style={{marginTop: "15vh", marginBottom: "5vh"}}>
    <Container>
      <Row>
        <Col lg={true} xs={true} sm={true} md={true} xl={true} style={{marginTop: "1vh", textAlign: "center"}}>
        <a href="/Aboutus" style={{ color: "black", fontWeight: "500", textTransform: "uppercase", paddingRight:"2vh"}}><Trans i18nKey="uberuns" /></a>
        <a href="/Blog" style={{ color: "black", fontWeight: "500", textTransform: "uppercase",  padding:"2vh"}}><Trans i18nKey="blog" /></a>
        <a href="/Jobs" style={{ color: "black", fontWeight: "500", textTransform: "uppercase",  padding:"2vh"}}><Trans i18nKey="jobs" /></a>
        </Col>
        <Col lg={true} xs={true} sm={true} md={true} xl={true} style={{marginTop: "1vh", textAlign: "center"}}>
        <a href="/TOU" style={{ color: "black", fontWeight: "500", textTransform: "uppercase",  padding:"2vh"}}><Trans i18nKey="agb" /></a>
        <a href="/Privacy" style={{ color: "black", fontWeight: "500", textTransform: "uppercase",  padding:"2vh"}}><Trans i18nKey="datenschutz" /></a>
        <a href="/Impressum" style={{ color: "black", fontWeight: "500", textTransform: "uppercase",  padding:"2vh"}}><Trans i18nKey="impressum" /></a>
        </Col>
        <Col lg={true} xs={true} sm={true} md={true} xl={true} style={{marginTop: "1vh", textAlign: "right"}}>
                v.1.5 &copy; {1900 + new Date().getYear()} TensorParc, Inc{" "}
        </Col>
      </Row>
    </Container>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
