import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class ThemeSwitcher extends Component {

    state = { theme: null }

    chooseTheme = (theme, evt) => {
        evt.preventDefault();
        if (theme.toLowerCase() === 'reset') { theme = null }
        this.setState({ theme });
    }

    render() {

        //const { theme } = this.state;
        /*const themeClass = theme ? theme.toLowerCase() : 'default';

        const parentContainerStyles = {
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'table'
        };

        const subContainerStyles = {
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'table-cell',
            verticalAlign: 'middle'
        };*/

        return (
            <Container fluid>
                <Row>
                    <Col>1 of 1</Col>
                </Row>
            </Container>
        );

    }

}

export default ThemeSwitcher;