import React from 'react';
import './style.css';
import Signin from "../../components/Signin/index.js";
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import  "../../i18n.js";

function Impressum() {
    return (
        <div>
            <Container>
                <div/>
                <Container className="mb-5">
                    <Row spacing={3}>
                        <Col className="mb-4">
                            <Card className="cards" style={{boxShadow: "1px 2px 1px 0 gray"}}>
                                <Signin />
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </Container>
        </div>
    );
}

export default Impressum;
