import React from 'react';
import './style.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function Impressum() {
    return (
        <div>
            <Container>
                <Row>

                    <Col>
                        <Card class="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>

                    <p className="datefont">Fassung vom 2. April 2020</p>

                    <h1>TensorParc's Nutzungsbedingungen</h1>
                    <h4>Kurzgesagt:</h4>
                    <p>Wir verwenden diese Grundbegriffe in der gesamten Vereinbarung, und sie haben spezifische Bedeutungen. Sie sollten wissen, was wir meinen, wenn wir die einzelnen Begriffe verwenden.</p>

                    <h4>Definitionen</h4>
                    <b>Nutzerkonto (auch Konto oder Account)</b>
                    <p> Ein Konto repräsentiert Ihr Rechtsverhältnis mit TensorParc. Ein "Benutzerkonto" stellt die Berechtigung eines einzelnen Nutzers dar, sich in den Dienst einzuloggen und diesen zu nutzen und dient als Identität des Nutzers bei TensorParc.</p>

                    <b> Agreement</b>
                    <p> Die Vereinbarung bezieht sich gemeinsam auf alle Bedingungen, Bestimmungen, Hinweise, die in diesem Dokument enthalten sind oder auf die in diesem Dokument verwiesen wird, sowie auf alle anderen Betriebsregeln, Richtlinien (einschließlich der Datenschutzerklärung von TensorParc, verfügbar unter <a href="https://TensorParc.com/privacy">TensorParc.com/privacy</a>)  und Verfahren, die wir von Zeit zu Zeit auf der Website veröffentlichen können.
                    </p>

                    <b> TensorParc, Wir und Uns </b>
                    <p> TensorParc, wir und uns beziehen sich auf TensorParc, Inc. sowie auf unsere verbundenen Unternehmen, Direktoren, Tochtergesellschaften, Auftragnehmer, Lizenzgeber, leitenden Angestellten, Agenten und Mitarbeiter, sofern wir nicht ausdrücklich etwas anderes angeben.
                    </p>

                    <b> Dienstleistungen</b>
                    <p> Service und Dienstleistungen bezieht sich auf die Anwendungen, Software, Produkte und Dienstleistungen, die von TensorParc bereitgestellt werden, einschließlich aller Beta-Vorschauen.
                    </p>

                    <b> Nutzer, Sie, Du </b>
                    <p> Nutzer, Benutzer, sie und du beziehen sich auf die einzelne Person, Firma oder Organisation, die die Website oder den Service besucht hat oder nutzt, die auf einen Teil des Kontos zugreift oder es nutzt oder die die Nutzung des Kontos bei der Ausführung seiner Funktionen lenkt. Ein Benutzer muss mindestens 13 Jahre alt sein. Für Geschäfts- oder Regierungskonten können Sonderbedingungen gelten.  </p>

                    <b> Website</b>
                    <p> Die Website bezieht sich auf die Website von TensorParc, die sich auf <a href="https://TensorParc.com/">TensorParc.com</a> befindet, und auf alle Inhalte, Dienstleistungen und Produkte, die von TensorParc auf oder über die Website bereitgestellt werden. Sie bezieht sich auch auf TensorParc-eigene Subdomains von TensorParc.com, wie z.B. <a href="https://jeff.TensorParc.com/">jeff.TensorParc.com</a>. Gelegentlich können Websites im Besitz von TensorParc andere oder zusätzliche Nutzungsbedingungen anbieten. Wenn diese zusätzlichen Bedingungen im Widerspruch zu dieser Vereinbarung stehen, gelten die spezifischeren Bedingungen für die betreffende Seite oder Dienstleistung.
                    </p>


                    <h4>Konto-Bedingungen </h4>
                    <h4>Kurzgesagt:</h4>
                    <p>
                    Benutzerkonten haben unterschiedliche administrative Kontrollen; ein Mitarbeiter muss Ihr Konto erstellen; Sie müssen mindestens 13 Jahre alt sein; Sie müssen eine gültige E-Mail-Adresse angeben; und Sie dürfen nicht mehr als ein kostenloses Konto haben. Sie allein sind für Ihren Account und alles, was während der Anmeldung oder der Nutzung Ihres Accounts passiert, verantwortlich. Sie sind für die Sicherheit Ihres Kontos verantwortlich.
                    </p>
                    <h4>Konto-Kontrollen</h4>
                    <b>Nutzer</b>
                    <p>Vorbehaltlich dieser Bedingungen behalten Sie die letzte administrative Kontrolle über Ihr Benutzerkonto und die darin enthaltenen Inhalte.
                    </p>

                    <h4>Erforderliche Informationen</h4>
                    <p>Sie müssen eine gültige E-Mail-Adresse angeben, um den Anmeldevorgang abzuschließen. Alle anderen angeforderten Informationen, wie z.B. Ihr richtiger Name, sind optional, es sei denn, Sie akzeptieren diese Bedingungen im Namen einer juristischen Person (in diesem Fall benötigen wir weitere Informationen über die juristische Person).
                    </p>

                    <h4>Anforderungen an das Konto</h4>

                    <p>Sie müssen ein Mensch sein, um ein Konto zu erstellen. Von Bots registrierte Konten oder andere automatisierte Methoden sind nicht zulässig.</p>

                    <p>Eine natürliche oder juristische Person darf nicht mehr als ein kostenloses Konto unterhalten.
                    </p>
                    <p>Sie müssen mindestens 13 Jahre alt sein. Während wir uns freuen, dass brillante junge Lernende vom Lernen begeistert sind, müssen wir uns an die Gesetze der Vereinigten Staaten halten. TensorParc richtet sich nicht an Kinder unter 13 Jahren, und wir erlauben keine Benutzer unter 13 Jahren in unserem Service. Wenn wir von einem Nutzer unter 13 Jahren erfahren, werden wir das Konto dieses Nutzers sofort löschen. Wenn Sie in einem Land außerhalb der Vereinigten Staaten wohnhaft sind, kann das Mindestalter in Ihrem Land höher sein; in diesem Fall sind Sie für die Einhaltung der Gesetze Ihres Landes verantwortlich.
                    </p>
                    <p>Ihr Login darf nur von einer Person verwendet werden, d.h. ein einzelnes Login darf nicht von mehreren Personen geteilt werden.
                    </p>
                    <p>Sie dürfen TensorParc nicht unter Verletzung der Exportkontroll- oder Sanktionsgesetze der Vereinigten Staaten oder einer anderen anwendbaren Gerichtsbarkeit verwenden. Sie dürfen TensorParc nicht verwenden, wenn Sie im Auftrag eines <a href="https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx">Specially Designated National (SDN)</a> oder einer Person arbeiten oder arbeiten, die ähnlichen Sperr- oder Verweigerungsverboten unterliegen, die von einer US-Regierungsbehörde verwaltet werden.
                    </p>


                    <h4>Sicherheit des Benutzerkontos</h4>
                    <p>Sie sind dafür verantwortlich, Ihr Konto sicher zu halten, während Sie unseren Service nutzen. Wir bieten Tools wie die Zwei-Faktor-Authentifizierung an, die Ihnen helfen, die Sicherheit Ihres Kontos aufrechtzuerhalten, aber der Inhalt Ihres Kontos und seine Sicherheit sind Ihnen überlassen.
                    </p>

                    <p>Sie sind verantwortlich für alle Inhalte und Aktivitäten, die unter Ihrem Konto veröffentlicht werden.
                    </p>

                    <p>Sie sind für die Sicherheit Ihres Kontos und Ihres Passworts verantwortlich. TensorParc kann und wird nicht für Verluste oder Schäden aus der Nichteinhaltung dieser Sicherheitsverpflichtung durch Sie haftbar gemacht werden.</p>

                    <p>Sie werden TensorParc unverzüglich unter support@tensorparc.com benachrichtigen, wenn Sie von einer unbefugten Nutzung unseres Dienstes oder dem Zugang zu unserem Dienst über Ihr Konto Kenntnis erhalten, einschließlich einer unbefugten Nutzung Ihres Passworts oder Ihres Kontos.</p>


                    <h4>Kollaborative Projekte</h4>
                    <h4>Kurzgesagt:</h4>
                    <p>
                    TensorParc beherbergt eine Vielzahl von Gemeinschaftsprojekten aus der ganzen Welt, und diese Zusammenarbeit funktioniert nur, wenn unsere Nutzer in der Lage sind, in gutem Glauben zusammenzuarbeiten. Bei der Nutzung des Dienstes müssen Sie die Bedingungen dieses Abschnitts befolgen, die einige Einschränkungen bezüglich der Inhalte, die Sie veröffentlichen können, des Verhaltens beim Dienst und andere Einschränkungen enthalten. Kurzum, seien Sie ausgezeichnet zueinander.</p>

                    <p>Ihre Nutzung der Website und des Dienstes darf nicht gegen geltende Gesetze, einschließlich Urheberrechts- oder Markengesetze, Exportkontroll- oder Sanktionsgesetze oder andere Gesetze in Ihrer Gerichtsbarkeit verstoßen. Sie sind dafür verantwortlich, sicherzustellen, dass Ihre Nutzung des Dienstes in Übereinstimmung mit den Gesetzen und den geltenden Vorschriften erfolgt.</p>

                    <p>Sie stimmen zu, dass Sie unter keinen Umständen gegen diese Bedingungen und <a href="https://jeff.TensorParc.com/privacy">Datenschutzrichtlinien </a>verstoßen werden.
                    </p>

                    <h4>Benutzergenerierter Inhalt</h4>
                    <h4>Kurzgesagt:</h4>
                    <p>Sie sind Eigentümer der von Ihnen erstellten Inhalte, aber Sie gewähren uns bestimmte Rechte daran, so dass wir die von Ihnen eingestellten Inhalte anzeigen und weitergeben können. Sie haben immer noch die Kontrolle über Ihre Inhalte und die Verantwortung dafür, und die Rechte, die Sie uns einräumen, sind auf diejenigen beschränkt, die wir für die Bereitstellung des Dienstes benötigen. Wir haben das Recht, Inhalte zu entfernen oder Konten zu schließen, wenn wir dies benötigen.</p>

                    <h4>Verantwortung für nutzergenerierte Inhalte</h4>
                    <p>Sie können während der Nutzung des Dienstes nutzergenerierte Inhalte erstellen oder hochladen. Sie sind allein verantwortlich für den Inhalt und für alle Schäden, die aus nutzergenerierten Inhalten entstehen, die Sie veröffentlichen, hochladen, verlinken oder anderweitig über den Dienst zur Verfügung stellen, unabhängig von der Form dieser Inhalte. Wir sind nicht verantwortlich für die öffentliche Anzeige oder den Missbrauch Ihrer nutzergenerierten Inhalte.</p>

                    <h4>TensorParc kann Inhalte entfernen</h4>
                    <p>Wir überprüfen nutzergenerierte Inhalte nicht vorab, aber wir haben das Recht (aber nicht die Pflicht), nutzergenerierte Inhalte, die nach unserem alleinigen Ermessen gegen die Bedingungen oder Richtlinien von TensorParc verstoßen, abzulehnen oder zu entfernen.
                    </p>

                    <h4>Eigentum am Inhalt, Recht zur Veröffentlichung und Lizenzgewährung</h4>
                    <p>Sie bleiben Eigentümer und verantwortlich für Ihre Inhalte. Wenn Sie etwas veröffentlichen, das Sie nicht selbst erstellt haben oder an dem Sie nicht die Rechte besitzen, erklären Sie sich damit einverstanden, dass Sie für alle von Ihnen veröffentlichten Inhalte verantwortlich sind; dass Sie nur Inhalte übermitteln, zu deren Veröffentlichung Sie berechtigt sind; und dass Sie alle Lizenzen Dritter in Bezug auf die von Ihnen veröffentlichten Inhalte vollständig einhalten.
                    </p>

                    <p>Da Sie das Eigentum und die Verantwortung für Ihre Inhalte behalten, müssen Sie uns - und anderen TensorParc-Benutzern - bestimmte gesetzliche Genehmigungen erteilen. Diese Lizenzerteilungen gelten für Ihre Inhalte. Wenn Sie Inhalte hochladen, die bereits mit einer Lizenz ausgestattet sind, die TensorParc die für den Betrieb unseres Dienstes erforderlichen Berechtigungen gewährt, ist keine zusätzliche Lizenz erforderlich. Sie verstehen, dass Sie für keines der gewährten Rechte eine Zahlung erhalten. Die Lizenzen, die Sie uns erteilen, enden, wenn Sie Ihre Inhalte von unseren Servern entfernen, es sei denn, andere Benutzer haben sie genutzt.</p>

                    <h4>Lizenzerteilung an uns</h4>
                    <p>Wir brauchen das gesetzliche Recht, Dinge wie das Hosten Ihrer Inhalte, sie zu veröffentlichen und zu teilen. Sie räumen uns und unseren Rechtsnachfolgern das Recht ein, Ihre Inhalte zu speichern, zu analysieren und anzuzeigen sowie beiläufige Kopien anzufertigen, wenn dies für die Bereitstellung der Website und des Dienstes erforderlich ist. Dies schließt das Recht ein, Dinge wie das Kopieren in unsere Datenbank und das Erstellen von Sicherungskopien zu tun; es Ihnen und anderen Nutzern zu zeigen; es in einen Suchindex zu parsen oder es auf unseren Servern anderweitig zu analysieren; es mit anderen Nutzern zu teilen; und es auszuführen, falls Ihr Inhalt so etwas wie Musik, ein Gif, Bilder oder Video oder andere Inhalte ist.</p>
                    <p>Diese Lizenz gewährt TensorParc nicht das Recht, Ihre Inhalte zu verkaufen oder sie anderweitig zu verbreiten oder außerhalb unserer Bereitstellung des Dienstes zu nutzen.</p>

                    <h4>Lizenzgewährung an andere Benutzer</h4>
                    <p>Alle von Ihnen öffentlich veröffentlichten nutzergenerierten Inhalte, einschließlich Themen, Kommentare und Beiträge zu Projekten anderer Nutzer, können von anderen eingesehen werden. Indem Sie Ihre Projekte für die öffentliche Anzeige einstellen, erklären Sie sich damit einverstanden, anderen zu erlauben, Ihre Projekte zu sehen und zu "verwenden" (dies bedeutet, dass andere ihre eigenen Kopien von Inhalten aus Ihren Projekten in von ihnen kontrollierten Projekten erstellen können).</p>

                    <p>Wenn Sie Ihre Seiten und Projekte so einstellen, dass sie öffentlich betrachtet werden, gewähren Sie jedem Nutzer von TensorParc eine nicht-exklusive, weltweite Lizenz, Ihre Inhalte durch den TensorParc-Service zu nutzen, anzuzeigen und auszuführen und Ihre Inhalte ausschließlich auf TensorParc zu reproduzieren, wie durch die Funktionalität von TensorParc erlaubt (zum Beispiel durch die Nutzung des Projekts eines anderen Nutzers). Wenn Sie Inhalte hochladen, die Sie nicht erstellt haben oder besitzen, sind Sie dafür verantwortlich, sicherzustellen, dass der von Ihnen hochgeladene Inhalt unter Bedingungen lizenziert ist, die diese Berechtigungen anderen TensorParc-Benutzern gewähren.
                    </p>

                    <h4>Beiträge unter Projektlizenz</h4>
                    <p>Immer wenn Sie einen Beitrag zu einem Projekt leisten, das eine Lizenzankündigung enthält, lizenzieren Sie Ihren Beitrag unter den gleichen Bedingungen, und Sie stimmen zu, dass Sie das Recht haben, Ihren Beitrag unter diesen Bedingungen zu lizenzieren. Wenn Sie eine separate Vereinbarung zur Lizenzierung Ihrer Beiträge unter anderen Bedingungen haben, wie z.B. eine Lizenzvereinbarung für Beitragende, so tritt diese an die Stelle dieser Vereinbarung.
                    </p>

                    <h4>Moralische Rechte</h4>
                    <p>Sie behalten alle moralischen Rechte an Ihren Inhalten, die Sie hochladen, veröffentlichen oder an irgendeinen Teil des Dienstes übermitteln, einschließlich der Rechte auf Integrität und Zuschreibung. Sie verzichten jedoch auf diese Rechte und verpflichten sich, sie nicht gegen uns geltend zu machen, damit wir die eingeräumten Rechte in angemessener Weise ausüben können, jedoch nicht anderweitig.</p>
                    <p>In dem Umfang, in dem diese Vereinbarung nicht durch das anwendbare Recht durchsetzbar ist, gewähren Sie TensorParc die Rechte, die wir benötigen, um Ihre Inhalte ohne Zuschreibung zu nutzen und um angemessene Anpassungen Ihrer Inhalte vorzunehmen, die notwendig sind, um die Website zu erstellen und den Service bereitzustellen.
                    </p>

                    <h4>Zugang</h4>
                    <p>Die Mitarbeiter von TensorParc dürfen nur in den folgenden Situationen auf die Inhalte Ihrer privaten Projekte zugreifen:
                    </p>
                    <ul>
                    <li>Mit Ihrem Einverständnis und Wissen, aus Gründen der Unterstützung. Wenn TensorParc aus Supportgründen auf ein privates Projekt zugreift, tun wir dies nur mit der Zustimmung und dem Wissen des Eigentümers.</li>
                    <li>Wenn der Zugang aus Sicherheitsgründen erforderlich ist, einschließlich wenn der Zugang erforderlich ist, um die laufende Vertraulichkeit, Integrität, Verfügbarkeit und Belastbarkeit der Systeme und des Service von TensorParc aufrechtzuerhalten.</li>
                    </ul>
                    <p>Sie können sich dafür entscheiden, zusätzlichen Zugang zu Ihren privaten Projekten zu ermöglichen. Zum Beispiel:</p>
                    <ul>
                    <p>Sie können verschiedene Dienste oder Funktionen von TensorParc aktivieren, die zusätzliche Rechte an Ihren Inhalten in privaten Projekten erfordern. Diese Rechte können je nach Dienstleistung oder Funktion variieren, aber TensorParc wird Ihren privaten Projektinhalt weiterhin vertraulich behandeln. Wenn diese Dienste oder Features Rechte zusätzlich zu denen, die wir für die Bereitstellung des TensorParc-Dienstes benötigen, erfordern, werden wir eine Erklärung dieser Rechte geben.</p></ul>

                    <h4>Ausschlüsse</h4>
                    <p>Wenn wir Grund zu der Annahme haben, dass der Inhalt eines privaten Projekts gegen das Gesetz oder diese Bedingungen verstößt, haben wir das Recht, darauf zuzugreifen, sie zu überprüfen und zu entfernen. Darüber hinaus können wir durch das Gesetz gezwungen sein, den Inhalt Ihrer privaten Projekte offenzulegen.</p>

                    <h4>Urheberrechtsverletzung und DMCA-Politik</h4>
                    <p>Wenn Sie der Meinung sind, dass die Inhalte unserer Website Ihr Urheberrecht verletzen, nehmen Sie bitte Kontakt mit uns auf. Wenn Sie ein Urheberrechtsinhaber sind und glauben, dass die Inhalte auf TensorParc Ihre Rechte verletzen, kontaktieren Sie uns bitte per E-Mail an copyright@TensorParc.com. Es kann rechtliche Konsequenzen haben, wenn eine falsche oder leichtfertige Anzeige gesendet wird. Bevor Sie eine Anfrage zur Abnahme senden, müssen Sie legale Verwendungen wie Fair Use und lizenzierte Verwendungen in Betracht ziehen.</p>

                    <p>Wir werden die Konten von Wiederholungstätern löschen.</p>

                    <h4>Hinweis auf geistiges Eigentum</h4>
                    <p><strong>Kurzgesagt:</strong> We own the service and all of our content. In order for you to use our content, we give you certain rights to it, but you may only use our content in the way we have allowed.
                    </p>
                    <h4>Die Rechte von TensorParc an den Inhalten</h4>
                    <p>TensorParc und unsere Lizenzgeber, Verkäufer, Agenten und/oder unsere Inhaltsanbieter behalten das Eigentum an allen geistigen Eigentumsrechten jeglicher Art in Bezug auf die Website und den Service. Wir behalten uns alle Rechte vor, die Ihnen nicht ausdrücklich im Rahmen dieser Vereinbarung oder durch das Gesetz gewährt werden. Das Erscheinungsbild der Website und des Service ist urheberrechtlich geschützt © TensorParc, Inc. Alle Rechte vorbehalten. Sie dürfen keinen Teil des Codes, der Bilder, des Textes oder der visuellen Designelemente oder Konzepte ohne ausdrückliche schriftliche Genehmigung von TensorParc vervielfältigen, kopieren oder wiederverwenden.
                    </p>
                    <h4>TensorParc Logos</h4>
                    <p>Wenn Sie die Logos von TensorParc verwenden möchten, müssen Sie um eine schriftliche Genehmigung bitten.</p>

                    <h4>Annullierung und Beendigung</h4>
                    <p><strong>Kurzgesagt:</strong> Sie können Ihr Konto jederzeit schließen. Wenn Sie dies tun, werden wir mit Ihren Informationen verantwortungsbewusst umgehen.</p>
                    <h4>Löschung von Konten</h4>
                    <p>Es liegt in Ihrer Verantwortung, Ihr Konto bei TensorParc ordnungsgemäß zu kündigen. Sie können Ihr Konto jederzeit kündigen, indem Sie Ihre Einstellungen in der globalen Navigationsleiste oben auf dem Bildschirm aufrufen. Der Konto-Bildschirm bietet einen einfachen Link zur Stornierung, der keine Fragen stellt. Wir sind nicht in der Lage, Konten als Antwort auf eine E-Mail oder telefonische Anfrage zu kündigen.</p>

                    <h4>Bei Löschung</h4>
                    <p>Wir werden Ihre Informationen aufbewahren und verwenden, soweit dies zur Erfüllung unserer rechtlichen Verpflichtungen, zur Beilegung von Streitigkeiten und zur Durchsetzung unserer Vereinbarungen erforderlich ist, aber mit Ausnahme der rechtlichen Anforderungen werden wir Ihr vollständiges Profil und den Inhalt Ihrer Projekte innerhalb von 90 Tagen nach der Kündigung oder Beendigung löschen (obwohl einige Informationen in verschlüsselten Backups verbleiben können). Diese Informationen können nach der Löschung Ihres Kontos nicht mehr wiederhergestellt werden.</p>
                    <p>Wir werden keine Inhalte löschen, die Sie für andere Benutzer oder Projekte beigetragen haben oder die andere Benutzer verwendet haben.
                    </p>
                    <p>Auf Anfrage werden wir uns in angemessener Weise bemühen, einem Kontoinhaber eine Kopie Ihres rechtmäßigen, nicht verletzenden Kontoinhalts nach der Kontolöschung, -kündigung oder -herabstufung zur Verfügung zu stellen. Sie müssen diesen Antrag innerhalb von 90 Tagen nach der Stornierung, Kündigung oder Herabstufung stellen.</p>
                    <h4>TensorParc kann jederzeit löschen oder Nutzern den Zugang verweigern</h4>
                    <p>TensorParc hat das Recht, Ihren Zugriff auf die gesamte oder einen Teil der Website jederzeit, mit oder ohne Grund, mit oder ohne Benachrichtigung, mit oder ohne sofortige Wirkung zu verweigern, auszusetzen oder zu beenden. TensorParc behält sich das Recht vor, den Service für jedermann aus beliebigen Gründen jederzeit zu verweigern.
                    </p>
                    <h4>Bestehen</h4>
                    <p>Alle Bestimmungen dieser Vereinbarung, die aufgrund ihrer Art die Kündigung überdauern sollten, bleiben bestehen; dazu gehören unter anderem: Eigentumsbestimmungen, Gewährleistungsausschlüsse, Haftungsausschlüsse, Entschädigungen und Haftungsbeschränkungen.</p>

                    <h4>Kommunikation mit TensorParc</h4>
                    <p><strong>Kurzgesagt:</strong> We use email and other electronic means to stay in touch with our users.</p>
                    <h4>Erforderliche elektronische Kommunikation</h4>
                    <p>Für vertragliche Zwecke stimmen Sie (1) dem Erhalt von Mitteilungen von uns in elektronischer Form über die von Ihnen angegebene E-Mail-Adresse oder über den Dienst zu; und (2) stimmen Sie zu, dass alle Nutzungsbedingungen, Vereinbarungen, Mitteilungen, Offenlegungen und andere Mitteilungen, die wir Ihnen elektronisch zur Verfügung stellen, alle rechtlichen Anforderungen erfüllen, die diese Mitteilungen erfüllen würden, wenn sie auf Papier vorliegen würden. Dieser Abschnitt hat keinen Einfluss auf Ihre unveräußerlichen Rechte.</p>
                    <h4>Rechtlicher Hinweis an TensorParc muss schriftlich erfolgen</h4>
                    <p>Mitteilungen, die per E-Mail oder über das Nachrichtensystem des TensorParc Supports gemacht werden, stellen in keiner Situation, in der eine Mitteilung an TensorParc durch einen Vertrag oder ein Gesetz oder eine Verordnung erforderlich ist, eine rechtliche Mitteilung an TensorParc oder seine leitenden Angestellten, Mitarbeiter, Agenten oder Vertreter dar. Rechtliche Mitteilungen an TensorParc müssen schriftlich erfolgen und dem gesetzlichen Vertreter von TensorParc zugestellt werden.</p>
                    <h4>Kein Telefon-Support</h4>
                    <p>TensorParc bietet nur Unterstützung per E-Mail, In-Service-Kommunikation und elektronische Nachrichten an. Wir bieten keinen telefonischen Support an.</p>
                    <h4>Gewährleistungsausschlusss</h4>
                    <p><strong>Kurzgesagt:</strong> Wir bieten unseren Dienst so an, wie er ist, und wir machen keine Versprechungen oder Garantien für diesen Dienst. Bitte lesen Sie diesen Abschnitt sorgfältig durch; Sie sollten verstehen, was Sie erwartet.
                    </p>
                    <p>TensorParc stellt die Website und den Service so zur Verfügung, wie er ist; und wie verfügbar ohne jegliche Garantie. Ohne dies einzuschränken, lehnen wir ausdrücklich alle Gewährleistungen, ob ausdrücklich, stillschweigend oder gesetzlich, bezüglich der Website und des Dienstes ab, einschließlich und ohne Einschränkung jeder Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck, des Titels, der Sicherheit, der Genauigkeit und der Nichtverletzung von Rechten Dritter.</p>
                    <p>TensorParc garantiert nicht, dass der Service Ihren Anforderungen entspricht; dass der Service ununterbrochen, zeitgerecht, sicher oder fehlerfrei ist; dass die durch den Service bereitgestellten Informationen genau, zuverlässig oder korrekt sind; dass jegliche Mängel oder Fehler korrigiert werden; dass der Service zu einer bestimmten Zeit oder an einem bestimmten Ort verfügbar ist; oder dass der Service frei von Viren oder anderen schädlichen Komponenten ist. Sie übernehmen die volle Verantwortung und das Verlustrisiko, das sich aus dem Herunterladen und/oder der Nutzung von Dateien, Informationen, Inhalten oder anderen Materialien, die Sie von dem Dienst erhalten haben, ergibt.</p>

                    <h4>Beschränkung der Haftung</h4>
                    <p><strong>Kurzgesagt:</strong>  We will not be liable for damages or losses arising from your use or inability to use the service or otherwise arising under this agreement. Please read this section carefully; it limits our obligations to you.</p>
                    <p>Sie verstehen und erklären sich damit einverstanden, dass wir Ihnen oder Dritten gegenüber nicht haftbar sind für den Verlust von Gewinn, Nutzung, Goodwill oder Daten oder für zufällige, indirekte, besondere, Folge- oder exemplarische Schäden, wie auch immer diese entstehen, die sich ergeben aus</p>
                    <ul>
                    <li>die Verwendung, Offenlegung oder Anzeige Ihrer nutzergenerierten Inhalte;</li>
                    <li>Ihre Nutzung oder Unfähigkeit, den Dienst zu nutzen;</li>
                    <li>jede Änderung, Preisänderung, Aussetzung oder Einstellung des Dienstes;</li>
                    <li>den Dienst im Allgemeinen oder die Software oder Systeme, die den Dienst zur Verfügung stellen;</li>
                    <li>unbefugter Zugriff auf oder Änderungen Ihrer Übertragungen oder Daten;</li>
                    <li>Erklärungen oder Verhalten von Dritten im Dienst;</li>
                    <li>alle anderen Benutzerinteraktionen, die Sie durch Ihre Nutzung des Dienstes eingeben oder erhalten; oder</li>
                    <li>jede andere Angelegenheit im Zusammenhang mit dem Dienst.</li>
                    </ul>
                    <p>Unsere Haftung ist begrenzt, unabhängig davon, ob wir über die Möglichkeit solcher Schäden informiert wurden oder nicht, und selbst dann, wenn sich herausstellt, dass ein in diesem Abkommen festgelegter Rechtsbehelf seinen wesentlichen Zweck verfehlt hat. Wir übernehmen keine Haftung für Ausfälle oder Verzögerungen aufgrund von Angelegenheiten, die sich unserer angemessenen Kontrolle entziehen.
                    </p>

                    <h4>Freistellung und Schadloshaltung</h4>
                    <p><strong>Kurzgesagt:</strong> You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else, we will not be involved.
                    </p>
                    <p>Wenn Sie eine Streitigkeit mit einem oder mehreren Nutzern haben, stimmen Sie zu, TensorParc von allen Ansprüchen, Forderungen und Schäden (tatsächliche und Folgeschäden) jeder Art und Weise, bekannt und unbekannt, die aus solchen Streitigkeiten entstehen oder in irgendeiner Weise damit verbunden sind, freizustellen.
                    </p>
                    <p>Sie erklären sich damit einverstanden, uns zu entschädigen, zu verteidigen und schadlos zu halten von und gegen alle Ansprüche, Verbindlichkeiten und Ausgaben, einschließlich Anwaltskosten, die aus Ihrer Nutzung der Website und des Service entstehen, einschließlich, aber nicht beschränkt auf Ihre Verletzung dieser Vereinbarung, vorausgesetzt, dass TensorParc (1) Sie unverzüglich schriftlich über den Anspruch, die Forderung, die Klage oder das Verfahren informiert; (2) gibt Ihnen die alleinige Kontrolle über die Verteidigung und die Beilegung der Forderung, des Antrags, der Klage oder des Verfahrens (vorausgesetzt, dass Sie keinen Anspruch, keine Forderung, keinen Prozess oder kein Verfahren beilegen dürfen, es sei denn, die Beilegung befreit TensorParc bedingungslos von jeglicher Haftung); und (3) bietet Ihnen auf Ihre Kosten jede angemessene Unterstützung.</p>

                    <h4>Änderungen dieser Bedingungen</h4>
                    <p><strong>Kurzgesagt:</strong> Wir möchten, dass unsere Benutzer über wichtige Änderungen unserer Bedingungen informiert werden, aber einige Änderungen sind nicht so wichtig; wir möchten Sie nicht jedes Mal stören, wenn wir einen Tippfehler korrigieren. Obwohl wir diese Vereinbarung jederzeit ändern können, werden wir die Benutzer über alle Änderungen, die Ihre Rechte betreffen, informieren und Ihnen Zeit geben, sich darauf einzustellen.</p>
                    <p>Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit nach eigenem Ermessen zu ändern und werden diese Nutzungsbedingungen im Falle solcher Änderungen aktualisieren. Wir werden unsere Nutzer mindestens 30 Tage vor Inkrafttreten der Änderung über wesentliche Änderungen dieser Vereinbarung, wie z.B. Preisänderungen, durch eine Mitteilung auf unserer Website informieren. Für nicht-materielle Änderungen stellt Ihre fortgesetzte Nutzung der Website die Zustimmung zu unseren Überarbeitungen dieser Nutzungsbedingungen dar. Sie können alle Änderungen an diesen Bedingungen einsehen.
                    </p>

                    <p>Wir behalten uns das Recht vor, die Website (oder Teile davon) jederzeit und von Zeit zu Zeit mit oder ohne Vorankündigung zu ändern oder vorübergehend oder dauerhaft einzustellen.</p>

                    <h4>Sonstiges</h4>
                    <h4>Geltendes Recht</h4>
                    <p>Soweit das anwendbare Recht nichts anderes vorsieht, unterliegen diese Vereinbarung zwischen Ihnen und TensorParc und jeder Zugriff auf oder die Nutzung der Website oder des Dienstes den Bundesgesetzen der Vereinigten Staaten von Amerika.</p>

                    <h4>Nichtübertragbarkeit</h4>
                    <p>TensorParc kann diese Nutzungsbedingungen und/oder die TensorParc Datenschutzerklärung, ganz oder teilweise, jederzeit mit oder ohne Ihr Einverständnis an jede Person oder Einheit abtreten oder delegieren, einschließlich der Lizenzerteilung in Abschnitt D.4. Sie dürfen ohne unsere vorherige schriftliche Zustimmung keine Rechte oder Verpflichtungen gemäß den Nutzungsbedingungen oder der Datenschutzerklärung abtreten oder delegieren, und jede unbefugte Abtretung und Delegation durch Sie ist ungültig..</p>

                    <h4>Trennbarkeit, kein Verzicht und Überleben</h4>
                    <p>Sollte ein Teil dieser Vereinbarung für ungültig oder nicht durchsetzbar erklärt werden, wird dieser Teil der Vereinbarung so ausgelegt, dass er die ursprüngliche Absicht der Parteien widerspiegelt. Die übrigen Teile bleiben in vollem Umfang in Kraft und Wirkung. Jedes Versäumnis von TensorParc, eine Bestimmung dieser Vereinbarung durchzusetzen, gilt nicht als Verzicht auf unser Recht, diese Bestimmung durchzusetzen. Unsere Rechte aus dieser Vereinbarung überdauern jede Beendigung dieser Vereinbarung.</p>

                    <h4>Änderungen; vollständige Einigung</h4>
                    <p>Diese Vereinbarung kann nur durch eine schriftliche Ergänzung, die von einem autorisierten Vertreter von TensorParc unterzeichnet ist, oder durch die Veröffentlichung einer überarbeiteten Version durch TensorParc geändert werden. Diese Nutzungsbedingungen stellen zusammen mit der Datenschutzerklärung von TensorParc die vollständige und ausschließliche Erklärung der Vereinbarung zwischen Ihnen und uns dar. Diese Vereinbarung ersetzt jeden Vorschlag oder jede vorherige mündliche oder schriftliche Vereinbarung sowie jede andere Kommunikation zwischen Ihnen und TensorParc, die sich auf den Gegenstand dieser Bedingungen bezieht, einschließlich aller Vertraulichkeits- oder Geheimhaltungsvereinbarungen.</p>

                    <h4>Fragen</h4>
                    <p>Haben Sie Fragen zu den Nutzungsbedingungen? Kontaktieren Sie uns unter support@tensorparc.com</p>
                             </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default Impressum;
