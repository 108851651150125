import React from "react";
import { Button } from 'react-bootstrap';
import SpeechRecognition from "../../components/Speech/index.js";
import { Trans } from "react-i18next";
import  "../../i18n.js";

const ToggleAudio = () => {
  const [show, toggleShow] = React.useState(false);

  return (
    <div>
      <Button onClick={() => toggleShow(!show)}><Trans i18nKey="toggle_audio" /> {show ? 'Beenden' : 'Starten'}</Button>
      {show && <div><p style={{marginTop: "1em"}}><Trans i18nKey="toggle_dukannst" /> </p><SpeechRecognition />
        </div>}
    </div>
  )
}

export default ToggleAudio;
