import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import { faMinus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Container, Row, Col, Carousel, Form} from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

class AddFollow extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        username: '',
        add: false,
        fail: false,
    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
    }

    componentDidMount() {
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.context.username); }
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
    }

    render() {
        const { t } = this.props;
        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        return (
            <UserConsumer>
                {({ username, password, isLoggedIn, loginFailure }) => (<Fragment>
                    {this.state.loading && <div>
                            <Loader />
                        </div>}
                    <div>
                        <Container>
                        <Row>
                            <Col>
                                <h3 className="subtitleide"><Trans i18nKey="follow_bestimme" /></h3>
                            </Col>
                        </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}><Trans i18nKey="follow_neue" /></div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        padding: "1rem",
                                    }}>
                                        <Form.Group>
                                        <Form.Control
                                            variant="outlined"
                                            placeholder= {t('follow_email')}
                                            className="mb-3"
                                            required
                                            id="username"
                                            label="Email Addresse"
                                            name="username"
                                            autoFocus
                                            onChange={this.handleChange}
                                            style= {{borderRadius:"5px", backgroundVolor: "yellow"}}
                                        />
                                        <Button
                                            type="button"
                                            className="mb-2"
                                            variant="primary"
                                            onClick={() => this.addUserFollows(username)}
                                        >
                                            <Trans i18nKey="follow_hinzu" />
                                        </Button>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            {this.state.add && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="follow_habeemail" />
                                </Col>
                            </Row>)}

                            {this.state.fail && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                  <Trans i18nKey="follow_konntenicht" />
                                </Col>
                            </Row>)}

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}><Trans i18nKey="follow_du" /></div>
                                </Col>
                            </Row>




                            {this.state.userFollows.map((item, index) => {
                            return (<Row key={index}>

                                <Col xs={3}>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                        minHeight: "155px"
                                    }}><div className="pb-5">{item.userid}</div>

                                        <Button
                                            type="button"
                                            variant="outline-light"
                                            size="sm"
                                            style={{ border: "1px solid #fff" }}
                                            onClick={() => this.delUserFollows(item.userid, username)}
                                        ><FontAwesomeIcon icon={faMinus} />
                                        </Button> <Trans i18nKey="follow_nichtweiter" />

                                    </div>
                                </Col>

                                <Col xs={3}>

                                <Carousel xs={10} indicators={false}>

                                    {item.topics.map((itm, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <Link to={{
                                                        pathname: "/load/"+itm.id,
                                                        state: {
                                                            loadNouns: itm.context
                                                        }
                                                    }}
                                                          style={{ color: "white", textDecoration: "none" }}>

                                                        <div key={index} style={{
                                                            backgroundColor: "#5cb85c",
                                                            color: "rgb(255, 255, 255)",
                                                            margin: "0.5rem",
                                                            padding: "1rem",
                                                            borderColor: "rgb(217, 217, 217)",
                                                            borderRadius: "10px",
                                                            minHeight: "155px"
                                                        }}>

                                                            <div style={{ fontSize: "90%" }}  top="xs">
                                                                {itm.context}
                                                            </div>

                                                            <div style={{ margin: "0 auto", textAlign: "center", alignSelf: "right", width:"8rem", padding: "1rem"}} middle="xs">
                                                                <CircularProgressbar
                                                                    value={itm.score}
                                                                    text={`${itm.score}%`}
                                                                    styles={buildStyles({
                                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                        strokeLinecap: 'butt',

                                                                        // Colors
                                                                        pathColor: '#a9a9a9',
                                                                        textColor: '#fff',
                                                                        maxHeight: "6rem",
                                                                    })}
                                                                />
                                                            </div>

                                                            <div style={{ fontSize: "90%" }} bottom="xs">
                                                                <Trans i18nKey="follow_alter" /> <Moment locale="de" tz="Europe/Berlin" fromNow ago>{itm.crdate}</Moment>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </Col>
                                            </Carousel.Item>)
                                    })}

                                </Carousel>
                                </Col>
                            </Row>)
                            })}

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getItems.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userIdes: data.result});


                const onlyContexts = data.result.reduce((obj, {context, id}) => {
                    const dateArr = obj[context] || [];
                    dateArr.push(context);
                    return {...obj, [context]: dateArr};
                }, {});

                const onlyContextsA = Object.values(onlyContexts);

                this.setState({onlyContexts: onlyContextsA});
                console.log(onlyContextsA)

                this.setState({loading: false});
                this.setState({open2: true});

                this.loadUserFollows(user);

            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadUserFollows = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getFollows.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userFollows: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    addUserFollows = async (user) => {
        this.setState({ loading: true });
        const mail = this.state.username;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putFollows.php",
            { user, mail }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === '500') {
                this.setState({fail: true});
                this.setState({add: false});
                this.setState({loading: false});
            } else {
                this.setState({add: true});
                this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserFollows(user);
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method delUserFollows
     * WIP
     */
    delUserFollows = async (mail, user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/delFollows.php",
            { user, mail }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserFollows(user);
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

}

const NewComponent6 = withTranslation()(AddFollow)


export default function App() {
  return (

      <NewComponent6 />

  );
}
