import React, {Component} from 'react';
import { Col, Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {Link} from "react-router-dom";
import "../../index.css";
import {UserConsumer} from "../../userContext";
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

class BsNavbar extends Component {

    render() {

        return (
            <UserConsumer>
                {({username, password, isLoggedIn, loginFailure, logout}) => (<Navbar collapseOnSelect expand="lg" bg="black" variant="light">
                <Container>
                <Col>
                    <Link to={"/"}>
                    <img src="https://i.imgur.com/6dN5sBu.png?1" width="80vh" height="auto" alt="Logo"/>
                    </Link>
                </Col>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="justify-content-end" style={{ width: "100%" }}>
                        {!isLoggedIn && <Nav.Link href="/Signin"><Button><Trans i18nKey="nav_anmelden" /></Button></Nav.Link>}
                        {!isLoggedIn && <Nav.Link href="/Signup"><Button><Trans i18nKey="nav_konto" /></Button></Nav.Link>}
                        {isLoggedIn && <Nav.Link href="/Home"><Button><Trans i18nKey="nav_dashboard" /> {username}</Button></Nav.Link>}
                        {isLoggedIn && <Nav.Link href="/Account"><Button>Konto</Button></Nav.Link>}
                        {isLoggedIn && <Nav.Link href="#"><Button onClick={() => logout()}><Trans i18nKey="nav_abmelden" /></Button></Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>)}
            </UserConsumer>
        );

    }

}

const NewComponent140 = withTranslation()(BsNavbar)


export default function App() {
  return (

      <NewComponent140 />

  );
}
