import { Link } from 'react-router-dom';
import React, {Fragment, PureComponent} from "react";
import UserRegisterFail from "../../UserRegisterFail";
import { UserConsumer } from "../../userContext";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

class SignUp extends PureComponent {
    state = {
        username: '',
        password: '',
        checkbox: false
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
        console.log("handle", this.state)
    }

    handleCheckChange=(e)=>{
        this.setState({ checkbox: !this.state.checkbox })
        console.log("check", this.state)
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }


    render() {
    const { t } = this.props;
        return (
            <UserConsumer>
                {({ register, isregister }) => (
            <Container>
                    <img src="https://i.imgur.com/QOBcLX6.png" width="64px" height="auto" alt="login" />
                    {!isregister && (<Fragment>
                    <UserRegisterFail/>
                        <Container className="mb-4">
                        <h4 style={{fontWeight:"bold", marginBottom: "1em"}}>
                        <Trans i18nKey="signup_registrieren" />
                        </h4>
                        <Row>
                        <Col sm="5">
                            <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                              <Form.Control
                              variant="outlined"
                              placeholder= {t('signin_email')}
                              className="mb-3"
                              required
                              id="username"
                              label="Email Address"
                              name="username"
                              autoComplete="email"
                              autoFocus
                              onChange={this.handleChange}
                              style= {{borderRadius:"5px"}}
                                />
                            <Form.Control
                            variant="outlined"
                            placeholder={t('signin_passwort')}
                            className="mb-4"
                            required
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                            style= {{borderRadius:"5px"}}
                            />

                            <Form.Group>
                            <Form.Control
                                className="mb-4"
                                required
                                name="agb"
                                checked={this.state.checkbox}
                                type="checkbox"
                                id="agb"
                                onChange={this.handleCheckChange}
                                style= {{borderRadius:"0px", boxShadow: "none"}}
                            />
                            <Form.Label sm={10} style={{color:"#bbb"}}><Trans i18nKey="signup_wenndu" /></Form.Label>
                            </Form.Group>

                        <Button
                            type="submit"
                            variant="primary"
                            onClick={event => {
                                register(this.state.username, this.state.password, this.state.checkbox );
                            }}
                        >
                            <Trans i18nKey="signup_registrieren" />
                        </Button>


                              <h5>
                                <Link to="/Signin" style={{color:"#000"}}>
                                    <Trans i18nKey="signup_bereits" />
                                </Link>
                              </h5>


                    </Form.Group>
                            </Form>
                        </Col>
                        </Row>
                  </Container>
</Fragment>
                   )}
                    {isregister && (
                        <Fragment> <Trans i18nKey="signup_erfolgreich" /></Fragment>
                    )}
            </Container>
                )}
            </UserConsumer>
        );
    }
}

const NewComponent2 = withTranslation()(SignUp)


export default function App() {
  return (

      <NewComponent2 />

  );
}
