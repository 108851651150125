import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import { faPlus, faMinus, faTools} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Button, Container, Row, Col, Carousel, Form } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import BsEditor from "../BsEditor_Schueler";
import SubscribeForm from "../../components/SubscribeForm";


class Account extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,

    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
      }


    componentDidMount() {
        //if (this.context.isLoggedIn) { this.loadUserSavedItems(this.props.history.location.state.studentname, this.props.match.params.ide); }
    }

    render() {

        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        console.log("####")
        console.log(this.props)
        console.log(this.context)
        console.log(this.state)

        return (
            <UserConsumer>
                {({ username, usertype, usersub, userid, updateUsersub }) => (<Fragment>
                    {this.state.loading && <div>
                            <Loader />
                        </div>}
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h3 className="subtitleide">Konto: {username}</h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}>Abo:</div>
                                </Col>
                            </Row>

                            {this.context.usertype=='teacher' && (<Fragment>
                                <Row>
                                    {usersub && (<Col>

                                        <h3 className="subtitleide">Dein Abo ist gültig.</h3>

                                        <Button onClick={() => this.unsubscribeMary(usersub, userid)}>Abo beenden</Button>


                                    </Col>)}

                                    {!usersub && (<Col>

                                        <SubscribeForm/>

                                    </Col>)}
                                </Row>
                            </Fragment>)}

                            {this.context.usertype=='student' && (<Fragment>
                                <Row>
                                    Du hast ein Abo über deine Lehrerin, deinen Lehrer oder deine Schule erhalten.
                                </Row>
                            </Fragment>)}

                        </Container>
                    </div>
                    <Container>
                    <Row className="pt-5">
                                    <Col>
                                        <div style={{
                                            backgroundColor: "rgba(63, 64, 63, 0.87)",
                                            color: "rgb(255, 255, 255)",
                                            margin: "0.5rem",
                                            padding: "1rem",
                                            borderColor: "rgb(217, 217, 217)",
                                            borderRadius: "10px",
                                        }}>
                                        <Trans i18nKey="home_deinenaccount" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="pb-5">
                                    <Col className="text-center">
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="btn-primary"
                                            color="primary"
                                            onClick={event => {
                                                delete(this.deleteUser(this.context.username, this.redirectToTarget));
                                            }}
                                        >
                                            <Trans i18nKey="home_meinenaccount" />
                                        </Button>
                                        <div style={{fontSize: "12px", textAlign: "center", color: "grey"}}>
                                          <Trans i18nKey="home_endgueltig" />
                                        </div>
                                    </Col>
                                </Row>
                                </Container>
                </Fragment>)}
            </UserConsumer>
        );
    }


    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteUser = async (id) => {

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/deleteUser.php",
            { id }
        );
        const {ok} = response || {};
        if (ok) {
            this.setState(this.redirectToTarget);
            }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };



    unsubscribeMary = async (subId, userid) => {
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/stripeUnsubscribe.php",
            { subId, userid }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                //localStorage.setItem("usersub", "");
                this.context.updateUsersub('');
                this.setState({loading: false});
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

}

export default Account;
