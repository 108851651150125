import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import './index.css';
import * as serviceWorker from './serviceWorker';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import Footer from "./components/Footer/Footer.js";
import Root from "./containers/Root";
import BsNavbar from "./components/BsNavbar/index.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { UserProvider } from './userContext';

// Create a browser history
const history = createBrowserHistory();

ReactDOM.render(
<I18nextProvider i18n={i18n}>
    <Router history={history}>
    <MixpanelProvider mixpanel={mixpanel}>


    <UserProvider>

<BsNavbar />
        <Root />
<Footer/>

        </UserProvider>

    </MixpanelProvider>
    </Router>
      </I18nextProvider>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
