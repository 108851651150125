import React, { PureComponent, Fragment, Component } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import { faPlus, faMinus, faTools} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Button, Container, Row, Col, Carousel, Form } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import Joyride, { Actions, Events, CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import { BrowserView, MobileView, isBrowser, isMobile, isSafari, browserName, CustomView} from "react-device-detect";
class Home extends PureComponent {

    static contextType = UserContext;

      
    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        userTeams: [],
        studentProjects: [],
        run: false,
        steps: [
                {
                  content: <h4>Klicke auf Next, damit Mary dir zeigt was du hier machen kannst.</h4>,
                  title: <h4><b>Willkommen bei Mary</b></h4>,
                  floaterProps: {
                    disableAnimation: true,
                    hideBackButton: true,
                  },
                  locale: { skip: <strong aria-label="skip">Ne, doch nicht</strong>, close: 'Alles klar', last: 'Schliessen'  },
                  hideBackButton: true,
                  target: 'button',
                  disableOverlay: true,
                },
                {
                    content: <h4>Lege zuerst deine Themen an. Wenn du hier drauf klickst, kommst du auf eine Seite, auf der du deine Themen anlegen kannst.</h4>,
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 20,
                    locale: { skip: <strong aria-label="skip">Ne, doch nicht</strong>, close: 'Alles klar', last: 'Schliessen'  },
                    target: '.themen-anlegen', 
                    hideBackButton: true,
                    disableOverlay: true,
                  },
                {
                content: <h4>Lege danach deine Klasse an. Wenn du hier drauf klickst, kommst du auf eine Seite, auf der du deine Klasse anlegen kannst.</h4>,
                floaterProps: {
                  disableAnimation: true,
                },
                spotlightPadding: 20,
                locale: { skip: <strong aria-label="skip">Ne, doch nicht</strong>, close: 'Alles klar', last: 'Schliessen'  },
                target: '.star-burst', 
                hideBackButton: true,
                disableOverlay: true,
                },
            ]
        
    }


    componentDidMount() {
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.context.username); }

    }

    getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
      };
    
    handleClickStart = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
    
        this.setState({
          run: true
        });
      };

      handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    
        if (finishedStatuses.includes(status)) {
          this.setState({ run: false });
        }
    
        // tslint:disable:no-console
        console.groupCollapsed(type);
        console.log(data);
        console.groupEnd();
        // tslint:enable:no-console
      };

    render() {
        const { run, stepIndex, steps } = this.state;
        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }
     
        console.log(this.context)
        console.log(this.state)

        return (
            <UserConsumer>
                {({ username, usertype }) => (<Fragment>
                    {this.state.loading && <div>
                            <Loader />
                        </div>}
                    <div>
                    {this.state.run &&
                    <Joyride
                        callback={this.handleJoyrideCallback}
                        getHelpers={this.getHelpers}
                        showSkipButton={true}
                        styles={{
                            options: {
                            zIndex: 10000,
                            },
                        }}
                        />
                    }
                        <Container>
                        {this.state.run &&
                        <Joyride
                        steps={steps}
                        />}
                        <Row>
                            <Col xs={12} md={9} lg={9}>
                                <h3 className="subtitleide"><Trans i18nKey="home_hallo" /> {username}<Trans i18nKey="home_dasist" /> </h3>
                            </Col>
                                <CustomView condition={browserName === "Chrome"}>
                            <Col xs={12} md={3} lg={3}><button onClick={this.handleClickStart}>Was kann ich hier machen?</button></Col>
                            </CustomView>
                        </Row>
                            {this.context.usertype=='teacher' && (<Fragment>
                                <Row>
                                    <Col>
                                        <div style={{
                                            backgroundColor: "rgba(63, 64, 63, 0.87)",
                                            color: "rgb(255, 255, 255)",
                                            margin: "0.5rem",
                                            padding: "1rem",
                                            borderColor: "rgb(217, 217, 217)",
                                            borderRadius: "10px",
                                        }}>Deine Klassen / Lerngruppen</div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        <div style={{
                                            backgroundColor: "#f79d79",
                                            color: "rgb(255, 255, 255)",
                                            margin: "0.5rem",
                                            padding: "1rem",
                                            borderColor: "rgb(217, 217, 217)",
                                            borderRadius: "10px",
                                        }}>{this.state.run && <div className="star-burst"></div>}

                                            <Link to={{
                                                pathname: "/AddTeam",
                                                state: {
                                                }
                                            }}><Button
                                                type="button"
                                                variant="outline-light"
                                                size="sm"
                                                style={{ border: "1px solid #fff" }}
                                            ><FontAwesomeIcon icon={faPlus} />
                                            </Button></Link> Neue Klasse / Lerngruppe anlegen
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            {this.state.userTeams.map((item, index) => {
                                                return (
                                                    <Col xs={12} md={6} lg={4} key={index}>
                                                        <div style={{
                                                            backgroundColor: "#f79d79",
                                                            color: "rgb(255, 255, 255)",
                                                            margin: "0.5rem",
                                                            padding: "1rem",
                                                            borderColor: "rgb(217, 217, 217)",
                                                            borderRadius: "10px",
                                                        }}>

                                                            <Row>
                                                                <Col>
                                                                    <Link to={{
                                                                        pathname: "/team/"+item.id,
                                                                        state: {
                                                                            teamid: item.id,
                                                                            teamname: item.name,
                                                                        }
                                                                    }}
                                                                          style={{ color: "white", textDecoration: "none" }}>
                                                                        {item.name}
                                                                    </Link>
                                                                </Col>
                                                            </Row>


                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>)}


                        <Row>
                            <Col>
                                <div style={{
                                    backgroundColor: "rgba(63, 64, 63, 0.87)",
                                    color: "rgb(255, 255, 255)",
                                    margin: "0.5rem",
                                    padding: "1rem",
                                    borderColor: "rgb(217, 217, 217)",
                                    borderRadius: "10px",
                                }}><Trans i18nKey="home_deine" /></div>
                            </Col>
                        </Row>

                            {this.context.usertype=='teacher' && (<Fragment>
                        <Row>
                                {this.state.onlyContexts.map((ide, ind) => {
                                    return (<Fragment key={ind}>
                                            {this.state.userIdes.map((items, index) => {
                                                if (items.context === ide[0]) return (<Col xs={12} md={6} lg={4} key={index}>
                                                        <div style={{
                                                            backgroundColor: "#5cb85c",
                                                            color: "rgb(255, 255, 255)",
                                                            margin: "0.5rem",
                                                            padding: "1rem",
                                                            borderColor: "rgb(217, 217, 217)",
                                                            borderRadius: "10px",
                                                        }}>

                                                            <Row>
                                                                <Col>
                                                                    <Link to={{
                                                                        pathname: "/source/"+items.id,
                                                                        state: {
                                                                            loadNouns: items.context
                                                                        }
                                                                    }}
                                                                          style={{ color: "white", textDecoration: "none", fontWeight: "bold" }}>
                                                                    {ide[0]}
                                                                    </Link>
                                                                    </Col>
                                                                <Col xs={3}>
                                                                <Trans i18nKey="home_projektloeschen" />
                                                                    <Button
                                                                        type="button"
                                                                        variant="outline-light"
                                                                        size="sm"
                                                                        style={{ border: "1px solid #fff" }}
                                                                        onClick={() => this.deleteProject(items.id, username)}
                                                                    ><FontAwesomeIcon icon={faMinus} />
                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                            <Link to={{
                                                                pathname: "/source/"+items.id,
                                                                state: {
                                                                    loadNouns: items.context
                                                                }
                                                            }}
                                                                  style={{ color: "white", textDecoration: "none" }}>

                                                            <div style={{ fontSize: "90%" }}>
                                                                <img alt="complex" src="https://i.imgur.com/VudgSQK.png" width="20vh" height="auto" />   <Trans i18nKey="home_age" /> <Moment locale="de" tz="Europe/Berlin" fromNow ago>{items.crdate}</Moment>
                                                            </div>

                                                            </Link>

                                                        </div>
                                                </Col>)
                                                return true;
                                            })}
                                    </Fragment>)
                                })}
                        </Row>
                </Fragment>)}
                
                        <Row>
                            {this.state.studentProjects.map((project, ind) => {
                                return (
                                    <Col xs={12} md={6} lg={4} key={ind}>
                                        <div style={{
                                            backgroundColor: "#5cb85c",
                                            color: "rgb(255, 255, 255)",
                                            margin: "0.5rem",
                                            padding: "1rem",
                                            borderColor: "rgb(217, 217, 217)",
                                            borderRadius: "10px",
                                        }}>

                                            <Row>
                                                <Col>
                                                    <Link to={{
                                                        pathname: project.wip != null ? "/loadSt/"+project.wip : "/loadSt/"+project.dashboard,
                                                        state: {
                                                            loadNouns: project.context
                                                        }
                                                    }}
                                                          style={{ color: "white", textDecoration: "none" }}>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12}>{project.name}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12}><strong>{project.context}</strong></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12} className="text-right">{project.wip != null && <FontAwesomeIcon icon={faTools} />}</Col>
                                                        </Row>
                                                    </Link>
                                                </Col>
                                            </Row>


                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>


                            {this.context.usertype=='teacher' && (<Fragment>


                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <div style={{
                                        backgroundColor: "#5cb85c",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}>{this.state.run && <div className="themen-anlegen"></div>}

                                        <Link to={{
                                            pathname: "/AddSource",
                                            state: {
                                            }
                                        }}><Button
                                            type="button"
                                            variant="outline-light"
                                            size="sm"
                                            style={{ border: "1px solid #fff" }}
                                        ><FontAwesomeIcon icon={faPlus} />
                                        </Button></Link> <Trans i18nKey="home_neuequelle" />

                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            </Fragment>)}

                

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getItems.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userIdes: data.result});


                const onlyContexts = data.result.reduce((obj, {context, id}) => {
                    const dateArr = obj[context] || [];
                    dateArr.push(context);
                    return {...obj, [context]: dateArr};
                }, {});

                const onlyContextsA = Object.values(onlyContexts);

                this.setState({onlyContexts: onlyContextsA});
                console.log(onlyContextsA)

                this.setState({loading: false});
                this.setState({open2: true});

                this.loadUserFollows(user);


            }

            if (this.context.usertype=='student') { this.loadStudentProjects(this.context.userid, this.context.username); }

            if(this.context.usertype=='teacher') this.loadUserTeams(this.context.userid);

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });

            if(this.context.usertype=='teacher') this.loadUserTeams(this.context.userid);
        }
    };

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadUserFollows = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getFollows.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userFollows: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadUserTeams = async (userid) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getTeams.php",
            { userid }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no team found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userTeams: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadStudentProjects = async (userid, username) => {
        this.setState({ loading: true });
        console.log("load student")
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getStudentProjects.php",
            { userid, username }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no team found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({studentProjects: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method delUserFollows
     * WIP
     */
    delUserFollows = async (mail, user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/delFollows.php",
            { user, mail }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserFollows(user);
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteUser = async (id) => {

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/deleteUser.php",
            { id }
        );
        const {ok} = response || {};
        if (ok) {
            this.setState(this.redirectToTarget);
            }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteProject = async (id) => {
        const user = this.context.username;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/deleteProject.php",
            { id, user }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserSavedItems(this.context.username)
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };


    hideProject = async (id, hideit) => {
        const user = this.context.username;
        const hideityn = hideit === 0 ? "1" : "0";
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/hideProject.php",
            { id, user, hideityn }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserSavedItems(this.context.username)
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

}



const NewComponent20 = withTranslation()(Home)


export default function App() {
  return (

      <NewComponent20 />

  );
}
