import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import 'moment-timezone';
import 'moment/locale/de';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import {Button, Container, Row, Col, Form} from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import Student from "../Student";


class EditSource extends PureComponent {
    static contextType = UserContext;

    constructor(props) {
        super(props);
    }

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        username: '',
        sourcetitel: '',
        sourcenote: '',
        add: false,
        fail: false,
        loadNouns: this.props.match.params.ide,
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        research: '',
        question: '',
        result: {
            percent: 0,
            differ: []
        },
        context: '',
        txt: '',
        checker: [],
        itemcounter: 0,
        saved: false,
        textarea1: '',
        keywords: [],
    }

    componentDidMount() {

        if (this.state.loadNouns >= 1) { this.loadSource(this.props.match.params.ide); }
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
        console.log(this.state)
    }

    handleSNChange=(e)=>{
        let { value} = e.target;
        this.setState({sourcenote: value});
        console.log(this.state)
    }

    render() {



        console.log(this.props)
        console.log(this.state)

        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        return (
            <UserConsumer>
                {({ username, password, isLoggedIn, loginFailure }) => (<Fragment>
                    {this.state.loading && <div>
                        <Loader />
                    </div>}
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h3 className="subtitleide"><Trans i18nKey="source_lege" /></h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}><Trans i18nKey="source_bitte" /></div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        padding: "1rem",
                                    }}>
                                        <Form.Group>
                                            <Form.Control
                                                variant="outlined"
                                                className="mb-3"
                                                required
                                                id="sourcetitel"
                                                label="Titel"
                                                name="sourcetitel"
                                                autoFocus
                                                value={this.state.dashboard.context}
                                                style= {{borderRadius:"5px"}}
                                            />
                                            <Form.Control
                                                as="textarea" rows="3"
                                                variant="outlined"
                                                className="mb-3"
                                                required
                                                id="sourcenote"
                                                label="Kommentar"
                                                name="sourcenote"
                                                autoFocus
                                                value={this.state.dashboard.note}
                                                style= {{borderRadius:"5px"}}
                                            />
                                            <Form.Group>
                                                <Form.Control as="textarea" rows="10" id="textarea1" name="textarea1"
                                                              value={this.state.dashboard.source}
                                                              onBlur={this.handleChange}/>
                                            </Form.Group>
                                            {/*<Button
                                                type="button"
                                                className="mb-2"
                                                variant="primary"
                                                onClick={() => this.addUserSource(this.state.username, this.state.textarea1)}
                                            >
                                                <Trans i18nKey="source_hinzufuegen" />
                                            </Button>*/}
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            {this.state.add && (<Fragment><Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_ichhab" /> <Trans i18nKey="source_woerter" /> <Button
                                    onClick={() => this.saveIde(username)}><Trans i18nKey="source_speichern" /></Button>
                                </Col>
                            </Row>
                            </Fragment>)}

                            {this.state.fail && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_ups" />
                                </Col>
                            </Row>)}

                            {this.state.saved && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_quelle" /> <Link to={"/Home"}><Trans i18nKey="source_schreibtisch" /></Link> <Trans i18nKey="source_gespeichert" />.
                                </Col>
                            </Row>)}

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadSource = async (loadIde) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/loadSource.php",
            { loadIde }
        );
        const { ok, data } = response || {};
        if (ok) {

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({dashboard: data.result[0]});

                this.setState({loading: false});

            }

        }  else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    saveIde = async username => {
        const dashboard = '';
        const research = JSON.stringify(this.state.keywords);
        const items = JSON.stringify(this.state.items);
        const check = JSON.stringify(this.state.checker);
        const context = JSON.stringify(this.state.context);
        const score = 0;
        const ide = '';
        const note = this.state.sourcenote;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putDashboard.php",
            { dashboard, ide, research, items, check, context, username, score, note }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ saved: true});
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };


}

export default EditSource;
