import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import { faPlus, faMinus, faTools} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Button, Container, Row, Col, Carousel, Form } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import BsEditor from "../BsEditor_Schueler";


class Student extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        userTeams: [],
        studentProjects: [],
        loadIde: this.props.match.params.ide,

    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
      }

    componentDidMount() {
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.props.history.location.state.studentname, this.props.match.params.ide); }

        //if (this.context.usertype=='student') { this.loadStudentProjects(this.context.userid, this.context.username); }
    }

    render() {



        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        console.log("####")
        console.log(this.props)
        console.log(this.context)
        console.log(this.state)

        return (
            <UserConsumer>
                {({ username, usertype }) => (<Fragment>
                    {this.state.loading && <div>
                            <Loader />
                        </div>}
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h3 className="subtitleide">{this.props.history.location.state.studentname}</h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}>Bearbeitet:</div>
                                </Col>
                            </Row>

                            {this.context.usertype=='teacher' && (<Fragment>
                                <Row>
                                    {this.state.onlyContexts.map((ide, ind) => {
                                        return (<Fragment key={ind}>
                                            {this.state.userIdes.map((items, index) => {
                                                if (items.context === ide[0]) return (<Col xs={12} md={6} lg={4} key={index}>
                                                    <div style={{
                                                        backgroundColor: "#5cb85c",
                                                        color: "rgb(255, 255, 255)",
                                                        margin: "0.5rem",
                                                        padding: "1rem",
                                                        borderColor: "rgb(217, 217, 217)",
                                                        borderRadius: "10px",
                                                    }}>

                                                        <Row>
                                                            <Col>
                                                                <Link to={{
                                                                    pathname: "/Feedback/"+items.id,
                                                                    state: {
                                                                        studentid: this.props.history.location.state.studentid,
                                                                        studentname: this.props.history.location.state.studentname,
                                                                    }
                                                                }}
                                                                      style={{ color: "white", textDecoration: "none" }}>
                                                                    {ide[0]}
                                                                </Link>
                                                            </Col>
                                                        </Row>

                                                        <Link to={{
                                                            pathname: "/Feedback/"+items.id,
                                                            state: {
                                                                studentid: this.props.history.location.state.studentid,
                                                                studentname: this.props.history.location.state.studentname,
                                                            }
                                                        }}
                                                              style={{ color: "white", textDecoration: "none" }}>

                                                            <div style={{ margin: "0 auto", textAlign: "center", alignSelf: "right", width:"11rem", padding: "1rem"}}>
                                                                <CircularProgressbar
                                                                    value={items.score}
                                                                    text={`${items.score}%`}
                                                                    styles={buildStyles({
                                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                        strokeLinecap: 'butt',

                                                                        // Colors
                                                                        pathColor: '#a9a9a9',
                                                                        textColor: '#fff',
                                                                        maxHeight: "6rem",
                                                                    })}
                                                                />
                                                            </div>

                                                        </Link>

                                                        <Link to={{
                                                            pathname: "/Feedback/"+items.id,
                                                            state: {
                                                                studentid: this.props.history.location.state.studentid,
                                                                studentname: this.props.history.location.state.studentname,
                                                            }
                                                        }}
                                                              style={{ color: "white", textDecoration: "none" }}>

                                                            <div style={{ fontSize: "90%" }}>
                                                                <img alt="complex" src="https://i.imgur.com/VudgSQK.png" width="20vh" height="auto" />   <Trans i18nKey="home_age" /> <Moment locale="de" tz="Europe/Berlin" fromNow ago>{items.crdate}</Moment>
                                                            </div>

                                                        </Link>

                                                    </div>
                                                </Col>)
                                                return true;
                                            })}
                                        </Fragment>)
                                    })}
                                </Row>
                            </Fragment>)}

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user, userid) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getItems.php",
            { user, userid }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userIdes: data.result});


                const onlyContexts = data.result.reduce((obj, {context, id}) => {
                    const dateArr = obj[context] || [];
                    dateArr.push(context);
                    return {...obj, [context]: dateArr};
                }, {});

                const onlyContextsA = Object.values(onlyContexts);

                this.setState({onlyContexts: onlyContextsA});
                console.log(onlyContextsA)

                this.setState({loading: false});
                this.setState({open2: true});

                this.loadUserFollows(user);

            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadUserFollows = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getFollows.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userFollows: data.result});

                this.setState({loading: false});
            }

            if(this.context.usertype=='teacher') this.loadUserTeams(this.context.userid);

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadUserTeams = async (userid) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getTeams.php",
            { userid }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no team found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userTeams: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    loadStudentProjects = async (userid, username) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getStudentProjects.php",
            { userid, username }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no team found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({studentProjects: data.result});

                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };


    /**
     *
     * @method delUserFollows
     * WIP
     */
    delUserFollows = async (mail, user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/delFollows.php",
            { user, mail }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserFollows(user);
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteUser = async (id) => {

        const response = await api.post(
            "https://apitp.tensorparc.com/mary/deleteUser.php",
            { id }
        );
        const {ok} = response || {};
        if (ok) {
            this.setState(this.redirectToTarget);
            }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteProject = async (id) => {
        const user = this.context.username;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/deleteProject.php",
            { id, user }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserSavedItems(this.context.username)
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };


    hideProject = async (id, hideit) => {
        const user = this.context.username;
        const hideityn = hideit === 0 ? "1" : "0";
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/hideProject.php",
            { id, user, hideityn }
        );
        const {ok, data} = response || {};
        if (ok) {

            if(data.result.status === '500') {
                //this.setState({fail: true});
                //this.setState({add: false});
                this.setState({loading: false});
            } else {
                //this.setState({add: true});
                //this.setState({fail: false});
                this.setState({loading: false});
                this.loadUserSavedItems(this.context.username)
            }

        }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };

}

export default Student;
