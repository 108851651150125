import { Link } from 'react-router-dom';
import React, { PureComponent, Fragment } from "react";
import UserContext, { UserConsumer } from '../../userContext';
import UserLoginFail from "../../UserLoginFail";
import { Redirect } from 'react-router'
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';

import '../../containers/Signin/style.css'
import { withTranslation, Trans } from "react-i18next";
import "../../i18n.js";


class Signin extends PureComponent {
    static contextType = UserContext;
    state = {
        username: '',
        password: '',
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
    }


    render() {
    const { t } = this.props;
        if (this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Home`,
                        state: {
                        }
                    }}
                />
            );
        }

  return (
      <UserConsumer>
          {({ login, isLoggedIn, logout }) => (
    <Container>
        <img src="https://i.imgur.com/zacEDg0.png" width="120px" height="auto" alt="login"/>
          {!isLoggedIn && (<Fragment>
        <UserLoginFail/>
        <Container className="mb-4">
        <h4 style={{fontWeight:"bold", marginBottom: "1em"}}>
        <Trans i18nKey="signin_anmelden" />
      </h4>
        <Row>
        <Col sm="5" >
        <Form.Group>
          <Form.Control
            variant="outlined"
            placeholder= {t('signin_email')}
            className="mb-3"
            required
            id="username"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
            style= {{borderRadius:"5px", backgroundVolor: "yellow"}}
          />
          <Form.Control
            variant="outlined"
            placeholder= {t('signin_passwort')}
            className="mb-4"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handleChange}
            style= {{borderRadius:"5px"}}
          />
          <Button
            type="button"
            className="mb-2"
            variant="primary"
            onClick={event => {
                login(this.state.username, this.state.password);
            }}
          >
            <Trans i18nKey="signin_anmelden" />
          </Button>
          <Row>
            <Col>
            <h5>
              <Link to="/Signup" style={{color:"#000"}}>
                <Trans i18nKey="signin_duhast" />
              </Link>
            </h5>
              <h5 style={{color: "#bbb"}}><Trans i18nKey="signin_wenndu" /> </h5>
            </Col>
           </Row>
      </Form.Group>
      </Col>
      </Row>
      </Container>
      </Fragment>
      )}
        {isLoggedIn && (
            <Button type="button"
                    color="primary"
                    onClick={event => { logout(); }}>
                <Trans i18nKey="signin_abmelden" />
            </Button>)}

    </Container>
          )}
      </UserConsumer>
  )}
}

const NewComponent = withTranslation()(Signin)


export default function App() {
  return (

      <NewComponent />

  );
}
