import React from 'react';
import './style.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";

function Blog() {
    return (
        <div>
                <Container>
                    <Row>
                        <Col>
                        <Card class="cards" style={{boxShadow: "1px 2px 1px 0 gray", padding: "2em"}}>
                                <h1 className="title"><Trans i18nKey="impressum_lerne" /> </h1>
                                <p> <b> <Trans i18nKey="impressum_impressum" /> </b> </p>
                                <p> <Trans i18nKey="impressum_dieseseite" /> TensorParc, Inc<br/>
                                    <Trans i18nKey="impressum_adresse" /> TensorParc, Inc <br/>

                                    <Trans i18nKey="impressum_verantwortlich" /> <br/>
                                    <Trans i18nKey="impressum_email" /> mary@tensorparc.com </p>
                                <p> Some icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></p>
                                <p> Music for the video is from <a href="https://icons8.de/music" title="Icons8">Icons8</a> from <a href="https://www.flaticon.com/"     title="Icons8">https://icons8.de/music</a></p>
                                <p> Some pics made by <a href="<a href='https://www.freepik.com/vectors/background">Freepik.com</a> and some by Karan Dehghani</p>
                            </Card>
                        </Col>
                    </Row>
                </Container>

        </div>
    );
}

const NewComponent130 = withTranslation()(Blog)


export default function App() {
  return (

      <NewComponent130 />

  );
}
