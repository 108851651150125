import React, { useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import UserContext from "../../userContext";
import { api } from "../../lib/apisauce";
import { Redirect } from 'react-router'

import { Button, Card, Container, Row, Col} from 'react-bootstrap';



const useOptions = () => {

  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#5cb85c",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#5cb85c"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })
  );

  return options;
};



const SplitForm = (props) => {
  const context = React.useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  console.log(context)

  const saveSubscription = async (paymentId, userid, username) => {
    console.log(paymentId, userid)

      const response = await api.post(
          "https://apitp.tensorparc.com/mary/stripeSubscribe.php",
          { paymentId, userid, username }
      );
      const {ok, data} = response || {};
      if (ok) {

          if(data.result.status === '500') {
            console.error("failed")
          } else {
            localStorage.setItem("usersub", data.result.subid);
            context.updateUsersub(data.result.subid)
            return (
                <Redirect
                    to={{
                      pathname: `/Account`,
                      state: {
                      }
                    }}
                />
            );
          }

      }
      else {
          // handle api call error
          if (process.env.REACT_APP_DEBUG === true) {
              console.error("It didn't work. The user is still alive.");
          }
      }
  };


  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.log("not ready");
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });
    console.log("[PaymentMethod]", payload);

    if(payload) {
      saveSubscription(payload.paymentMethod.id, context.userid, context.username);
    }

  };

  return (

      <form onSubmit={handleSubmit}>
            <h4> Kreditkartennummer</h4>
              <CardNumberElement
                  options={options}
                  onReady={() => {
                      console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                      console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                      console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                      console.log("CardNumberElement [focus]");
                  }}
              />
              <h4> Gültig bis </h4>
              <CardExpiryElement
                  options={options}
                  onReady={() => {
                      console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                      console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                      console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                      console.log("CardNumberElement [focus]");
                  }}
              />
              <h4> CVC </h4>
              <CardCvcElement
                  options={options}
                  onReady={() => {
                      console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                      console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                      console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                      console.log("CardNumberElement [focus]");
                  }}
              />
          <button type="submit" disabled={!stripe}>
              Abo Starten
          </button>
      </form>
  );
};

export default SplitForm;
