import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import 'moment-timezone';
import 'moment/locale/de';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import {Button, Container, Row, Col, Form} from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import Joyride, { Actions, Events, CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';

class AddSource extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        username: '',
        sourcetitel: '',
        sourcenote: '',
        add: false,
        fail: false,
        loadNouns: '',
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        research: '',
        question: '',
        result: {
            percent: 0,
            differ: []
        },
        context: '',
        txt: '',
        checker: [],
        itemcounter: 0,
        saved: false,
        textarea1: '',
        keywords: [],
        source: '',
        run: false,
        steps: [
                {
                  title: <h4>Bitte gib auf dieser Seite Titel, Aufgabenstellung und die Musterlösung ein.</h4>,
                  content: <p>Deine SchülerInnen sehen nur den Titel und die Aufgabenstellung. Wenn du den Text fertig eingegeben hast, errechnet Mary die Prüfung. Bitte bestätige diese, wenn Mary fertig ist, indem du auf Speichern klickst. </p>,
                  floaterProps: {
                    disableAnimation: true,
                  },
                  locale: { skip: <strong aria-label="skip">Ne, doch nicht</strong>, close: 'Alles klar', last: 'Schliessen'  },
                  placement: 'center',
                  target: 'body',
                  hideBackButton: true,
                  disableOverlay: true,
                }
            ]
    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
    }

    componentDidMount() {
    }

    getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
      };
    
    handleClickStart = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
    
        this.setState({
          run: true
        });
      };

      handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    
        if (finishedStatuses.includes(status)) {
          this.setState({ run: false });
        }
    
        // tslint:disable:no-console
        console.groupCollapsed(type);
        console.log(data);
        console.groupEnd();
        // tslint:enable:no-console
      };

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
        console.log(this.state)
    }

    handleSNChange=(e)=>{
        let { value} = e.target;
        this.setState({sourcenote: value});
        console.log(this.state)
    }

    render() {
        const { run, stepIndex, steps } = this.state;
        const { t } = this.props;
        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        return (
            <UserConsumer>
                {({ username, password, isLoggedIn, loginFailure }) => (<Fragment>
                    {this.state.loading && <div>
                        <Loader />
                    </div>}
                    <div>
                    {this.state.run &&
                    <Joyride
                        callback={this.handleJoyrideCallback}
                        continuous={true}
                        getHelpers={this.getHelpers}
                        scrollToFirstStep={true}
                        
                        showSkipButton={true}
                        
                        styles={{
                            options: {
                            zIndex: 10000,
                            },
                        }}
                        />
                    }
                        <Container>
                        {this.state.run &&
                        <Joyride
                        steps={steps}
                        />}
                            <Row>
                                <Col>
                                    <h3 className="subtitleide"><Trans i18nKey="source_lege" /></h3>
                                </Col>
                                <Col xs={12} md={3} lg={3}><button onClick={this.handleClickStart}>Was kann ich hier machen?</button></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}><Trans i18nKey="source_bitte" /></div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        padding: "1rem",
                                    }}>
                                        <Form.Group>
                                            <Form.Control
                                                variant="outlined"
                                                placeholder= {t('source_titel')}
                                                className="mb-3"
                                                required
                                                id="sourcetitel"
                                                label="Titel"
                                                name="sourcetitel"
                                                autoFocus
                                                onChange={this.handleChange}
                                                style= {{borderRadius:"5px"}}
                                            />
                                            <Form.Control
                                                as="textarea" rows="3"
                                                variant="outlined"
                                                placeholder= {t('source_kommentar')}
                                                className="mb-3"
                                                required
                                                id="sourcenote"
                                                label="Kommentar"
                                                name="sourcenote"
                                                autoFocus
                                                onChange={this.handleSNChange}
                                                style= {{borderRadius:"5px"}}
                                            />
                                            <Form.Group>
                                                <Form.Control as="textarea" rows="10" id="textarea1" name="textarea1"
                                                              onBlur={this.handleChange}
                                                              onChange={this.handleChange}
                                                              placeholder='Bitte hier die Musterlösung oder Textquelle eingeben. Du kannst einfaches Deutsch verwenden:'/>
                                            </Form.Group>
                                            <Button
                                                type="button"
                                                className="mb-2"
                                                variant="primary"
                                                onClick={() => this.addUserSource(this.state.username, this.state.textarea1)}
                                            >
                                                <Trans i18nKey="source_hinzufuegen" />
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            {this.state.add && (<Fragment><Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_ichhab" /> <Trans i18nKey="source_woerter" /> <Button
                                    onClick={() => this.saveIde(username)}><Trans i18nKey="source_speichern" /></Button>
                                </Col>
                            </Row>
                            </Fragment>)}

                            {this.state.fail && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_ups" />
                                </Col>
                            </Row>)}

                            {this.state.saved && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_quelle" /> <Link to={"/Home"}><Trans i18nKey="source_schreibtisch" /></Link> <Trans i18nKey="source_gespeichert" />.
                                </Col>
                            </Row>)}

                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    addUserSource = async (url) => {
        this.setState({ loading: true });
        const txt = this.state.textarea1;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/getSpacychunks.php",
            { url, txt }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === '500') {
                this.setState({fail: true});
                this.setState({add: false});
                this.setState({loading: false});
            } else {
                this.setState({add: true});
                this.setState({fail: false});
                this.setState({ context: this.state.sourcetitel });
                this.setState({ items: data.result.items });
                this.setState({ checker: data.result.checker });
                this.setState({ keywords: data.result.keywords });
                this.setState({ source: this.state.textarea1 });
                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    saveIde = async username => {
        const dashboard = '';
        const research = JSON.stringify(this.state.keywords);
        const items = JSON.stringify(this.state.items);
        const check = JSON.stringify(this.state.checker);
        const context = JSON.stringify(this.state.context);
        const source = this.state.textarea1;
        const score = 0;
        const ide = '';
        const note = this.state.sourcenote;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putDashboard.php",
            { dashboard, ide, research, items, check, context, username, score, note, source }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ saved: true});
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };


}

const NewComponent5 = withTranslation()(AddSource)


export default function App() {
  return (

      <NewComponent5 />

  );
}
