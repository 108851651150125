import React from "react";
import Home from "../containers/Home";
import Impressum from "../containers/Impressum";
import Blog from "../containers/Blog";
import Aboutus from "../containers/Aboutus";
import Privacy from "../containers/Privacy";
import TOU from "../containers/TOU";
import Jobs from "../containers/Jobs";
import Signin from "../containers/Signin";
import Signup from "../containers/Signup";
import FlowStart from "../containers/FlowStart";
import ThemeSwitcher from "../ThemeSwitcher";
import ConfirmRegister from "../containers/ConfirmRegister";
import ConfirmDeletion from "../containers/ConfirmDeletion";
import { IDE } from "../components/IDE";
import { defaultEditorStrings } from "../components/IDE/config";
import BsStart from "../containers/BsStart";
import BsEditor from "../containers/BsEditor";
import BsEditor_Schueler from "../containers/BsEditor_Schueler";
import AddFollow from "../containers/AddFollow";
import AddSource from "../containers/AddSource";
import SubscribeForm from "../components/SubscribeForm";
import AddTeam from "../containers/AddTeam";
import Team from "../containers/Team";
import Student from "../containers/Student";
import Feedback from "../containers/Feedback";
import Account from "../containers/Account";
import EditSource from "../containers/EditSource";

const publicRoutes = [
    {
        component: BsStart, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/" // <-- ... and this is the actual path to match on
    },
    {
        component: ThemeSwitcher, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/ThemeSwitcher" // <-- ... and this is the actual path to match on
    },
    {
        component: BsStart, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/BsStart" // <-- ... and this is the actual path to match on
    },
    {
        component: SubscribeForm, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/SubscribeForm" // <-- ... and this is the actual path to match on
    },
    {
        component: Account, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Account" // <-- ... and this is the actual path to match on
    },
    {
        component: BsEditor, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/BsEditor" // <-- ... and this is the actual path to match on
    },
    {
        component: EditSource, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/source/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: BsEditor_Schueler, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/loadSt/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: Feedback, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Feedback/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: Student, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Student/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: FlowStart, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/FlowStart" // <-- ... and this is the actual path to match on
    },
    {
        component: BsEditor, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/load/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: Team, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/team/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: Home, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Home" // <-- ... and this is the actual path to match on
    },
    {
        component: AddFollow, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/AddFollow" // <-- ... and this is the actual path to match on
    },
    {
        component: AddSource, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/AddSource" // <-- ... and this is the actual path to match on
    },
    {
        component: AddTeam, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/AddTeam" // <-- ... and this is the actual path to match on
    },
    {
        component: Jobs, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Jobs" // <-- ... and this is the actual path to match on
    },
    {
        component: TOU, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/TOU" // <-- ... and this is the actual path to match on
    },
    {
        component: Aboutus, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Aboutus" // <-- ... and this is the actual path to match on
    },
    {
        component: Privacy, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Privacy" // <-- ... and this is the actual path to match on
    },
    {
        component: Blog, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Blog" // <-- ... and this is the actual path to match on
    },
    {
        component: Impressum, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Impressum" // <-- ... and this is the actual path to match on
    },
    {
        component: Signin, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Signin" // <-- ... and this is the actual path to match on
    },
    {
        component: Signup, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Signup" // <-- ... and this is the actual path to match on
    },
    {
        component: ConfirmRegister, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/ConfirmRegister/:code" // <-- ... and this is the actual path to match on
    },
    {
        component: ConfirmDeletion, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/ConfirmDeletion" // <-- ... and this is the actual path to match on
    },
    {
    component: () => (
      <IDE
        name="IDE-PYTHON"
        mode="python"
        value={defaultEditorStrings.python}
        defaultValue={defaultEditorStrings.python}
      />
    ),
    exact: true,
    path: "/IDE-PY"
  },
  {
    component: () => (
      <IDE
        name="IDE-JAVA"
        mode="java"
        value={defaultEditorStrings.java}
        defaultValue={defaultEditorStrings.java}
      />
    ),
    exact: true,
    path: "/IDE-JAVA"
  },
  {
    component: () => (
      <IDE
        name="IDE-JS"
        mode="javascript"
        splits={2}
        value={defaultEditorStrings.js}
        defaultValue={defaultEditorStrings.js}
      />
    ),
    exact: true,
    path: "/IDE-JS"
  },
];


export { publicRoutes };
