import React, { PureComponent } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { Container, Col,  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation } from "react-i18next";
import  "../../i18n.js";

class ConfirmDeletion extends PureComponent {

    render() {
        return (
            <div>
                <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem" }}>
                    <Container style={{ paddingTop: "10rem" }}>
                        <Col xs={12} sm={12} md={12}>
                            <h3 style={{ paddingBottom: "3rem" }}>Es tut uns leid, dass du gehst. Dein Konto wurde gelöscht.</h3>
                            <a href="https://www.youtube.com/watch?v=MKWWhf8RAV8">Hier ist ein Video als letzter Leckerbissen. Nur um sich zu verabschieden.</a>
                        </Col>
                    </Container>
                </div>
            </div>
        );
    }
}

const NewComponent60 = withTranslation()(ConfirmDeletion)


export default function App() {
  return (

      <NewComponent60 />

  );
}
