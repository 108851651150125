import React, { PureComponent, Fragment } from 'react';
import './style.css';
import UserContext, { UserConsumer } from "../../userContext";
import 'moment-timezone';
import 'moment/locale/de';
import { api } from "../../lib/apisauce";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import {Button, Container, Row, Col, Form} from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import  "../../i18n.js";
import Joyride, { Actions, Events, CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';

class AddTeam extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],
        userFollows: [],
        username: '',
        sourcetitel: '',
        sourcenote: '',
        add: false,
        fail: false,
        loadNouns: '',
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        research: '',
        question: '',
        result: {
            percent: 0,
            differ: []
        },
        context: '',
        txt: '',
        checker: [],
        itemcounter: 0,
        saved: false,
        textarea1: '',
        keywords: [],
        run: false,
        steps: [
                {
                  title: <h4>Bitte gib auf dieser Seite den Namen deiner Klasse und die E-Mail Adressen deiner SchülerInnen ein.</h4>,
                  content: <p>Deine SchülerInnen bekommen dann von Mary eine Einladung per E-Mail gesendet. Im Moment musst du leider alle Adressen beim ersten mal komplett eingeben. Wir arbeiten daran, dass du nachträglich noch SchülerInnem hinzufügen bzw. löschen kannst.</p>,
                  floaterProps: {
                    disableAnimation: true,
                  },
                  locale: { skip: <strong aria-label="skip">Ne, doch nicht</strong>, close: 'Alles klar', last: 'Schliessen'  },
                  placement: 'center',
                  target: 'body',
                  hideBackButton: true,
                  disableOverlay: true,
                }
            ]
    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
    }

    componentDidMount() {
    }

    getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
      };
    
    handleClickStart = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
    
        this.setState({
          run: true
        });
      };

      handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    
        if (finishedStatuses.includes(status)) {
          this.setState({ run: false });
        }
    
        // tslint:disable:no-console
        console.groupCollapsed(type);
        console.log(data);
        console.groupEnd();
        // tslint:enable:no-console
      };

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
        console.log(this.state)
    }

    handleSNChange=(e)=>{
        let { value} = e.target;
        this.setState({sourcenote: value});
        console.log(this.state)
    }

    render() {
        const { run, stepIndex, steps } = this.state;
        const { t } = this.props;
        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        return (
            <UserConsumer>
                {({ username, password, isLoggedIn, loginFailure, userid }) => (<Fragment>
                    {this.state.loading && <div>
                        <Loader />
                    </div>}
                    <div>
                    {this.state.run &&
                    <Joyride
                        callback={this.handleJoyrideCallback}
                        getHelpers={this.getHelpers}
                        steps={steps}
                        showSkipButton={true}
                        styles={{
                            options: {
                            zIndex: 10000,
                            overlayColor: 'transparent',
                            backgroundColor: 'transparent',
                            },
                        }}
                        />
                    }
                        <Container>
                        {this.state.run &&
                        <Joyride
                        steps={steps}
                        />}
                            <Row>
                                <Col xs={12} md={9} lg={9}>
                                    <h3 className="subtitleide">Neue Klasse / Lerngruppe anlegen:</h3>
                                </Col>
                                <Col xs={12} md={3} lg={3}><button onClick={this.handleClickStart}>Was kann ich hier machen?</button></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div style={{
                                        backgroundColor: "rgba(63, 64, 63, 0.87)",
                                        color: "rgb(255, 255, 255)",
                                        margin: "0.5rem",
                                        padding: "1rem",
                                        borderColor: "rgb(217, 217, 217)",
                                        borderRadius: "10px",
                                    }}>Bitte gib hier den Namen der Klasse ein, die ein Thema behandeln soll. Danach gib bitte die E-Mail Addressen ein:</div>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{
                                        padding: "1rem",
                                    }}>
                                      
                                        <Form.Group>
                                            <Form.Control
                                                variant="outlined"
                                                placeholder= {t('source_titel')}
                                                className="mb-3"
                                                required
                                                id="sourcetitel"
                                                label="Titel"
                                                name="sourcetitel"
                                                autoFocus
                                                onChange={this.handleChange}
                                                style= {{borderRadius:"5px"}}
                                            />
                                            

                                            <Form.Group >
                                           
                                                <Form.Label>Bitte E-Mail Adressen der Schüler / Teilnehmer eingeben (eine Adresse pro Zeile):</Form.Label>
                                                
                                                <Form.Control as="textarea" rows="10" id="textarea1" name="textarea1"
                                                              onBlur={this.handleChange}
                                                              onChange={this.handleChange}/>
                                            </Form.Group>
                                           
                                            <Button
                                                type="button"
                                                className="mb-2"
                                                variant="primary"
                                                onClick={() => this.addUserSource(this.state.username, this.state.textarea1)}
                                            >
                                                Anlegen und Schüler / Teilnehmer einladen
                                            </Button>
                                            {this.state.run && <div className="emailaddressen"> </div>}
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            {this.state.add && (<Fragment><Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    Dein Team wurde angelegt und du kannst es nun auf Deinem Dashboard sehen:
                                    <Link to={"/Home"}><Trans i18nKey="source_schreibtisch" /></Link>
                                </Col>
                            </Row>
                            </Fragment>)}

                            {this.state.fail && (<Row className="pb-5">
                                <Col>
                                    <img alt="complex" src="https://i.imgur.com/VudgSQK.png" height="40rem" style={{ marginRight: "1rem" }} />
                                    <Trans i18nKey="source_ups" />
                                </Col>
                            </Row>)}


                        </Container>
                    </div>
                </Fragment>)}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserFollows
     * @memberof LandingPage
     * WIP
     */
    addUserSource = async (url) => {
        this.setState({ loading: true });
        const mails = this.state.textarea1;
        const owner = this.context.userid;
        const name = this.state.sourcetitel;
        const owneremail = this.context.username;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putTeam.php",
            { name, owner, mails, owneremail }
        );
        const { ok, data } = response || {};
        if (ok) {

            if(data.result.status === '500') {
                this.setState({fail: true});
                this.setState({add: false});
                this.setState({loading: false});
            } else {
                this.setState({add: true});
                this.setState({fail: false});
                this.setState({loading: false});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    saveIde = async username => {
        const dashboard = '';
        const research = JSON.stringify(this.state.keywords);
        const items = JSON.stringify(this.state.items);
        const check = JSON.stringify(this.state.checker);
        const context = JSON.stringify(this.state.context);
        const score = 0;
        const ide = '';
        const note = this.state.sourcenote;
        const response = await api.post(
            "https://apitp.tensorparc.com/mary/putDashboard.php",
            { dashboard, ide, research, items, check, context, username, score, note }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ saved: true});
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };


}

const NewComponent5 = withTranslation()(AddTeam)


export default function App() {
  return (

      <NewComponent5 />

  );
}
