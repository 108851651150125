//const createRoomEndpoint = 'https://create-a-room--kwindla.repl.co';
const createRoomEndpoint1 = 'https://apitp.tensorparc.com/mary/getDaily.php';
let callUrl;
//let frame = document.getElementById("iframe");

export function maybeJoinCall() {
        if (window.location.hash) {
          callUrl = window.location.hash.substring(1);
          startCall();
        }
      };

export async function startCall() {
        if (!callUrl) {
         try {
            let response = await fetch(createRoomEndpoint1),
                roomData = await response.json();
                callUrl = roomData.url;
                let iframe = document.createElement('iframe');
                iframe.setAttribute("id", "draggable");
                iframe.width = '250px';
                iframe.height = '80px';
                iframe.style.position = 'absolute';
                iframe.style.right = '1em';
                iframe.style.bottom = '3em';
                iframe.src = callUrl;
                iframe.allow="camera; microphone; autoplay";

                document.getElementById("appendhere").append(iframe)

             //document.getElementById("dailyURL").value=callUrl;

             //console.log('fetch (retrying in 2s)', roomData);

          } catch (e) {
            console.log('fetch failed (retrying in 2s)', e);
            setTimeout(() => startCall(), 2000);
          }
        }



        /*callFrame.join()*/
        window.location = '#' + callUrl;
        let button = document.getElementById('start-call-button');
        /*button.innerHTML = 'Video Call starten';*/
        button.onclick = () => {

          /*button.innerHTML = 'Video Call starten';*/
          button.onclick = startCall;
          // window.location.origin is this page's url
          // without the hash fragment
          /*window.location = window.location.origin + '/BsStart'*/
        }
      };

export function delFrame() {
    var getframe = document.getElementById("draggable")
    document.getElementById("appendhere").removeChild(getframe);
    getframe.src = null;
   //var getframe = document.getElementById("draggable");
   //getframe.remove();

}
